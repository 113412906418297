<template>
  <div class="featured-posts">
    <h3>
      {{ data.subheader }}
    </h3>
    <h2>
      {{ data.header }}
    </h2>
    <Button
      :url="data.cta.url"
      :text="data.cta.title"
      color="dark-green"
      lineHover="bg-mint-30"
      circleColor="circle-orange"
    />
    <div class="posts-list">
      <div
        class="post"
        :class="{ 'no-border': idx % 2 !== 0 }"
        v-for="(post, idx) in data.featured_posts"
        :key="idx"
      >
        <span class="post-date">{{ formatDate(post.post_date) }}</span>

        <div
          class="post-text"
          v-html="truncate(post.post_excerpt, 10) || 'No Excerpt Available'"
        />
        <Button
          :url="post.post_url"
          text="Learn More"
          color="dark-green"
          lineHover="bg-mint-30"
          circleColor="circle-orange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import Button from '@/components/utility/Button.vue'
import { format } from 'date-fns'

const props = defineProps(['data'])

const formatDate = (date) => {
  const dateToFormat = new Date(date.split(' ')[0])
  // compensing for a problem where the returned date is a day before the original unformatted date
  dateToFormat.setDate(dateToFormat.getDate() + 1)

  return format(dateToFormat, 'MMMM dd, yyyy')
}
const truncate = (text, length) => {
  const textArr = text.split(' ')
  if (textArr.length <= length) {
    return text
  }
  const truncated = textArr.slice(0, length)
  const lastChar = truncated[truncated.length - 1].slice(-1)
  if (/[!"#$%&'()*+,-./:<=>?@[\]^_`{|}~]/g.test(lastChar)) {
    truncated.pop()
  }
  return `${truncated.join(' ')}...`
}
</script>

<style lang="scss" scoped>
.featured-posts {
  @apply main-container mt-[60px] mb-[110px] xl:px-[20px] flex flex-col
    xl:my-[20px];
  h3 {
    @apply sm:text-[20px];
  }
  h2 {
    @apply text-[34px] leading-[34px] mt-9 mb-[25px]
      sm:text-[60px] sm:leading-[60px] sm:mt-11
      lg:text-[70px] lg:leading-[70px] lg:max-w-[60%] lg:mb-[50px];
  }
  .button {
    @apply mb-[50px]
      sm:mb-[100px];
  }
  .posts-list {
    @apply flex flex-col
      md:flex-row md:flex-wrap md:gap-[0px];

    .post {
      @apply flex flex-col gap-[10px] border-b-[1px] border-[rgba(0,22,16,0.3)] pt-[60px] pb-[35px]
          md:w-[50%] md:gap-[30px] md:h-[325px] md:border-r-[1px] md:border-b-[0px] md:pr-[60px] md:pt-0 md:mb-[115px]
          lg:w-[33%] lg:mb-0 lg:py-[75px] lg:px-[60px] lg:flex-grow lg:min-h-[270px] lg:h-auto;

      &-date {
        @apply uppercase leading-[12px];
      }
      &-text {
        @apply text-[24px] mt-[25px];
      }
      .button {
        @apply mb-0 mt-0 md:flex-grow md:flex md:items-end
          lg:mt-0;
      }
      &:first-child {
        @apply pt-[0px]
          lg:py-[75px] lg:pl-[0px];
      }
      &:last-child {
        @apply pb-[0px] border-b-0
          md:mb-0 md:pb-[35px]
          lg:px-[60px] lg:py-[75px] lg:border-r-0;
      }
      &.no-border {
        @apply md:border-r-0 md:pl-[60px] md:pr-[0px]
          lg:border-r-[1px] lg:px-[60px];
      }
      @media (min-width: 768px) and (max-width: 811px) {
        &.no-border {
        }
      }
    }
  }
}
</style>
