<template>
  <div class="size-card" :class="selected ? 'active' : ''" @click="setFinalSize(props.sizes)">
    <div class="sizing">
      <div class="size">
        {{sizes.attributes.attribute_size}}
      </div>
      <div v-if="sizes.dimensions" class="measurements" :class="sizes.attributes.attribute_size === 'Custom' ? 'form' : ''">
        <template v-if="sizes.attributes.attribute_size === 'Custom'">
          <template v-if="isDiameter()">
            <FormKit
            type="group"
            id="custom-sizing"
            name="dimensions"
            :delay="100"
            v-model="CylinderMeasurements"
            >
              <template #default="{ state: { valid } }">
                <div class="input is-diameter diameter">
                    <FormKit
                    required
                    name="diameter"
                    validation="required"
                    placeholder="Ø"
                    :value="selected ? size?.diameter : ''"
                    type="text" />
                </div>
                <div class="input is-diameter height">
                  <FormKit
                  required
                  name="height"
                  validation="required"
                  placeholder="H"
                  :value="selected ? size?.dimensions?.height : ''"
                  type="text" />
                </div>
                <div
                  v-if="!valid && selected"
                  class="not-valid"
                >
                  Please fill the measurement fields.
                </div>
              </template>
            </FormKit>
          </template>
          <template v-else>
            <FormKit
            type="group"
            id="custom-sizing"
            name="dimensions"
            :delay="100"
            v-model="measurements"
            >
              <template #default="{ state: { valid } }">
                <div class="input length">
                  <FormKit
                  required
                  name="length"
                  validation="required"
                  placeholder="L"
                  :value="selected ? size?.dimensions?.length : ''"
                  type="text" />
                </div>
                <div class="input width">
                  <FormKit
                  required
                  name="width"
                  validation="required"
                  placeholder="W"
                  :value="selected ? size?.dimensions?.width : ''"
                  type="text" />
                </div>
                <div class="input height">
                  <FormKit
                  required
                  name="height"
                  validation="required"
                  placeholder="H"
                  :value="selected ? size?.dimensions?.height : ''"
                  type="text" />
                </div>
                <div
                  v-if="!valid && selected"
                  class="not-valid"
                >
                  Please fill the measurement fields.
                </div>
              </template>
            </FormKit>
          </template>
        </template>
        <template v-else>
          <template v-if="isDiameter()">
            <div class="input is-diameter diameter">
              <Popper arrow placement="top" arrowPadding="20" content="Diameter in Inches" :hover="true">
                <div>Ø/<span class="value" v-html="useDecimalToInches(sizes.diameter).toHTMLString()" /></div>
              </Popper>
            </div>
            <div class="input is-diameter height">
              <Popper arrow placement="top" arrowPadding="20" content="Height in Inches" :hover="true">
                <div>H/<span class="value" v-html="useDecimalToInches(sizes.dimensions.height).toHTMLString()" /></div>
              </Popper>
            </div>
          </template>
          <template v-else>
            <div class="input length">
              <Popper arrow placement="top" arrowPadding="20" content="Length in Inches" :hover="true">
                <div>L/<span class="value" v-html="useDecimalToInches(sizes.dimensions.length).toHTMLString()" /></div>
              </Popper>
            </div>
            <div class="input width">
              <Popper arrow placement="top" arrowPadding="20" content="Width in Inches" :hover="true">
                <div>W/<span class="value" v-html="useDecimalToInches(sizes.dimensions.width).toHTMLString()" /></div>
              </Popper>
            </div>
            <div class="input height">
              <Popper arrow placement="top" arrowPadding="20" content="Height in Inches" :hover="true">
                <div>H/<span class="value" v-html="useDecimalToInches(sizes.dimensions.height).toHTMLString()" /></div>
              </Popper>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watchEffect, onMounted, watch } from 'vue'
import { ProductState } from './state'
import { FormKit } from '@formkit/vue'
import { reset } from '@formkit/core'
import Popper from 'vue3-popper'
import { useDecimalToInches } from '../../composables/useDecimalToInches.js'

const { setSize, size, product } = ProductState()

const props = defineProps(['sizes'])
const selected = ref(false)
const measurements = ref(null)
const CylinderMeasurements = ref(null)

onMounted(() => {
  if (!size.value) return
  if (size.value.sku === props.sizes.sku) {
    selected.value = true
  }
})

const isDiameter = () => {
  if (product.value.product_variations.find(x => x.diameter !== false)) {
    return true
  } else {
    return false
  }
}

watchEffect(() => {
  if (!size.value) return
  if (size.value.sku === props.sizes.sku) {
    selected.value = true
  } else {
    selected.value = false
  }
})

watch(selected, (next, prev) => {
  if (!next) {
    if (props.sizes.attributes.attribute_size === 'Custom') {
      reset('custom-sizing')
    }
  }
})

if (isDiameter()) {
  watch(CylinderMeasurements, (next, prev) => {
    if (props.sizes.attributes.attribute_size === 'Custom') {
      if (selected.value) {
        setSize({
          sku: props.sizes.sku,
          attributes: { attribute_size: props.sizes.attributes.attribute_size },
          diameter: CylinderMeasurements.value.diameter,
          dimensions: {
            height: CylinderMeasurements.value.height
          }
        })
      }
    }
  })
} else {
  watch(measurements, (next, prev) => {
    if (props.sizes.attributes.attribute_size === 'Custom') {
      if (selected.value) {
        setSize({
          sku: props.sizes.sku,
          attributes: { attribute_size: props.sizes.attributes.attribute_size },
          dimensions: {
            length: measurements.value.length,
            width: measurements.value.width,
            height: measurements.value.height
          }
        })
      }
    }
  })
}

const setFinalSize = (data) => {
  if (data) {
    setSize(data)
    selected.value = true
  }
}

</script>
<style lang="scss" scoped>
.size-card {
  @apply bg-white rounded-xl p-3 flex items-center transition-shadow duration-300 ease-in-out relative cursor-pointer;
  &::before {
    @apply absolute content-[''] h-2 w-2 rounded-full bg-ore_orange top-2 right-3 opacity-0 transition-opacity duration-300 ease-in-out;
  }
  &.active {
    @apply shadow-primaryshade/30 shadow-lg transition-shadow duration-300 ease-in-out;
  }
  &.active::before {
    @apply absolute content-[''] h-2 w-2 rounded-full bg-ore_orange top-2 right-3 opacity-100 transition-opacity duration-300 ease-in-out;
  }
  &:hover {
    @apply shadow-primaryshade/30 shadow-lg transition-shadow duration-300 ease-in-out;
  }
  .sizing {
    @apply flex w-full flex-row lg:flex-col xl:flex-row;
    .size {
      @apply text-[16px] md:text-[24px] text-ore_green_dark leading-tight py-4 pl-2
      border-r-ore_orange lg:border-b-ore_orange xl:border-r-ore_orange
      border-r lg:border-b lg:border-r-0 xl:border-r xl:border-b-0 w-3/12 lg:w-full xl:w-3/12;
    }
    .measurements:deep() {
      @apply flex gap-4 md:gap-x-4 items-center
      text-[16px] lg:text-[20px] xl:text-[24px]
      w-9/12 px-2 lg:px-2 xl:px-6 py-0 lg:py-4 xl:py-0
      flex-wrap;
      &.form {
        @apply gap-2;
      }
      .popper {
        @apply bg-white p-5 shadow-2xl text-base rounded-lg text-ore_green;
        #arrow {
          &::before {
            @apply bg-white shadow-2xl;
          }
        }
      }
      .not-valid {
        @apply text-sm text-red-600;
      }
      .input {
        @apply text-ore_orange w-max md:w-[26%] xl:w-[30%] flex items-center;
        &.is-diameter {
          @apply w-max sm:w-4/12;
        }
        .formkit-label {
          @apply hidden;
        }
        .formkit-message {
          @apply text-sm;
        }
        input {
          @apply form-input w-full border-ore_orange rounded-md
          text-[24px] text-center focus:ring-ore_orange focus:border-ore_orange
          placeholder-ore_orange;
          &::-webkit-inner-spin-button, &::-webkit-inner-spin-button {
            @apply hidden;
          }
        }
        .value {
          @apply text-ore_green_dark pl-1;
        }
      }
    }
  }
}

</style>
