<template>
  <section :style="css" class="modules-container overflow-hidden">
    <div class="decoration" :style="imageCss">

    </div>
    <slot />
  </section>
</template>
<script setup>
import { ref } from 'vue'
const props = defineProps([
  'data'
])

const bgRoot = ref(props.data.container_properties.background_properties)
const PMRoot = ref(props.data.container_properties.padding_and_margin)
const css = {
  position: 'relative',
  backgroundColor: `rgba(${bgRoot.value.background_color.red}, ${bgRoot.value.background_color.green}, ${bgRoot.value.background_color.blue}, ${bgRoot.value.background_color.alpha})`,
  paddingTop: `${PMRoot.value.padding.padding_t}px`,
  paddingRight: `${PMRoot.value.padding.padding_r}px`,
  paddingBottom: `${PMRoot.value.padding.padding_b}px`,
  paddingLeft: `${PMRoot.value.padding.padding_l}px`,
  marginTop: `${PMRoot.value.margin.margin_t}px`,
  marginRight: `${PMRoot.value.margin.margin_r}px`,
  marginBottom: `${PMRoot.value.margin.margin_b}px`,
  marginLeft: `${PMRoot.value.margin.margin_l}px`
}
const imageCss = {
  backgroundImage: `url(${bgRoot.value.background_image ? bgRoot.value.background_image.url : ''})`,
  backgroundPosition: `${bgRoot.value.background_position}`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  width: `${bgRoot.value.bg_position.pos_x}${bgRoot.value.bg_position.type}`,
  height: `${bgRoot.value.bg_position.pos_y}${bgRoot.value.bg_position.type}`,
  position: 'absolute',
  transform: `translate(${bgRoot.value.el_position.el_pos_x}${bgRoot.value.el_position.el_type}, ${bgRoot.value.el_position.el_pos_y}${bgRoot.value.el_position.el_type})`,
  zIndex: 1
}

</script>
<style lang="scss" scoped>
  .modules-container:deep(section) {
    //mix-blend-mode: multiply;
  }
  .decoration {
    @apply  hidden lg:block
  }
</style>
