<template>
  <section class="projectFilter">
    <div class="listCategories" :class="allCategories.length > 6 ? 'scrollShadow' : ''">
      <div
        class="arrow right"
        v-if="
          scrollPosition != allCategories.length && scrollPosition <= allCategories.length
        "
      >
        <img :src="arrow" alt="" v-on:click="scrollX('right')" />
      </div>
      <div class="arrow left" v-if="scrollPosition != 6">
        <img :src="arrow" alt="" v-on:click="scrollX('left')" />
      </div>
      <div class="listCategories__wrapper" id="wrapper">
        <div
          v-on:click="setCategory(item.ids, item.qty)"
          v-for="(item, index) in allCategories"
          :key="index"
          :id="`cat-${item.ids.toString().replaceAll(',', '-')}`"
          :class="`listCategories__wrapper__item ${index == 0 ? 'active' : ''}`"
        >
          <span>{{ item.qty.toString().length == 1 ? "0" + item.qty : item.qty }}</span>
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
    <div class="listPosts">
      <TransitionGroup name="slide-fade">
        <ProjectCard
          v-for="(itm, idx) in listOfProject"
          :key="idx"
          :itm="itm"
          :idx="idx"
          :totalItem="listOfProject.length"
        />
      </TransitionGroup>
      <div class="loadMore cursor-pointer" v-if="totalPages !== currentPage" v-on:click="loadMore()">
        Load More
      </div>
    </div>
  </section>
</template>

<script setup>
import arrow from '@/assets/img/button_arrow.svg'
import ProjectCard from '@/components/utility/ProjectCard.vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import { useStore } from 'vuex'
import { onBeforeMount, ref, onMounted } from 'vue'

gsap.registerPlugin(ScrollTrigger)
const props = defineProps(['data'])

const listOfProject = ref([])
const scrollPosition = ref(6)
const mainStore = useStore()
const currentPage = ref(1)

const totalPages = ref(1)
const totalItems = ref(0)
const initialLoad = ref(false)
const allCategories = [
  {
    ids: [],
    name: 'See all',
    qty: totalItems.value
  }
]
const categorySelected = ref(allCategories[0].ids)

const scrollX = (direction) => {
  const position = direction === 'left' ? -100 : 100
  const scrollPositionValue = direction === 'left' ? -1 : 1

  document.getElementById('wrapper').scrollLeft += position
  scrollPosition.value += scrollPositionValue
}

const SetListOfCategories = () => {
  const catetegoriesFromData = props.data.categories

  catetegoriesFromData.forEach((e) => {
    const tempArr = allCategories[0].ids

    tempArr.push(e.term_id)
    allCategories[0] = {
      ids: tempArr,
      name: 'See all',
      qty: 0
    }
  })

  catetegoriesFromData.forEach((e) => {
    if (e.count !== 0) {
      allCategories.push({
        ids: [e.term_id],
        name: e.name,
        qty: e.count
      })
    }
  })
  // allCategories[0].qty = sumatory all categories qty
  allCategories[0].qty = allCategories.reduce((a, b) => a + b.qty, 0)
}

// onMounted(() => {
//
//   // ScrollTrigger.refresh()
// })

const loadProject = async (loadMore = false) => {
  await mainStore.dispatch('getItems', {
    type: 'portfolio',
    params: {
      per_page: 2,
      page: currentPage.value,
      'portfolio-type': categorySelected.value.toString()
    }
  })
  totalPages.value = initialPortfolio().totalPages
  if (initialLoad.value === false) {
    allCategories[0].qty = initialPortfolio().totalItems
    initialLoad.value = true
  }
  if (loadMore) {
    const tempCurrent = listOfProject.value
    const tempNew = initialPortfolio().porfolioList
    listOfProject.value = tempCurrent.concat(tempNew)
  } else {
    listOfProject.value = initialPortfolio().porfolioList
  }
}
const initialPortfolio = () => {
  const porfolioList = mainStore.getters.requestedItems({
    type: 'portfolio',
    params: {
      per_page: 2,
      page: currentPage.value,
      'portfolio-type': categorySelected.value.toString()
    }
  })
  const totalItems = mainStore.getters.totalItems({
    type: 'portfolio',
    params: {
      per_page: 2,
      page: currentPage.value,
      'portfolio-type': categorySelected.value.toString()
    }
  })
  const totalPages = mainStore.getters.totalPages({
    type: 'portfolio',
    params: {
      per_page: 2,
      page: currentPage.value,
      'portfolio-type': categorySelected.value.toString()
    }
  })

  return { porfolioList, totalItems, totalPages }
}
const loadMore = async () => {
  currentPage.value = currentPage.value + 1
  await loadProject(true)
  // setTimeout(() => {
  //
  //   // ScrollTrigger.refresh()
  // }, 500)
}
const setCategory = async (ids, qty) => {
  listOfProject.value = []
  const activeCategories = document.querySelector('.active')
  activeCategories.classList.remove('active')
  const item = document.getElementById(`cat-${ids.toString().replaceAll(',', '-')}`)
  item.classList.add('active')
  categorySelected.value = ids
  currentPage.value = 1
  await loadProject()
  // setTimeout(() => {
  //
  //   // ScrollTrigger.refresh()
  // }, 500)
}

onBeforeMount(async () => {
  SetListOfCategories()
  await loadProject()
})
</script>

<style lang="scss" scoped>
.projectFilter {
  @apply px-[3px] lg:px-8  max-w-[1728px] mx-auto;
  .listCategories {
    @apply relative w-full lg:max-w-[1232px] mx-auto bg-ore_green p-5 rounded-3xl;
    &::before {
      @apply content-[''] w-[40%] h-full block lg:hidden z-1 absolute right-0 top-0 bg-gradient-to-l from-ore_green to-transparent rounded-3xl pointer-events-none;
    }

    &__wrapper {
      @apply flex flex-row flex-nowrap overflow-x-scroll m-0 snap-x scroll-smooth;
      &::-webkit-scrollbar {
        opacity: 0;
        height: 0px;
      }
      &__item {
        @apply snap-start  min-w-[40%] md:min-w-[25%] lg:min-w-[16.6%] lg:w-1/5 h-[82px] md:h-32 flex flex-col justify-center items-center cursor-pointer hover:bg-white/10 rounded-xl;
        span {
          @apply text-ore_orange text-xl md:text-3xl font-medium pb-1 md:pb-3;
        }
        p {
          @apply text-white text-xl md:text-2xl font-medium;
        }
        &.active {
          @apply bg-ore_orange rounded-xl;
          p,
          span {
            @apply text-white;
          }
        }
      }
    }
    .arrow {
      @apply w-10 h-10 hover:scale-110 ease-out duration-300 bg-white rounded-full hidden lg:flex flex-row items-center justify-center cursor-pointer absolute;
      &.left {
        @apply inset-y-16 -left-5 z-2 rotate-180 ease-out duration-300;
      }
      &.right {
        @apply inset-y-16 -right-5 z-2;
      }
      img {
        @apply w-1/2;
      }
    }
  }
  .scrollShadow {
    @apply relative;
    &::after {
      @apply content-[''] w-[40%] h-full z-1 absolute right-0 top-0 bg-gradient-to-l from-ore_green to-transparent rounded-3xl pointer-events-none;
    }
  }
  .listPosts {
    @apply py-6 md:py-24 px-[9px] md:px-6 lg:px-0;

    .loadMore {
      @apply flex flex-row justify-center -mt-5  bg-ore_orange rounded-xl w-full md:w-1/2 lg:w-1/4 mx-auto text-white py-5 text-xl cursor-pointer;
    }
  }
}
</style>
