<template>
  <div class="finish-picker-card" :style="{ 'background-image': 'url(' + finish['acf-product-options-finish-image'].url + ')' }" :class="active ? 'active' : ''">
    <div class="bottom-area">
      <p class="pr-4">{{ finish['acf-product-options-finish-title'] }}</p>
      <div class="right-side">
        <Popper v-if="finish['acf-product-options-finish-description']" placement="top" :hover="true" offsetSkid="-40">
          <img class="tooltips" src="@/assets/img/info.svg" alt="" />
          <template #content>
            <div class="flex flex-col">
              <img class="tooltips mb-4 w-6 h-6" src="@/assets/img/info.svg" alt="" />
              <div class="mb-4">{{ finish['acf-product-options-finish-description'] }}</div>
              <a class="flex w-fit self-end hover:scale-95 transition" v-if="finish['acf-product-options-finish-file']" target="_blank" role="button" :href="`${finish['acf-product-options-finish-file'].url}`" :download="`${props.category} - ${finish['acf-product-options-finish-title']}`">
                <div class="cursor-pointer specs-button flex items-center border border-black rounded-full w-fit px-3 py-1">
                  <p class="mr-4 text-[14px]">Specs</p><img src="@/assets/img/download-black.svg" alt="">
                </div>
              </a>
            </div>
          </template>
        </Popper>
        <div class="checkbox">
          <label class="label">
          <input  class="label__checkbox" v-model="checked" type="checkbox" />
          <div class="label__text">
            <div class="label__check">
              <img src="@/assets/img/check.svg" class="icon">
            </div>
          </div>
        </label>
      </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue'
import { finishState } from '@/components/Cart/finishStore.js'
import Popper from 'vue3-popper'
const { finishes, addToFinishes, removeFromFinishes } = finishState()
const checked = ref(false)
const active = ref(false)
const props = defineProps(['finish', 'category'])
const finishId = `${props.category} - ${props.finish['acf-product-options-finish-title']}`

watch(checked, (next, prev) => {
  if (!next) {
    removeFromFinishes(finishId)
    active.value = false
  } else {
    if (!finishes.value.find((x) => x.finishId === finishId)) {
      addToFinishes({ finish: props.finish, finishId })
      active.value = true
    }
  }
})

onMounted(() => {
  if (finishes.value.find((x) => x.finishId === finishId)) {
    checked.value = true
    active.value = true
  } else {
    checked.value = false
    active.value = false
  }
})

</script>
<style lang="scss" scoped>
  .finish-picker-card {
    @apply flex w-full md:w-[45%] lg:w-[22%] rounded-3xl h-[280px] lg:h-[300px] xl:h-[350px] bg-cover bg-no-repeat bg-center transition;
    &:hover {
      @apply shadow-xl scale-[101%];
    }
    .bottom-area {
      @apply flex justify-between py-8 px-6 bg-white self-end w-full rounded-b-3xl;
      .right-side:deep() {
        @apply flex gap-x-4 items-center;
        .popper {
        @apply bg-white bg-opacity-[95%] w-full md:w-[300px] p-5 shadow-2xl text-base rounded-lg text-black;
        #arrow {
          &::before {
            @apply bg-white bg-opacity-[95%] shadow-2xl;
          }
        }
      }
        .tooltips {
          @apply cursor-pointer;
        }
        .checkbox {
        .label__checkbox {
          @apply hidden;
        }

        .label__check {
          @apply border-gray-300 rounded-full border border-solid
          bg-gray-200 w-7 h-7 cursor-pointer flex items-center content-center
          transition-all duration-300 ease-in-out;

          .icon {
            @apply opacity-0 transition-opacity duration-300 delay-100 ease-linear h-3 w-3 relative left-2;
          }

          &:hover {
            @apply border-2 border-solid border-gray-300;
          }
        }

        .label__checkbox:checked + .label__text .label__check {
          @apply shadow-ore_orange shadow-lg;
          animation: check .5s cubic-bezier(0.895, 0.030, 0.685, 0.220) forwards;

          .icon {
            @apply opacity-0 scale-0;
            animation: icon .3s cubic-bezier(1.000, 0.008, 0.565, 1.650) .1s 1 forwards;
          }
        }

        @keyframes icon {
          from {
            @apply opacity-0 scale-50;
          }
          to {
            @apply opacity-100 scale-100;
          }
        }

        @keyframes check {
          0% {
            @apply w-6 h-6 border-4;
          }
          10% {
            @apply w-6 h-6 border-4 opacity-10 bg-black/20;
          }
          12% {
            @apply w-6 h-6 border-0 opacity-40 bg-black/10;
          }
          50% {
            @apply w-7 h-7 border-0 opacity-60 bg-ore_orange;
          }
          100% {
            @apply w-7 h-7 border-0 opacity-100 bg-ore_orange;
          }
        }

      }
      }
    }

  }
</style>
