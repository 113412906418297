<template>
  <section class="FAQ-section">
      <h2 class="faq-title">{{ data.title }}</h2>
    <div class="faq-items">
      <div class="col" v-for="(col,index) of faqCols" :key="`col-${index}`">
        <FaqToggle v-for="(faq, indx) of col" :key="`faq-${indx}`" :data="faq" />
      </div>
    </div>
  </section>
</template>
<script setup>
import { computed } from 'vue'
import FaqToggle from '@/components/modules/FaqToggle.vue'
const props = defineProps(['data'])

const faqCols = computed(() => {
  if (props.data.faqs.length > 0) {
    const middle = Math.ceil(props.data.faqs.length / 2)
    const first = props.data.faqs.slice(0, middle)
    const second = props.data.faqs.slice(middle)
    return [first, second]
  } else {
    return [props.data.faqs]
  }
})

</script>
<style lang="scss" scoped>
  .FAQ-section {
    @apply main-container my-36;
    h2 {
      @apply text-ore_green_dark text-3xl font-light leading-[30px] font-FHOscar pl-0 lg:pl-28;
    }
    .faq-items {
      @apply flex flex-col lg:flex-row gap-0 lg:gap-32 py-20;
      .col {
        @apply w-full lg:w-5/12;
      }
    }
  }
</style>
