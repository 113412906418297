<template>
  <div class="cart-card">
    <img class="show-more" src="@/assets/img/show-more.svg"
    :class="
    active
    ? 'transform rotate-180 transition-all duration-300 ease-in-out'
    : 'transform rotate-0 transition-all duration-300 ease-in-out'
    "
    @click="active = !active" />
    <h2 v-html="data.product.title.rendered" />
    <div class="top-area desktop">
      <div class="main-product">
        <div class="remove-product">
          <img class="remove" src="@/assets/img/close.svg" />
          <img class="trash" src="@/assets/img/trash.svg" @click="removeFromCart(index)" />
        </div>
        <div class="edit-product" @click="sendToCustomizer(data, index)">
          <img src="@/assets/img/edit.svg" />
        </div>
      </div>
      <div class="product-image">
        <img :src="data.currentImage" />
      </div>
      <div class="product-finish">
        <p>Finish</p>
        <div class="material-card">
          <div class="material-container">
            <img :src="data.finish['acf-single-product-options-finish-option'].url" />
          </div>
          <p v-html="data.finish['acf-single-product-options-finish-option'].label" />
        </div>
      </div>
      <div class="product-quantity">
        <p>Quantity</p>
        <div class="quantity-selector">
          <div class="less-button" @click="removeProductQuantity(index)"><img src="@/assets/img/minus.svg"></div>
          <div class="quantity">{{data.quantity.toLocaleString('en-US', { minimumIntegerDigits: 2 })}}</div>
          <div class="more-button" @click="addProductQuantity(index)"><img src="@/assets/img/plus.svg"></div>
        </div>
      </div>
    </div>
    <div class="top-area mobile">
      <div class="container-1">
        <div class="product-image">
          <img :src="data.currentImage" />
        </div>
        <div class="main-product">
          <div class="remove-product">
            <img class="remove" src="@/assets/img/close.svg" />
            <img class="trash" src="@/assets/img/trash.svg" @click="removeFromCart(index)" />
          </div>
          <div class="edit-container">
            <div class="edit-product" @click="sendToCustomizer(data, index)">
              <img src="@/assets/img/edit.svg" />
            </div>
          </div>
        </div>
      </div>
      <div class="container-2">
        <div class="product-finish">
          <p>Finish</p>
          <div class="material-card">
            <div class="material-container">
              <img :src="data.finish['acf-single-product-options-finish-option'].url" />
            </div>
            <div class="internal-data">
              <p v-html="data.finish['acf-single-product-options-finish-option'].label" />
            </div>
          </div>
        </div>
        <div class="product-quantity">
          <p>Quantity</p>
          <div class="quantity-selector">
            <div class="less-button" @click="removeProductQuantity(index)"><img src="@/assets/img/minus.svg"></div>
            <div class="quantity">{{data.quantity.toLocaleString('en-US', { minimumIntegerDigits: 2 })}}</div>
            <div class="more-button" @click="addProductQuantity(index)"><img src="@/assets/img/plus.svg"></div>
          </div>
        </div>
      </div>
    </div>
    <slide-up-down v-model="active" :duration="300">
      <div class="bottom-area">
        <div class="offset" />
        <div class="product-size">
          <p>Size</p>
          <p class="text-[24px] md:text-[30px]" v-html="data.size.attributes.attribute_size" />
          <p>
          <span class="mr-3" v-if="data.size.diameter" v-html="isNaN(data.size.diameter) ? `Ø: ${data.size.diameter}″` : `Ø: ${useDecimalToInches(data.size.diameter).toHTMLString()}`" />
          <span class="mr-3" v-if="data.size.dimensions.length" v-html="isNaN(data.size.dimensions.length) ? `L: ${data.size.dimensions.length}″` : `L: ${useDecimalToInches(data.size.dimensions.length).toHTMLString()}`" />
          <span class="mr-3" v-if="data.size.dimensions.width" v-html="isNaN(data.size.dimensions.width) ? `W: ${data.size.dimensions.width}″` : `W: ${useDecimalToInches(data.size.dimensions.width).toHTMLString()}`" />
          <span class="mr-3" v-if="data.size.dimensions.height" v-html="isNaN(data.size.dimensions.height) ? `H: ${data.size.dimensions.height}″` : `H: ${useDecimalToInches(data.size.dimensions.height).toHTMLString()}`" />
          </p>
        </div>
        <div class="design-options">
          <p>Design options</p>
          <div class="options-container">
            <p v-for="(options, idx) of data.designOptions" :key="`${idx}-design-option`">{{options.label}}</p>
          </div>
        </div>
      </div>
    </slide-up-down>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import SlideUpDown from 'vue3-slide-up-down'
import { useRouter } from 'vue-router'
import { cartState } from './Store.js'
import { useDecimalToInches } from '@/composables/useDecimalToInches.js'
const { addProductQuantity, removeProductQuantity, removeFromCart } = cartState()

defineProps(['data', 'index'])
const active = ref(false)
const router = useRouter()

const sendToCustomizer = (data, index) => {
  router.push({
    path: `/products/${data.product.slug}`,
    query: { customizer: true, index }
  })
}

</script>
<style lang="scss" scoped>
  .cart-card {
    @apply bg-white rounded-3xl mb-3 p-3 md:p-7 relative;
    .show-more {
      @apply absolute top-7 right-7 cursor-pointer;
      &:hover {
        @apply transform scale-95;
      }
    }
    h2 {
          @apply text-[30px] text-black mb-10 md:mb-2 w-10/12;
        }
    .top-area.desktop {
      @apply hidden md:flex w-full xl:w-9/12;
      .main-product {
        @apply flex flex-col gap-y-5 w-1/12 mt-10;
        .remove-product {
          @apply flex relative items-center w-10;
          &:hover {
            @apply w-14;
          }
          &:hover .trash {
            @apply -right-8 opacity-100 transition-all duration-150 ease-in-out;
          }
          .remove {
            @apply relative p-2 m-0 z-2 w-10 h-10 bg-form rounded-full transition-all duration-300
            ease-in-out cursor-pointer;
            &:hover {
              @apply transition-all duration-300 ease-in-out transform scale-75;
            }
          }
          .trash {
            @apply cursor-pointer p-2 m-0 bg-red-600 w-10 h-10 rounded-full absolute right-0
            transition-all duration-150 ease-in-out z-1 opacity-0;
          }
        }
        .edit-product {
          @apply flex items-center justify-center w-10 h-10 bg-ore_green rounded-full
          transition-all duration-300 ease-in-out shadow-ore_green/60 shadow-md cursor-pointer;
          &:hover {
            @apply transition-all duration-300 ease-in-out transform scale-105;
          }
          img {
            @apply p-2 m-0 h-8 w-8;
          }
        }
      }
      .product-image {
        @apply flex w-4/12 relative overflow-hidden h-[200px] items-center mr-16;
        img {
          @apply relative object-cover top-5;
        }
      }
      .product-finish {
        @apply w-3/12 mt-8 relative;
        &::before {
          @apply content-[''] border-t-2 border-solid border-ore_green_dark absolute top-0 w-[70%];
        }
        p {
          @apply pt-4 pl-1;
        }
        .material-card {
          @apply mt-5 flex border border-solid border-ore_mint rounded-xl p-2 w-[70%] items-center;
          .material-container {
            @apply rounded-xl h-16 overflow-hidden flex items-center justify-center relative w-5/12;
            img {
              @apply absolute w-40 h-40 object-cover;
            }
          }
          p {
              @apply text-[16px] ml-2 w-7/12 pt-0;
            }
        }
      }
      .product-quantity {
        @apply w-4/12 mt-8 relative;
        &::before {
          @apply content-[''] border-t-2 border-solid border-ore_green_dark absolute top-0 w-[70%];
        }
        p {
          @apply pt-4 pl-1;
        }
        .quantity-selector {
          @apply flex mt-5 border border-ore_mint rounded-xl p-2 w-[90%] items-center justify-center h-20;
          .less-button {
            @apply flex items-center justify-center cursor-pointer px-4 lg:px-6 border-r border-solid border-ore_mint h-full w-4/12;
          }
          .quantity {
            @apply text-[20px] xl:text-[30px] px-4 md:px-8 w-4/12;
          }
          .more-button {
            @apply flex items-center justify-center cursor-pointer px-4 lg:px-6 border-l border-solid border-ore_mint h-full w-4/12;
          }
        }
      }
    }

    .top-area.mobile {
      @apply flex md:hidden w-11/12 gap-x-6;
      .container-1 {
        @apply flex flex-col w-6/12 items-center;
        h2 {
            @apply text-[30px] text-black self-start;
          }
        .main-product {
          @apply flex flex-row gap-x-6 sm:gap-x-16 w-full justify-center h-24;
          .remove-product {
            @apply flex relative items-center;
            .remove {
              @apply relative p-2 m-0 z-2 w-10 h-10 bg-form rounded-full transition-all duration-300
              ease-in-out cursor-pointer;
              &:hover + .trash {
              @apply -bottom-6 -right-0 md:-right-12 md:-bottom-0 opacity-100 transition-all duration-150 ease-in-out;
            }
              &:hover {
                @apply transition-all duration-300 ease-in-out transform scale-75;
              }
            }
            .trash {
              @apply cursor-pointer p-2 m-0 bg-red-600 w-10 h-10 rounded-full absolute bottom-0 right-0
              transition-all duration-150 ease-in-out z-1 opacity-0;
            }
          }
          .edit-container {
            @apply h-full items-center flex justify-center;
            .edit-product {
              @apply flex items-center justify-center w-10 h-10 bg-ore_green rounded-full
              transition-all duration-300 ease-in-out shadow-ore_green/60 shadow-md cursor-pointer;
              &:hover {
                @apply transition-all duration-300 ease-in-out transform scale-105;
              }
              img {
                @apply p-2 m-0 h-8 w-8;
              }
            }
          }
        }
        .product-image {
          @apply flex w-full relative overflow-hidden h-[200px] items-center;
          img {
            @apply relative object-cover top-5;
          }
        }
      }
      .container-2 {
        @apply flex flex-col w-6/12 items-center;
        .product-finish {
          @apply w-full relative;
          p {
            @apply pt-4 pl-1;
          }
          &::before {
            @apply content-[''] border-t-2 border-solid border-ore_green_dark absolute top-0 w-[70%];
          }
          .material-card {
            @apply flex border border-solid border-ore_mint rounded-xl p-2 w-full items-center mt-6;
            .material-container {
              @apply rounded-xl h-16 overflow-hidden flex items-center justify-center relative w-5/12;
              img {
                @apply absolute w-40 h-40 object-cover;
              }
            }
            .internal-data {
              @apply flex flex-col;
              p {
                @apply text-[16px] ml-2 pt-0;
              }
              .title {
                @apply border-b border-solid border-ore_green_dark;
              }
            }

          }
        }
        .product-quantity {
          @apply w-full mt-8 relative;
          &::before {
            @apply content-[''] border-t-2 border-solid border-ore_green_dark absolute top-0 w-[70%];
          }
          p {
            @apply pt-4 pl-1;
          }
          .quantity-selector {
            @apply flex mt-5 border border-ore_mint rounded-xl p-2 items-center justify-center h-20;
            .less-button {
              @apply flex items-center justify-center cursor-pointer px-3 lg:px-6 border-r border-solid border-ore_mint h-full w-4/12;
            }
            .quantity {
              @apply text-[20px] xl:text-[30px] px-2 md:px-8 w-4/12;
            }
            .more-button {
              @apply flex items-center justify-center cursor-pointer px-3 lg:px-6 border-l border-solid border-ore_mint h-full w-4/12;
            }
          }
        }
      }
    }
    .bottom-area {
      @apply flex pt-10 pb-4 flex-col lg:flex-row;
      .offset {
        @apply w-0 md:w-1/12;
      }
      .product-size {
        @apply w-full lg:w-5/12 mt-8 relative;
        &::before {
          @apply content-[''] border-t-2 border-solid border-ore_green_dark absolute top-0 w-[70%];
        }
        p {
          @apply pt-4 pl-1;
        }
      }
      .design-options {
        @apply w-full lg:w-7/12 mt-8 relative;
        &::before {
          @apply content-[''] border-t-2 border-solid border-ore_green_dark absolute top-0 w-[80%];
        }
        p {
          @apply pt-4 pl-1 pb-5;
        }
        .options-container {
          @apply flex flex-col md:flex-row w-full flex-wrap;
          p {
            @apply text-[16px] md:text-[24px] w-full md:w-6/12 pb-1 md:pb-3 pt-0;
          }
        }
      }
    }
  }
</style>
