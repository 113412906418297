<template>
  <section class="image-with-title">
    <div class="image-with-title__wrapper" :class="props.data.image_with_title_preset">
      <div v-show="props.data.image_with_title_preset !== 'preset-2' && props.data.image_with_title_image"
        class="container-text container-text-one"
        :class="{'drop-shadow-md' : props.data.image_with_title_preset === 'preset-3'} "
        :style="imageStyles"
        >
        <div class="inner-wrapper inner-wrapper-one">
          <h2 class="text text-one">{{props.data.image_with_title_title}}</h2>
        </div>
      </div>
      <div class="container-text container-text-two">
        <div class="inner-wrapper inner-wrapper-two">
          <h2 class="text text-two" :style="textColor">{{props.data.image_with_title_title}}</h2>
          <div class="circles" v-show="props.data.image_with_title_preset === 'preset-1'">
            <span class="circle-one"></span>
            <span class="circle-two"></span>
            <span class="circle-three"></span>
          </div>
        </div>
      </div>
      <div class="container-text-bottom" v-if="props.data.image_with_title_preset.length !== 'preset-1' && props.data.image_with_title_text_bottom.length > 0">
        <div class="text-bottom-wrapper" v-html="props.data.image_with_title_text_bottom"></div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, computed, onUpdated, onBeforeUnmount, onMounted } from 'vue'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'

gsap.registerPlugin(ScrollTrigger)

const props = defineProps(['data'])

const imageStyles = computed(() => {
  return {
    'background-image': `url("${props.data.image_with_title_image.url}")`
  }
})

const textColor = computed(() => {
  return {
    color: `rgba(${props.data.image_with_title_title_color.red}, ${props.data.image_with_title_title_color.green}, ${props.data.image_with_title_title_color.blue}, ${props.data.image_with_title_title_color.alpha})`
  }
})

const positions = ref({
  'preset-1': {
    xl: {
      oneFrom: -60,
      oneTo: 320,
      twoFrom: -334,
      twoTo: 46
    },
    lg: {
      oneFrom: -40,
      oneTo: 254,
      twoFrom: -266,
      twoTo: 29
    },
    md: {
      oneFrom: -32,
      oneTo: 258,
      twoFrom: -258,
      twoTo: 32
    },
    xs: {
      oneFrom: -10,
      oneTo: 130,
      twoFrom: -130,
      twoTo: 8
    }
  },
  'preset-3': {
    xl: {
      oneFrom: -60,
      oneTo: 262,
      twoFrom: -367,
      twoTo: -45
    },
    lg: {
      oneFrom: -40,
      oneTo: 262,
      twoFrom: -302,
      twoTo: 0
    },
    md: {
      oneFrom: -40,
      oneTo: 163,
      twoFrom: -181,
      twoTo: 24
    },
    xs: {
      oneFrom: -5,
      oneTo: 135,
      twoFrom: -127,
      twoTo: 13
    }
  }
})

onMounted(() => {
  // console.log('data', props.data)
  if (props.data.image_with_title_preset === 'preset-2') {
    return
  }

  applyTransition()
})

onUpdated(() => {
  if (props.data.image_with_title_preset === 'preset-2') {
    return
  }
  // ScrollTrigger.refresh()
})

onBeforeUnmount(() => {
  const textOneAnim = ScrollTrigger.getById('text-one-anim')
  if (textOneAnim) textOneAnim.kill()

  const textTwoAnim = ScrollTrigger.getById('text-two-anim')
  if (textTwoAnim) textTwoAnim.kill()

  const textOneAnimLg = ScrollTrigger.getById('text-one-anim-lg')
  if (textOneAnimLg) textOneAnimLg.kill()

  const textTwoAnimLg = ScrollTrigger.getById('text-two-anim-lg')
  if (textTwoAnimLg) textTwoAnimLg.kill()

  const textOneAnimMd = ScrollTrigger.getById('text-one-anim-md')
  if (textOneAnimMd) textOneAnimMd.kill()

  const textTwoAnimMd = ScrollTrigger.getById('text-two-anim-md')
  if (textTwoAnimMd) textTwoAnimMd.kill()

  const textOneAnimSm = ScrollTrigger.getById('text-one-anim-sm')
  if (textOneAnimSm) textOneAnimSm.kill()

  const textTwoAnimSm = ScrollTrigger.getById('text-two-anim-sm')
  if (textTwoAnimSm) textTwoAnimSm.kill()

  const circleOne = ScrollTrigger.getById('circle-one')
  if (circleOne) circleOne.kill()

  const circleThree = ScrollTrigger.getById('circle-three')
  if (circleThree) circleThree.kill()
})

function applyTransition () {
  if (props.data.image_with_title_image) {
    ScrollTrigger.matchMedia({
      // xl
      '(min-width: 1440px)': function () {
        // setup animations and ScrollTriggers for screens 960px wide or greater...
        // These ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
        gsap.fromTo(
          '.text-one',
          { top: positions.value[props.data.image_with_title_preset].xl.oneFrom },
          {
            scrollTrigger: {
              id: 'text-one-anim',
              trigger: '.text-one',
              start: 'top-=100 center',
              end: 'bottom+=125 center',
              startTrigger: '.inner-wrapper-one',
              endTrigger: '.inner-wrapper-two',
              // markers: true,
              scrub: true
            },
            top: positions.value[props.data.image_with_title_preset].xl.oneTo
          }
        )

        gsap.fromTo(
          '.text-two',
          { top: positions.value[props.data.image_with_title_preset].xl.twoFrom },
          {
            scrollTrigger: {
              id: 'text-two-anim',
              trigger: '.text-one',
              start: 'top-=100 center',
              end: 'bottom+=125 center',
              startTrigger: '.inner-wrapper-one',
              endTrigger: '.inner-wrapper-two',
              // markers: true,
              scrub: true
            },
            top: positions.value[props.data.image_with_title_preset].xl.twoTo
          }
        )
      },
      // lg
      '(min-width: 1024px) and (max-width: 1439px)': function () {
        // setup animations and ScrollTriggers for screens 960px wide or greater...
        // These ScrollTriggers will be reverted/killed when the media query doesn't match anymore.
        gsap.fromTo(
          '.text-one',
          { top: positions.value[props.data.image_with_title_preset].lg.oneFrom },
          {
            scrollTrigger: {
              id: 'text-one-anim-lg',
              trigger: '.text-one',
              start: 'top-=80 center',
              end: 'bottom+=105 center',
              startTrigger: '.inner-wrapper-one',
              endTrigger: '.inner-wrapper-two',
              // markers: true,
              scrub: true
            },
            top: positions.value[props.data.image_with_title_preset].lg.oneTo
          }
        )

        gsap.fromTo(
          '.text-two',
          { top: positions.value[props.data.image_with_title_preset].lg.twoFrom },
          {
            scrollTrigger: {
              id: 'text-two-anim-lg',
              trigger: '.text-one',
              start: 'top-=80 center',
              end: 'bottom+=105 center',
              startTrigger: '.inner-wrapper-one',
              endTrigger: '.inner-wrapper-two',
              // markers: true,
              scrub: true
            },
            top: positions.value[props.data.image_with_title_preset].lg.twoTo
          }
        )
      },
      // md
      '(min-width: 768px) and (max-width: 1023px)': function () {
        // The ScrollTriggers created inside these functions are segregated and get
        // reverted/killed when the media query doesn't match anymore.
        gsap.fromTo(
          '.text-one',
          { top: positions.value[props.data.image_with_title_preset].md.oneFrom },
          {
            scrollTrigger: {
              id: 'text-one-anim-md',
              trigger: '.text-one',
              start: 'top-=80 center',
              end: 'bottom+=105 center',
              startTrigger: '.inner-wrapper-one',
              endTrigger: '.inner-wrapper-two',
              // markers: true,
              scrub: true
            },
            top: positions.value[props.data.image_with_title_preset].md.oneTo
          }
        )

        gsap.fromTo(
          '.text-two',
          { top: positions.value[props.data.image_with_title_preset].md.twoFrom },
          {
            scrollTrigger: {
              id: 'text-two-anim-md',
              trigger: '.text-one',
              start: 'top-=80 center',
              end: 'bottom+=105 center',
              startTrigger: '.inner-wrapper-one',
              endTrigger: '.inner-wrapper-two',
              // markers: true,
              scrub: true
            },
            top: positions.value[props.data.image_with_title_preset].md.twoTo
          }
        )
      },
      // xs, mobile, sm
      '(max-width: 767px)': function () {
        // ScrollTriggers created here aren't associated with a particular media query,
        // so they persist.
        gsap.fromTo(
          '.text-one',
          { top: positions.value[props.data.image_with_title_preset].xs.oneFrom },
          {
            scrollTrigger: {
              id: 'text-one-anim-sm',
              trigger: '.text-one',
              start: 'top-=80 center',
              end: 'bottom+=105 center',
              startTrigger: '.inner-wrapper-one',
              endTrigger: '.inner-wrapper-two',
              // markers: true,
              scrub: true
            },
            top: positions.value[props.data.image_with_title_preset].xs.oneTo
          }
        )

        gsap.fromTo(
          '.text-two',
          { top: positions.value[props.data.image_with_title_preset].xs.twoFrom },
          {
            scrollTrigger: {
              id: 'text-two-anim-sm',
              trigger: '.text-one',
              start: 'top-=80 center',
              end: 'bottom+=105 center',
              startTrigger: '.inner-wrapper-one',
              endTrigger: '.inner-wrapper-two',
              // markers: true,
              scrub: true
            },
            top: positions.value[props.data.image_with_title_preset].xs.twoTo
          }
        )
      },
      all: function () {
        gsap.fromTo(
          '.circle-one',
          { x: 0 },
          {
            scrollTrigger: {
              id: 'circle-one',
              trigger: '.image-with-title__wrapper',
              start: 'start center',
              end: 'bottom center',
              startTrigger: '.image-with-title',
              endTrigger: '.image-with-title',
              // markers: true,
              scrub: true
            },
            x: 18
          }
        )

        gsap.fromTo(
          '.circle-three',
          { x: 0 },
          {
            scrollTrigger: {
              id: 'circle-three',
              trigger: '.image-with-title__wrapper',
              start: 'start center',
              end: 'bottom center',
              startTrigger: '.image-with-title',
              endTrigger: '.image-with-title',
              // markers: true,
              scrub: true
            },
            x: -18
          }
        )
      }
    })
  }
}
</script>

<style lang='scss' scoped>
.image-with-title {
  @apply w-full flex;

  &__wrapper {
    @apply w-full flex flex-col justify-center;

    &.preset-1 {
      @apply  py-14 md:py-20 lg:py-24 md:px-[18px];

      .container-text {
        @apply px-[18px] md:px-[39px] lg:px-20 desktop:px-28 xl:px-[152px];

        .inner-wrapper {
          @apply main-container px-0;

          .text-two {
            @apply md:my-8 xl:my-20;
          }

          .circles{
            @apply md:h-0 md:m-0 md:p-0 h-auto lg:py-8 lg:pt-44 xl:pb-16 xl:pt-0;
          }
        }

        .text {
          @apply text-[73px] xs:text-[92px] mobile:text-[113px] sm:text-[115px] md:text-[160px] lg:text-[220px] leading-[115px] md:leading-[188px];
        }
      }
    }

    &.preset-2 {
      @apply px-[29px] md:px-[57px] lg:px-20 xl:px-[52px] 2xl:px-[112px] wide-container;

      .container-text {
        .text {
          @apply text-[46px] md:text-[110px] lg:text-[150px] desktop:text-[180px] xl:text-[190px] 2xl:text-[220px] leading-[34px] md:leading-[188px];
        }

        &.container-text-two {
          @apply pb-6;
        }
      }

      .inner-wrapper-two {
        @apply py-[24px] lg:pb-4;

        .text-two {
          @apply md:my-0;
        }
      }

      .container-text-bottom:deep(){
        @apply lg:pl-4 xl:pl-8;
        .text-bottom-wrapper {
          @apply md:gap-2 lg:gap-8 xl:gap-20 2xl:gap-16 md:max-w-full lg:w-[468px] desktop:w-[530px] xl:w-[740px];

          p:first-child {
            @apply md:pr-9 lg:pr-0;
          }

          p:not(:first-child) {
            @apply md:mr-4 lg:mr-0;
          }
        }
      }
    }

    &.preset-3 {
      @apply  py-[35px] md:px-[14px];

      .container-text {
        @apply px-[29px] md:px-[43px] lg:px-[36px] xl:px-0 2xl:px-[112px];

        .inner-wrapper {
          @apply main-container px-0;
        }

        .text {
          @apply text-[39px] mobile:text-[64px] sm:text-[65px] md:text-[119px] lg:text-[152px] desktop:text-[198px] xl:text-[220px] leading-[80px] md:leading-[70px] lg:leading-[188px];
        }
      }

      .inner-wrapper-two {
        @apply py-9 lg:pb-4;

        .text-two {
          @apply md:my-8 xl:my-20;
        }
      }

      .container-text-bottom:deep(){
        @apply md:mt-8 main-container lg:pl-12 desktop:pr-14 desktop:pl-16 xl:pl-24 2xl:pl-20;
        .text-bottom-wrapper {
          @apply md:gap-x-[32px] lg:gap-8 lg:w-[521px] desktop:w-[552px] xl:w-[740px]
        }
      }
    }

    .container-text {
      @apply w-full flex;

      .inner-wrapper {
        @apply overflow-hidden relative h-auto;
      }

      .text {
        @apply font-FHOscar font-medium relative w-max;
      }
    }

    .container-text-one {
      @apply content-center items-end h-[60vh] min-h-[280px] md:h-[768px] md:max-h-[768px] md:rounded-[27px] bg-cover bg-center;

      .inner-wrapper-one {
        @apply md:pt-[80px] lg:pt-[90px];
        .text-one {
          @apply text-white;
        }
      }
    }

    .container-text-two {

      .inner-wrapper-two {
        @apply w-full flex flex-col lg:flex-row;

        .circles{
          @apply flex flex-row space-x-1 w-auto h-auto py-8 lg:pt-44 xl:pt-52 md:invisible lg:visible lg:flex flex-grow self-end justify-center items-end;

          span {
            @apply h-[30px] w-[30px] bg-transparent border border-ore_green_dark rounded-full;
          }
        }
      }
    }

    .container-text-bottom:deep(){
      @apply flex text-xl leading-6 font-medium text-ore_green_dark lg:mt-20;

      .text-bottom-wrapper {
        @apply flex flex-col md:flex-row columns-1 md:columns-2 md:gap-x-[69px] lg:gap-x-14 md:max-w-[766px];

        p {
          @apply mb-4;
        }
      }
    }
  }

}
</style>
