<template>
  <div v-if="thePortfolio" class="wrapper" ref="wrapper">
    <main class="main">
        <CPTHero
        type="Portfolio"
        :category="thePortfolioType"
        :title="thePortfolio.title.rendered"
        :backCTA="backCTA"
        />
        <div class="main-content">
            <DoubleImagesSectionGroup
            :sections="thePortfolio.acf.sections"
            :gallery = "thePortfolio.acf['acf-single-portfolio-gallery']"
            />
        </div>
        <div class="main-bottom">
            <TextContainer
            v-if="thePortfolio.acf.bottom_text_section"
            :data="{
                text_container_content: thePortfolio.acf.bottom_text_section,
                text_container_cta: backCTABottom
            }"/>
            <h3 v-if="thePortfolio.acf['acf-single-portfolio-product-selection']" class="products-title">Products in this project</h3>
            <div class="products-wrapper" v-if="theUsedProducts">
                <div class="product" v-for="prod, idx in thePortfolio.acf['acf-single-portfolio-product-selection']" :key="idx">
                    <a  v-if="theUsedProducts[idx]" :href="theUsedProducts[idx].link">
                        <img :src="theUsedProducts[idx].acf['acf-single-product-image'].sizes.large"/>
                        <span>
                            {{prod['acf-single-portfolio-product-title']}}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </main>
    </div>
</template>

<script setup>
import { onBeforeMount, onUpdated, ref } from 'vue'
import TextContainer from '@/components/modules/TextContainer.vue'
import DoubleImagesSectionGroup from '@/components/utility/DoubleImagesSectionGroup.vue'
import CPTHero from '@/components/utility/CPTHero.vue'

import { useStore } from 'vuex'

const thePortfolio = ref(null)
const thePortfolioType = ref(null)
const theUsedProducts = ref([])

// -------- STATIC DATA --------
const backCTA = {
  target: '',
  title: 'Back'
  //  url: '/portfolio'
}

const backCTABottom = {
  target: '',
  title: 'Back to Portfolio',
  url: '/portfolio'
}

// -------- MODULE VARIABLES --------
const props = defineProps(['slug'])
const store = useStore()
const productsIDs = () => {
  const products = thePortfolio.value.acf['acf-single-portfolio-product-selection']
  if (products) {
    return products.map((prod) => {
      return prod['acf-single-portfolio-product-link'][0].ID
    })
  } else {
    return []
  }
}

// -------- STORE REQUESTS --------
const request = {
  type: 'portfolio',
  slug: props.slug,
  showLoading: true
}
const portfolioTypeRequest = (typeId = 0) => {
  return {
    type: 'portfolio-type',
    id: typeId,
    showLoading: false
  }
}
const productRequest = (prodId) => {
  return {
    type: 'products',
    id: prodId,
    showLoading: false
  }
}
// -------- DISPATCHES --------
const getPortfolio = async () => {
  await store.dispatch('getSingleBySlug', request)
  const data = store.getters.singleBySlug(request)
  thePortfolio.value = data
}
const getPortfolioType = async () => {
  await store.dispatch('getSingleById', portfolioTypeRequest(thePortfolio.value['portfolio-type'][0]))
  const protfolioTypeData = store.getters.singleById(portfolioTypeRequest(thePortfolio.value['portfolio-type'][0]))
  thePortfolioType.value = protfolioTypeData
}
const getFeaturedProducts = async (id) => {
  // console.log(productsIDs())
  await productsIDs().forEach(async id => {
    await store.dispatch('getSingleById', productRequest(id))
    const productData = store.getters.singleById(productRequest(id))
    // console.log(productData)
    theUsedProducts.value = [...theUsedProducts.value, productData]
  })
}

// -------- LIFECYCLE HOOKS --------
onBeforeMount(async () => {
  await getPortfolio()
  await store.dispatch('updateDocTitle', {
    parts: [
      document.title = thePortfolio.value.title.rendered,
      store.state.site.name
    ]
  })
  await getFeaturedProducts()
})
onUpdated(() => {
  if (thePortfolio.value) {
    getPortfolioType()
  }
})
</script>

<style lang="scss" scoped>
.main {
    &-content {
        @apply flex flex-col items-center
        ;
        section {
            @apply w-full flex justify-center;
        }
        // commenting this section just in case
        // .misc-content {
        //     @apply px-5 mt-10 mb-[70px]
        //     md:px-[60px]
        //     lg:w-[60%] lg:self-end lg:pr-[120px] lg:mb-[150px] lg:mt-[-270px]
        //     xl:w-[50%] xl:mb-[225px];
        //     &-text {
        //         &:deep(p) {
        //             @apply text-[30px] leading-none
        //             md:text-[40px]
        //             lg:text-[30px]
        //             xl:text-[40px];
        //         }
        //         &:deep(p+p) {
        //             @apply mt-6;
        //         }
        //     }
        // }
    }
    &-bottom{
        @apply pb-[74px]
        md:pb-[100px]
        lg:pb-[156px];
        &:deep(p) {
            font-size: 36px !important;
            @media (min-width: 760px) {
                font-size: 60px !important;
            }
            @media (min-width: 1024px) {
                font-size: 70px !important;
            }
        }
        .products-title {
            @apply text-ore_orange main-container text-2xl mb-6 mt-[66px]
            md:mb-9 md:mt-[108px]
            lg:text-[40px] lg:mb-20 lg:mt-[138px]
            xl:px-[100px]
            2xl:main-container;
        }
        .products-wrapper {
        @apply grid grid-cols-1 gap-5 px-5
        md:grid-cols-2
        xl:px-[100px] lg:grid-cols-4
        2xl:main-container
        ;
            .product {
                @apply pb-[20px] pt-[10px] bg-white aspect-square rounded-[20px]
                md:flex-1 overflow-hidden transition-transform duration-300;

                a {
                    @apply w-full h-full flex flex-col justify-between;
                    img {
                        @apply h-[85%] object-contain transition-transform duration-300;
                    }
                    span{
                        @apply px-[20px] leading-[12px] transition-transform duration-300;
                    }
                }
                &:hover{
                  @apply scale-95;
                    a {
                        img {
                            @apply scale-110;
                        }
                        span{
                          @apply transform translate-x-1 -translate-y-1 lg:translate-x-3 lg:-translate-y-3 ;
                        }
                    }
                }
            }
        }
    }
}
</style>
