<template>
  <div class="wrapper" ref="wrapper">
    <main class="main" id="global-wrapper">
      <template v-if="page">
        <template v-for="(module, index) in page.acf.modules">
          <template v-if="module.acf_fc_layout === 'container'">
            <ModulesContainer :key="index" :data="module" :index="index">
              <template v-for="(module, index) in module.inner_content">
                <template v-if="module.acf_fc_layout === 'forms'">
                  <Forms :key="index" :data="module" :index="index" />
                </template>
                <template v-if="module.acf_fc_layout === 'imageModule'">
                  <ImageComponent :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'wideImageModule'">
                  <WideImage :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'homeHero'">
                  <HomeHero :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'textContainer'">
                  <TextContainer :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'spacermodule'">
                  <SpacerModule :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'titleWithTwoColumns'">
                  <TitleWithTwoColumns :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'fuseHome'">
                  <FuseHome :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'textWithCta'">
                  <TextWithCta :key="index" :data="module" :index="index" />
                </template>
                <template
                  v-else-if="module.acf_fc_layout === 'productCarousel'"
                >
                  <ProductCarousel :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'text_gallery'">
                  <TextGallery :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'decoratedTextCols'">
                  <DecoratedTextCols :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'featuredPosts'">
                  <FeaturedPosts :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'imageWithTitle'">
                  <ImageWithTitle :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'image_text_2'">
                  <ImageText2 :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'customHero'">
                  <CustomHero :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'columnsData'">
                  <ColumnsData :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'our_values'">
                  <OurValues :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'image_text'">
                  <ImageText :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'ceo_bio'">
                  <CeoBio :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'animatedList'">
                  <AnimatedList :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'fixedButton'">
                  <FixedButton :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'categories_list'">
                  <CategoriesList :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'listWithAnimation'">
                  <ListWithAnimation :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'address_info'">
                    <AddressInfo :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'titleWithList'">
                  <TitleWithList :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'scrollableList'">
                  <ScrollableList :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'contact_cards'">
                    <ContactCards :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'portfolioHero'">
                  <PortfolioHero :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'projects_filters'">
                    <ProjectsFilters :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'listWithAnimation2'">
                  <ListWithAnimation2 :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'blogs_list'">
                   <BlogsList :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'thanksForSubmitting'">
                  <ThanksForSubmitting :key="index" :data="module" :index="index" />
                 </template>
                <template v-else-if="module.acf_fc_layout === 'decoratedTextColsVariant'">
                  <DecoratedTextColsVariant :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'cart_module'">
                  <CartModule :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'requestQuote'">
                  <RequestQuote :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'careers'">
                  <CareersComponent :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'wysiwyg'">
                  <Wysiwyg :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'menu_anchor'">
                  <MenuAnchor :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'terms_conditions'">
                  <TermsConditions :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'faq'">
                  <Faq :key="index" :data="module" :index="index" />
                </template>
                <template v-else-if="module.acf_fc_layout === 'call_to_action'">
                  <CallToAction :key="index" :data="module" :index="index" />
                </template>
              </template>
            </ModulesContainer>
          </template>
          <template v-if="module.acf_fc_layout === 'forms'">
            <Forms :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'imageModule'">
            <ImageComponent :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'wideImageModule'">
            <WideImage :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'homeHero'">
            <HomeHero :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'image_text_2'">
            <ImageText2 :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'fuseHome'">
            <FuseHome :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'productCarousel'">
            <ProductCarousel :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'textContainer'">
            <TextContainer :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'spacermodule'">
            <SpacerModule :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'titleWithTwoColumns'">
            <TitleWithTwoColumns :key="index" :data="module" :index="index" />
            </template>
          <template v-else-if="module.acf_fc_layout === 'textWithCta'">
            <TextWithCta :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'imageWithTitle'">
            <ImageWithTitle :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'featuredPosts'">
            <FeaturedPosts :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'decoratedTextCols'">
            <DecoratedTextCols :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'customHero'">
            <CustomHero :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'columnsData'">
            <ColumnsData :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'our_values'">
            <OurValues :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'image_text'">
            <ImageText :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'ceo_bio'">
            <CeoBio :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'animatedList'">
            <AnimatedList :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'fixedButton'">
            <FixedButton :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'categories_list'">
            <CategoriesList :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'listWithAnimation'">
            <ListWithAnimation :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'address_info'">
              <AddressInfo :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'titleWithList'">
            <TitleWithList :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'scrollableList'">
            <ScrollableList :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'contact_cards'">
              <ContactCards :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'portfolioHero'">
            <PortfolioHero :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'projects_filters'">
              <ProjectsFilters :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'listWithAnimation2'">
            <ListWithAnimation2 :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'text_gallery'">
            <TextGallery :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'thanksForSubmitting'">
            <ThanksForSubmitting :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'blogs_list'">
            <BlogsList :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'decoratedTextColsVariant'">
            <DecoratedTextColsVariant :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'cart_module'">
              <CartModule :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'requestQuote'">
            <RequestQuote :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'careers'">
            <CareersComponent :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'wysiwyg'">
            <Wysiwyg :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'menu_anchor'">
            <MenuAnchor :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'finish_list'">
            <FinishList :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'product_info'">
            <ProductInfo :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'terms_conditions'">
            <TermsConditions :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'faq'">
            <Faq :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'portfolio_list'">
            <PortfolioList :key="index" :data="module" :index="index" />
          </template>
          <template v-else-if="module.acf_fc_layout === 'call_to_action'">
            <CallToAction :key="index" :data="module" :index="index" />
          </template>
        </template>
      </template>
    </main>
  </div>
  </template>

<script>
import ImageText from '@/components/modules/ImageText.vue'
import Forms from '@/components/modules/Forms.vue'
import ImageComponent from '@/components/modules/ImageComponent.vue'
import WideImage from '@/components/modules/WideImage.vue'
import HomeHero from '@/components/modules/HomeHero.vue'
import ImageText2 from '@/components/modules/ImageText2.vue'
import TextContainer from '@/components/modules/TextContainer.vue'
import ImageWithTitle from '@/components/modules/ImageWithTitle.vue'
import FeaturedPosts from '@/components/modules/FeaturedPosts.vue'
import CeoBio from '@/components/modules/CeoBio.vue'
import TitleWithTwoColumns from '@/components/modules/TitleWithTwoColumns.vue'
import DecoratedTextCols from '@/components/modules/DecoratedTextCols.vue'
import CustomHero from '@/components/modules/CustomHero.vue'
import ColumnsData from '@/components/modules/ColumnsData.vue'
import ModulesContainer from '@/components/ModulesContainer.vue'
import ProductCarousel from '@/components/modules/ProductCarousel.vue'
import SpacerModule from '@/components/modules/Spacer.vue'
import TextWithCta from '@/components/modules/TextWithCta.vue'
import FuseHome from '@/components/modules/FuseHome.vue'
import AnimatedList from '@/components/modules/AnimatedList.vue'
import TextGallery from '@/components/modules/TextGallery.vue'
import OurValues from '@/components/modules/OurValues.vue'
import FixedButton from '@/components/modules/FixedButton.vue'
import CategoriesList from '@/components/modules/CategoriesList.vue'
import ListWithAnimation from '@/components/modules/ListWithAnimation.vue'
import AddressInfo from '@/components/modules/AddressInfo.vue'
import PortfolioHero from '@/components/modules/PortfolioHero.vue'
import TitleWithList from '@/components/modules/TitleWithList.vue'
import ScrollableList from '@/components/modules/ScrollableList.vue'
import ContactCards from '@/components/modules/ContactCards.vue'
import ProjectsFilters from '@/components/modules/ProjectsFilters.vue'
import ListWithAnimation2 from '@/components/modules/ListWithAnimation2.vue'
import BlogsList from '@/components/modules/BlogsList.vue'
import ThanksForSubmitting from '@/components/modules/ThanksForSubmitting.vue'
import DecoratedTextColsVariant from '@/components/modules/DecoratedTextColsVariant.vue'
import CartModule from '@/components/Cart/CartModule.vue'
import RequestQuote from '@/components/modules/RequestQuote.vue'
import CareersComponent from '@/components/modules/CareersComponent.vue'
import Wysiwyg from '@/components/modules/Wysiwyg.vue'
import MenuAnchor from '@/components/utility/MenuAnchor.vue'
import FinishList from '@/components/modules/FinishList.vue'
import ProductInfo from '@/components/modules/ProductInfo.vue'
import TermsConditions from '@/components/modules/TermsConditions.vue'
import Faq from '@/components/modules/Faq.vue'
import PortfolioList from '@/components/modules/PortfolioList.vue'
import CallToAction from '@/components/modules/CallToAction.vue'

export default {
  components: {
    Forms,
    ImageComponent,
    WideImage,
    HomeHero,
    ImageText2,
    FuseHome,
    ProductCarousel,
    TextContainer,
    TextWithCta,
    ImageText,
    ModulesContainer,
    SpacerModule,
    CeoBio,
    ImageWithTitle,
    FeaturedPosts,
    TitleWithTwoColumns,
    DecoratedTextCols,
    AnimatedList,
    CustomHero,
    ColumnsData,
    FixedButton,
    CategoriesList,
    ListWithAnimation,
    PortfolioHero,
    TitleWithList,
    ScrollableList,
    OurValues,
    ContactCards,
    ProjectsFilters,
    AddressInfo,
    ListWithAnimation2,
    TextGallery,
    BlogsList,
    ThanksForSubmitting,
    DecoratedTextColsVariant,
    CartModule,
    RequestQuote,
    CareersComponent,
    Wysiwyg,
    MenuAnchor,
    FinishList,
    ProductInfo,
    TermsConditions,
    Faq,
    PortfolioList,
    CallToAction
  },
  name: 'MainPage',
  props: {
    slug: {
      type: String,
      required: true
    },
    isHomepage: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      request: {
        type: 'pages',
        slug: this.slug,
        showLoading: true
      },
      introShown: false,
      navOpened: false
    }
  },
  computed: {
    page () {
      this.normalizeData('forms')
      return this.$store.getters.singleBySlug(this.request)
    }
  },
  methods: {
    getPage () {
      this.$store
        .dispatch('getSingleBySlug', this.request)
        .then(() => {
          if (this.page) {
            if (!this.isHomepage) {
              this.$store.dispatch('updateDocTitle', {
                parts: [
                  this.$root.decodeTitle(this.page.title.rendered),
                  this.$store.state.site.name
                ]
              })
            } else {
              this.$store.dispatch('updateDocTitle', {
                parts: [null, this.$store.state.site.name]
              })
            }
          } else {
            this.$router.replace('/404')
          }
        })
        .then(() => {
          // this.$gtag.pageview({
          //   page_path: this.$root.convertToRelative(this.page.link),
          //   page_title: document.title,
          //   page_location: window.location.href,
          // })
        })
    },
    setAnchors () {
      // Grab all elements that have a "data-target" attribute
      const scrollButtons = document.querySelectorAll('[data-target]')

      // For each element, listen to a "click" event
      scrollButtons.forEach(button => {
        button.addEventListener('click', e => {
          e.preventDefault()

          // get the DOM element by the ID (data-target value)
          const target = button.dataset.target
          const el = document.getElementById(target.replace('#', ''))

          // Use lenis.scrollTo() to scroll the page to the right element
          this.$root.locoScroll.scrollTo(el, {
            offset: 0,
            immediate: true
          })
        })
      })
    },
    normalizeData (type) {
      if (type) {
        const filtered = this.$store.getters.singleBySlug(this.request)
        const rawData = this.$store.getters.getPosts({ type })
        if (filtered) {
          for (const module of filtered.acf.modules) {
            if (module.acf_fc_layout === type) {
              const data = Object.entries(rawData).map((x) => x[1])
              data.pop()
              module[type] = data
            }
          }
        }
      }
    },
    introComplete () {
      this.introShown = true
      sessionStorage.setItem('introShown', true)
    }
  },
  created () {
    this.getPage()
  },
  mounted () {
    this.setAnchors()
    const anchor = this.$router.currentRoute.hash
    this.$nextTick(() => {
      if (anchor && document.querySelector(anchor)) {
        location.href = anchor
      }
    })
  }
}
</script>

<style lang="scss">
</style>
