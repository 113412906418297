<template>
<!-- container -->
  <section class="scrollable-list">
    <section class="extra-padding"></section>
    <section v-if="!isMobile" class="scrollable-list__wrapper__tablet">
      <!-- inner-container -->
      <div class="inner-wrapper">
        <!-- content -->
        <div class="item">
          <!-- scrolling-image -->
          <div class="top">
            <!-- title-area -->
            <div class="title-area">
              <div class="titles">
                <div class="scrolling-title" :class="{'one': index === 0, 'two': index === 1, 'three': index === 2}" v-for="(item, index) in data.scrollable_list" :key="index">
                  <h3 class="title">
                    {{item.scrollable_list_item_title}}
                  </h3>
                  <span class="scroll-number">
                    <small>0{{index + 1}} </small>
                  </span>
                </div>
              </div>
              <div class="numbers">
                <span class="static-number">
                  /<small>0{{itemCount}}</small>
                </span>
              </div>
            </div>
            <div class="image-section">
              <div class="images">
                <img :class="{'one-img': index === 0, 'two-img': index === 1, 'three-img': index === 2}" v-for="(item, index) in data.scrollable_list" :key="index" :src="item.scrollable_list_item_image.url" :alt="item.scrollable_list_item_image.alt" class="image">
              </div>
            </div>
          </div>
          <!-- scrolling-text -->
          <div class="bottom">
            <div :class="{'one-text': index === 0, 'two-text': index === 1, 'three-text': index === 2}" v-for="(item, index) in data.scrollable_list" :key="index" class="text-section">
              <h4 class="small-title">{{item.scrollable_list_item_title}}</h4>
              <div class="text" v-html="item.scrollable_list_item_content"></div>
              <OreButton v-if="item.scrollable_list_item_cta"
                :url="item.scrollable_list_item_cta.url"
                :text="item.scrollable_list_item_cta.title"
                :target="item.scrollable_list_item_cta.target"
                lineHover="bg-mint-30"
                circleColor="circle-orange"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-if="isMobile" class="scrollable-list__wrapper__mobile">
      <div class="item" v-for="(item, index) in data.scrollable_list" :key="index">
        <div class="top">
          <h4 class="title">
            {{item.scrollable_list_item_title}}

            <span class="num">
              <small>0{{index + 1}} </small> /
              <small>0{{itemCount}}</small>
            </span>
          </h4>
          <img :src="item.scrollable_list_item_image.url" :alt="item.scrollable_list_item_image.alt" class="image">
        </div>
        <div class="bottom">
          <span class="small-title">{{item.scrollable_list_item_title}}</span>
          <div class="text" v-html="item.scrollable_list_item_content"></div>
          <OreButton v-if="item.scrollable_list_item_cta"
            :url="item.scrollable_list_item_cta.url"
            :text="item.scrollable_list_item_cta.title"
            :target="item.scrollable_list_item_cta.target"
            lineHover="bg-mint-30"
            circleColor="circle-orange"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import OreButton from '@/components/utility/Button.vue'

export default {
  name: 'ScrollableList',
  components: {
    OreButton
  },
  props: ['data'],
  data () {
    return {
      itemCount: 0,
      screenWidth: window.innerWidth,
      isMobile: false,
      isTablet: false,
      isDesktop: false
    }
  },
  methods: {
    deviceAsign () {
      this.screenWidth = window.innerWidth
      if (this.screenWidth < 768) {
        this.isMobile = true
        this.isTablet = false
        this.isDesktop = false
      } else if (this.screenWidth >= 768 && this.screenWidth < 1024) {
        this.isTablet = true
        this.isMobile = false
        this.isDesktop = false
      } else if (this.screenWidth >= 1024) {
        this.isTablet = false
        this.isMobile = false
        this.isDesktop = true
      }
    },
    onWindowResize () {
      this.deviceAsign()
      if (this.isMobile) {
        return
      }
      this.buildAnimationForDesktop()
    },
    setItemCountValue () {
      this.itemCount = this.data.scrollable_list.length
    },
    buildAnimationForDesktop () {
      const elements = document.querySelectorAll('.scrollable-list__wrapper__tablet')
      if (elements.length === 0) {
        return
      }

      const self = this
      ScrollTrigger.create({
        id: 'master',
        trigger: '.images',
        start: 'center-=15% center',
        end: 'bottom bottom',
        endTrigger: '.bottom',
        pin: '.top',
        markers: false
      })

      gsap.fromTo('.titles', {
        y: 0
      }, {
        scrollTrigger: {
          id: 'titles',
          trigger: '.images',
          start: 'center center',
          end: 'bottom bottom',
          endTrigger: '.bottom',
          scrub: true,
          markers: false
        },
        y: this.isTablet ? -(((gsap.getProperty('.titles', 'height') / 3)) * 2.22) : -(((gsap.getProperty('.titles', 'height') / 3)) * 2.1)
      })

      gsap.fromTo('.one-img', {
        opacity: 1
      }, {
        scrollTrigger: {
          id: 'one-img',
          trigger: '.bottom',
          start: 'top+=30% center',
          end: 'center center',
          endTrigger: '.bottom',
          scrub: true,
          markers: false
        },
        opacity: 0
      })

      gsap.fromTo('.two-img', {
        opacity: 1
      }, {
        scrollTrigger: {
          id: 'two-img',
          trigger: '.bottom',
          start: 'center+=10% center',
          end: 'center+=30% center',
          endTrigger: '.bottom',
          scrub: true,
          markers: false
        },
        opacity: 0
      })

      ScrollTrigger.create({
        id: 'one-text',
        trigger: '.one-text',
        duration: 0.75,
        scrub: true,
        start: 'top center',
        end: 'bottom center',
        onEnter: () => self.$root.locoScroll.scrollTo('.one-text'),
        onEnterBack: () => self.$root.locoScroll.scrollTo('.one-text'),
        markers: false
      })
      ScrollTrigger.create({
        id: 'two-text',
        trigger: '.two-text',
        duration: 0.75,
        scrub: true,
        start: 'top center',
        end: 'bottom center',
        onEnter: () => self.$root.locoScroll.scrollTo('.two-text'),
        onEnterBack: () => self.$root.locoScroll.scrollTo('.two-text'),
        markers: false
      })
      ScrollTrigger.create({
        id: 'three-text',
        trigger: '.three-text',
        duration: 0.75,
        scrub: true,
        start: 'top center',
        end: 'bottom center',
        onEnter: () => self.$root.locoScroll.scrollTo('.three-text'),
        onEnterBack: () => self.$root.locoScroll.scrollTo('.three-text'),
        markers: false
      })
    },
    clearScrollTrigger () {
      /*
       * no error but media query events still fire after unmount
      */
      const master = ScrollTrigger.getById('master')
      if (master) master.kill()

      const titles = ScrollTrigger.getById('titles')
      if (titles) titles.kill()

      const oneImg = ScrollTrigger.getById('one-img')
      if (oneImg) oneImg.kill()

      const twoImg = ScrollTrigger.getById('two-img')
      if (twoImg) twoImg.kill()

      const oneText = ScrollTrigger.getById('one-text')
      if (oneText) oneText.kill()

      const twoText = ScrollTrigger.getById('two-text')
      if (twoText) twoText.kill()

      const threeText = ScrollTrigger.getById('three-text')
      if (threeText) threeText.kill()
    }
  },
  beforeMount () {
    gsap.registerPlugin(ScrollTrigger)
    window.addEventListener('resize', this.onWindowResize)
  },
  mounted () {
    this.setItemCountValue()
    this.onWindowResize()
  },
  updated () {
    // ScrollTrigger.refresh()
  },
  beforeUnmount () {
    window.removeEventListener('resize', this.onWindowResize)
    this.clearScrollTrigger()
  }
}
</script>

<style lang="scss" scoped>
.scrollable-list {
  @apply flex flex-col w-full h-full font-FHOscar font-medium text-ore_green_dark px-[10px] pb-16 md:px-8;

  .extra-padding {
    @apply md:py-20 lg:py-28 md:px-0;
  }

  section {
    @apply md:w-full md:my-0 md:mx-auto md:py-3 md:px-0;
  }

  &__wrapper__tablet {
    @apply main-container w-full flex flex-col px-0 h-full;

    .inner-wrapper {
      @apply flex flex-col;
      .item {
        @apply w-full flex flex-col md:flex-row pb-16 md:pb-0 md:gap-x-14 lg:gap-x-28;
        .top {
          @apply flex flex-col w-full md:w-6/12;

          .title-area:deep() {
            @apply w-full h-full flex justify-between overflow-y-hidden bg-white rounded-[24px] py-5 px-8 mb-4 md:h-16 lg:h-24;

            h3, span, small {
              @apply text-2xl lg:text-[40px] leading-6 lg:leading-10;

              small {
                @apply text-ore_orange;
              }
            }

            .titles {
              @apply w-full h-fit;

              .scrolling-title {
                @apply flex w-full justify-between pt-20 pb-20;
                &:first-child {
                  @apply pt-1;
                }
                &:nth-child(2) {
                  @apply pb-12 pt-24;
                }
                &:last-child {
                  @apply pt-0;
                }
                .title {
                  @apply m-0 text-ore_green_dark text-lg lg:text-4xl leading-normal;
                }
              }
            }

            .numbers {
              @apply flex items-center text-ore_green_dark md:pl-5;

              small {
                @apply md:pl-5;
              }
            }
          }

          .image-section {
            @apply w-full;

            .images {
              @apply relative w-full md:h-[328px] lg:h-[420px] desktop:h-[440px] xl:h-[632px] lg:max-h-[700px];

              .image {
                @apply object-cover absolute w-full h-full rounded-3xl mb-7;
              }

              .one-img {
                @apply z-3;
              }

              .two-img {
                @apply z-2;
              }

              .three-img {
                @apply z-1;
              }
            }
          }
        }

        .bottom {
          @apply w-5/12 h-full;

          .text-section {
            @apply flex flex-col justify-center h-screen;
          }

          .small-title {
            @apply text-xl leading-6 mb-7 lg:mb-9;
          }

          .text:deep(){
            @apply lg:mb-11;
            p {
              @apply text-2xl lg:text-[40px] leading-6 lg:leading-10;
            }
          }
        }
      }
    }
  }

  &__wrapper__mobile {
    @apply main-container w-full flex flex-col px-0;

    .item {
      @apply w-full flex flex-col pb-16;

      .top {
        @apply flex flex-col w-full;
        .title {
          @apply text-2xl leading-6 bg-white rounded-[24px] py-5 px-8 flex justify-between mb-4;

          .num {
            @apply text-ore_green_dark;

            small {
              @apply text-ore_orange text-2xl leading-4 px-2;
            }
          }

        }

        .image{
          @apply bg-cover object-cover bg-center bg-no-repeat w-full h-80 max-h-80 rounded-[24px] mb-7;
        }
      }

      .bottom {
        @apply flex flex-col w-full;

        .small-title {
          @apply text-xl leading-6 mb-7 lg:mb-9;
        }

        .text:deep(){
          @apply lg:mb-11;
          p {
            @apply text-2xl lg:text-[40px] leading-6 lg:leading-10;
          }
        }
      }
    }
  }
}
</style>
