<template>
  <section class="text-cols-w-deco">
    <div class="container">
        <div class="deco-cols" :class="{'content-reversed': data.deco_cols_reversed }">
        <div class="deco-cols-decoration" :class="{'left-offset': data.deco_left_padding}">
          <img
            class="deco-cols-decoration-image"
            src="@/assets/img/three_circles_decoration.svg"
          />
        </div>
        <div class="deco-cols-content">
          <h3 class="deco-cols-content-title" v-html="data.content_title"/>
          <div class="deco-cols-content-text" :class="`text-${data.decoratedTextCols_fontSize}`" v-html="data.content"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, onBeforeUnmount } from 'vue'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
defineProps(['data'])

gsap.registerPlugin(ScrollTrigger)
const animateDecoration = () => {
  gsap.to('.deco-cols-decoration-image', {
    rotate: 180 * 3,
    scrollTrigger: {
      id: 'decoration',
      trigger: '.text-cols-w-deco',
      start: 'top 75%',
      end: 'bottom top',
      scrub: 0.5
    }
  })
}

onMounted(() => {
  animateDecoration()
})

onBeforeUnmount(() => {
  const decoration = ScrollTrigger.getById('decoration')
  if (decoration) decoration.kill()
})

</script>

<style lang="scss" scoped>
.text-cols-w-deco {
  @apply main-container px-0;
  .container {
    @apply z-1 relative w-full max-w-screen-xl
    mx-auto pl-[29px] pr-[34px] py-[50px]
    flex flex-col items-center
    md:px-[60px] md:pb-[120px]
     xl:pt-[135px] xl:pb-[200px] xl:px-28;
    .deco-cols {
      @apply mb-12 w-full relative flex flex-col items-center gap-[100px] text-[20px] leading-none
      md:px-0 md:flex-row md:gap-[60px]
      lg:px-[14px]
      lg:justify-between
      xl:justify-start xl:text-[30px] xl:gap-[360px];
      &-decoration {
        @apply absolute right-[15px] top-0
        md:relative md:-mt-9
        md:self-center
        lg:self-start lg:mt-2
        ;
        &.left-offset{
            @apply xl:translate-x-[40px];
        }
        img {
          @apply min-w-[59px] min-h-[55px];
        }
      }
      &-content {
        @apply flex flex-col gap-[40px]
        md:gap-[40px]
        lg:w-[calc(100%_-_360px)]
        ;

        &-title{
            @apply min-h-[48px] max-w-[210px] md:max-w-[340px] md:min-h-0 lg:max-w-none;
          &:deep(p) {
            @apply text-[24px] leading-none md:text-[30px] lg:text-[30px];
          }
        }
        &-text {
          @apply flex flex-col
          gap-[25px]
          md:flex-row md:gap-[65px]
          lg:gap-[30px] lg:justify-between
          xl:justify-start xl:gap-[114px];
           :deep(p) {
            @apply text-[20px] leading-[24px] md:flex-1 lg:max-w-[320px] md:w-6/12 lg:w-[calc(50%_-_15px)];
          }
          &.text-md {
            :deep(p) {
              @apply lg:text-[30px] lg:leading-none;
            }
          }
          &.text-sm {
            :deep(p) {
              @apply lg:text-[20px] lg:leading-[24px];
            }
          }
        }
      }
    }
    .content-reversed {
        @apply lg:flex-row-reverse;

        .deco-cols-content-text {
            @apply lg:gap-[55px]
            ;
            &:deep(p) {
                @apply lg:min-w-[340px];
            }
        }
        .deco-cols-decoration{
            @apply xl:translate-x-[-100px]
        }
    }
  }
}
</style>
