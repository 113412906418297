// Route components
import Page from '@/components/Page.vue'
import Posts from '@/components/Posts.vue'
import NotFound from '@/components/404.vue'
import AuthorArchive from '@/components/AuthorArchive.vue'
import DateArchive from '@/components/DateArchive.vue'
import CategoryArchive from '@/components/CategoryArchive.vue'
import TagArchive from '@/components/TagArchive.vue'
import SinglePost from '@/components/SinglePost.vue'
import CustomizerView from '@/components/Customizer/CustomizerView.vue'
import SingleTeam from '@/components/SingleTeam.vue'
import SinglePortfolio from '@/components/SinglePortfolio.vue'
import SingleProductType from '@/components/SingleProductType.vue'
// Route paths as formatted in WP permalink settings
import paths from './paths'
// Route composition utilities
import {
  categorySlugFromParams,
  pageFromPath
} from './utils'

const { show_on_front, page_for_posts, page_on_front } = window.__VUE_WORDPRESS__.routing

const postsPageRoute = show_on_front === 'page' && page_for_posts
  ? {
      path: paths.postsPage(page_for_posts),
      component: Posts,
      name: 'Posts',
      props: route => ({ page: pageFromPath(route.path), slug: page_for_posts })
    }
  : null

const rootRoute = show_on_front === 'page' && page_on_front
  ? {
      path: '/',
      component: Page,
      name: 'Home',
      meta: { bodyClass: 'home' },
      props: () => ({
        slug: page_on_front,
        isHomepage: true,
        showIntro: true
      })
    }
  : null

const routes = [
  rootRoute,
  postsPageRoute,
  {
    path: '/404',
    component: NotFound,
    name: '404'
  },
  {
    path: paths.authorArchive,
    component: AuthorArchive,
    name: 'AuthorArchive',
    props: route => (Object.assign(route.params, { page: pageFromPath(route.path) }))
  },
  {
    path: paths.dateArchive,
    component: DateArchive,
    name: 'DateArchive',
    props: route => (Object.assign(route.params, { page: pageFromPath(route.path) }))
  },
  {
    path: paths.categoryArchive,
    component: CategoryArchive,
    name: 'CategoryArchive',
    props: route => (Object.assign(route.params, { slug: categorySlugFromParams(route.params), page: pageFromPath(route.path) }))
  },
  {
    path: paths.tagArchive,
    component: TagArchive,
    name: 'TagArchive',
    props: route => (Object.assign(route.params, { page: pageFromPath(route.path) }))
  },
  {
    path: '/products/:slug',
    component: CustomizerView,
    name: 'ProductCustomizer',
    props: route => ({ slug: route.params.slug })
  },
  {
    // remove /ore/
    path: '/portfolio/:slug',
    component: SinglePortfolio,
    name: 'SinglePortfolio',
    props: route => ({ slug: route.params.slug })
  },
  {
    path: paths.single,
    component: SinglePost,
    name: 'Single',
    props: route => ({ slug: route.params.slug, newsSlug: page_for_posts })
  },
  {
    path: '/:slugs+',
    component: Page,
    name: 'Page',
    props: route => ({ slug: route.params.slugs[0].split('/').filter(s => s).pop() })
  },
  {
    path: '/product-type/:slug',
    component: SingleProductType,
    name: 'SingleProductType',
    props: route => ({ slug: route.params.slug })
  }
].filter(route => route) // Removes empty route objects

export default routes
