export const useDecimalToInches = (decimal, precision = 32) => {
  const reduce = (numerator, denominator) => {
    let gcd = function gcd (a, b) {
      return b ? gcd(b, a % b) : a
    }
    gcd = gcd(numerator, denominator)
    return { n: numerator / gcd, d: denominator / gcd }
  }

  const properToMixed = ({ n, d }) => {
    const quotient = Math.floor(n / d)
    const remainder = n % d
    if (remainder === 0) {
      return { q: quotient }
    } else {
      return { q: quotient, r: remainder, d }
    };
  }

  const getInches = (decimal, denominator) => {
    const numerator = Math.floor(denominator * decimal)
    const fraction = reduce(numerator, denominator)
    return properToMixed(fraction)
  }

  const toMixed = () => {
    return getInches(decimal, precision)
  }

  const toHTMLString = () => {
    const val = getInches(decimal, precision)
    return val.r ? `${val.q} <sup style="margin-left: 3px; top: -0.4em; font-size: 70%; line-height: 0; position: relative; vertical-align: baseline;">${val.r}</sup>/<sub style="bottom: -0.1em; font-size: 70%; line-height: 0; position: relative; vertical-align: baseline;">${val.d}</sub>″` : `${val.q}″`
  }

  const toRegularString = () => {
    const val = getInches(decimal, precision)
    return val.r ? `${val.q} ${val.r}/${val.d}″` : `${val.q}″`
  }

  return {
    toMixed,
    toRegularString,
    toHTMLString
  }
}
