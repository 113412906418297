<template>
  <section class="product-detail">
    <div class="banner" v-if="information?.acf?.show_banner">
      <div class="text">
        <div
          class="title"
          v-if="information"
          v-html="
            information.acf.category_banner_title
              ? information.acf.category_banner_title
              : '<p>Need it fast?</p>'
          "
        />
        <div
          v-if="information"
          v-html="
            information.acf.category_banner_text
              ? information.acf.category_banner_text
              : '<p>Quick Ship is available for some standard size and finish products.</p>'
          "
        />
      </div>

      <div class="button-container">
        <Button
          color="mint-30"
          lineHover="bg-green"
          circleColor="circle-white"
          :url="
            information.acf.category_banner_cta
              ? information.acf.category_banner_cta.url
              : '/'
          "
          :text="
            information.acf.category_banner_cta
              ? information.acf.category_banner_cta.title
              : 'Learn More'
          "
          v-if="information"
        />
      </div>
    </div>

    <div class="information">
      <div class="top">
        <div class="left">
          <h2 v-html="information.name" v-if="information" />

          <div class="arrow">
            <img src="@/assets/img/orange_arrow.png" alt="Orange Arrow" />
          </div>
        </div>

        <div class="right">
          <div class="breadcrumb">
            <ul v-if="products">
              <li
                v-for="(item, index) in products.acf.category_breadcrumb"
                :key="index"
              >
                <a
                  :href="item.page_option.url"
                  :target="
                    item.page_option.target ? item.page_option.target : '_self'
                  "
                  :title="item.page_option.title"
                  >{{ item.page_option.title }}</a
                >
              </li>
              <li v-if="information">
                <a href="/" :title="information.name" target="_self">{{
                  information.name
                }}</a>
              </li>
            </ul>
            <ul v-else>
              <li v-if="information">
                <a href="/" :title="information.name" target="_self">{{
                  information.name
                }}</a>
              </li>
            </ul>
          </div>

          <div class="arrow">
            <img src="@/assets/img/orange_arrow.png" alt="Orange Arrow" />
          </div>
        </div>
      </div>

      <div class="bottom">
        <div class="products-container">
          <Transition name="slide-fade" mode="out-in">
            <template v-if="!products">
              <div class="dots">
                <div class="dot-flashing"></div>
              </div>
            </template>
          </Transition>
          <template v-if="products">
            <template v-if="products.products.length > 0">
              <div class="products">
                <div class="boxes">
                  <div
                    class="box"
                    v-for="(info, index) in products.products"
                    :key="index"
                  >
                    <a
                      :href="setUrl(info.slug, slug)"
                      :title="info.name"
                      target="_self"
                    >
                      <img :src="info.product_image.url" alt="Image" />
                      <h4 v-html="info.title" />
                    </a>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="products.sub_categories">
              <div
                class="products"
                v-for="(content, idx) in products.sub_categories"
                :key="idx"
              >
                <MenuAnchor :data="{menu_anchor: content.name.replaceAll('/^[a-zA-Z]+$/g', '').replaceAll(' ', '-').replaceAll('_', '-').toLowerCase()}" />
                <h3 v-html="content.name" />
                <div class="boxes">
                  <div
                    class="box"
                    v-for="(info, index) in content.products"
                    :key="index"
                  >
                    <a
                      :href="setUrl(info.slug, slug, content.name.replaceAll('/^[a-zA-Z]+$/g', '').replaceAll(' ', '-').replaceAll('_', '-').toLowerCase())"
                      :title="info.name"
                      target="_self"
                    >
                      <img :src="info.product_image.url" alt="Image" />
                      <h4 v-html="info.title" />
                    </a>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import Button from '@/components/utility/Button.vue'
import MenuAnchor from '@/components/utility/MenuAnchor.vue'
import { ref, onBeforeMount, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'

const route = useRoute()
const props = defineProps(['slug'])
const store = useStore()
const products = ref(null)

const request = {
  type: 'product-type',
  slug: props.slug,
  showLoading: true
}

const information = computed(() => {
  return products.value
})

function setUrl (link, slug, subcategory) {
  let url
  if (subcategory) {
    url = `/products/${link}?category=${slug}`.replace('/?', '?') + '&subcategory=' + subcategory
  } else {
    url = `/products/${link}?category=${slug}`.replace('/?', '?')
  }
  return url
}

function scrollToSection () {
  if (route.hash) {
    // console.log(route.hash)
    // const element = document.querySelector(`${route.hash}`)
    // instance.scrollTo(element, true, 'top 100px')
  }
}

onBeforeMount(async () => {
  await store.dispatch('getSingleBySlug', request)
  products.value = store.getters.singleBySlug(request)
  setTimeout(() => {
    scrollToSection()
  }, 1000)
})

</script>

<style lang="scss" scoped>
.product-detail {
  @apply min-h-[100vh] relative py-[130px] px-[10px] md:px-5 lg:py-[160px] bg-ore_mint_30;

  .banner {
    @apply relative bg-ore_green max-w-[1390px] mx-auto rounded-[27px] p-8 mb-8 md:flex md:justify-between md:items-center md:gap-[50px] lg:gap-[220px] lg:pl-12 lg:pr-[90px] lg:py-8 xl:justify-between xl:mb-[160px];

    :deep(.text) {
      @apply relative mb-4 max-w-[315px] ml-0 md:flex md:justify-start md:max-w-[490px] md:items-center md:m-0 lg:max-w-[590px];

      p {
        @apply text-ore_mint_30 mb-2 text-[20px] leading-none md:px-[30px] md:border-l-ore_orange md:border-l-[1px] md:leading-[24px];
      }

      .title {
        @apply md:flex-[1_0_auto] md:pl-0 md:max-w-[220px];

        p {
          @apply leading-none md:text-[30px] md:border-none;
        }
      }
    }

    .button-container {
      @apply md:flex-[1_0_auto] md:flex md:justify-end;

      .button {
        @apply md:m-0 md:p-0 md:inline-block;
      }
    }
  }

  .information {
    @apply relative;

    .top {
      @apply wide-container flex flex-col md:flex-row md:flex-wrap md:mb-[75px] xl:px-14 2xl:px-10;

      .left {
        @apply order-2 xl:order-1 xl:w-1/2;

        h2 {
          @apply text-[60px] leading-[70px] md:text-[120px] md:leading-none;
        }

        .arrow {
          @apply mt-[35px] mb-2 md:hidden;

          img {
            @apply mr-0 ml-auto;
          }
        }
      }

      .right {
        @apply order-1 mb-8 md:w-full md:flex-[1_0_auto] md:flex md:justify-between md:items-center md:mb-12 md:mt-10 xl:order-2 xl:w-[50%] xl:gap-14 xl:justify-end 2xl:gap-[140px];

        .breadcrumb {
          ul {
            @apply flex justify-center items-center flex-col mobile:flex-row mobile:flex-wrap;

            li {
              @apply p-6 flex-[1_0_auto] text-center w-full mobile:flex-grow-0 mobile:w-auto border-b-ore_orange border-b-[1px] mobile:border-r-ore_orange mobile:border-r-[1px] mobile:border-b-0;

              a {
                @apply hover:text-ore_orange transition-all text-[16px] leading-[24px] md:text-[24px] md:leading-none;
              }

              &:last-child {
                @apply border-none pointer-events-none;
              }

              &:first-child {
                @apply md:pl-0;
              }
            }
          }
        }

        .arrow {
          @apply hidden md:block;
        }
      }
    }

    .bottom {
      @apply relative xl:max-w-[1600px] xl:mx-auto xl:px-14 2xl:px-10;

      .products-container {
        .products {
          @apply relative mb-24;

          h3 {
            @apply text-[30px] leading-[40px] text-ore_orange mb-[30px] ml-7 md:ml-14 xl:ml-0 lg:mb-[40px];
          }

          .boxes {
            @apply flex flex-row gap-[10px] flex-wrap md:gap-[9px] xl:gap-[20px];

            .box {
              @apply relative flex rounded-[27px] min-h-[200px] overflow-hidden w-[calc(50%_-_5px)] md:w-[calc(33%_-_4px)] xl:w-[calc(25%_-_15px)];

              &:before{
                @apply content-[''] absolute rounded-[27px] bg-white top-0 right-0 left-0 bottom-0 transition-all;
              }

              a {
                @apply flex flex-col justify-between p-5 z-1 md:min-h-[200px] md:px-6 lg:px-8 lg:py-11 lg:min-h-[250px];

                h4 {
                  @apply pt-5 transition-all text-[16px] leading-none md:text-[20px] lg:text-[22px];
                }

                img{
                  @apply transition-all;
                }
              }

              &:hover{

                &:before{
                  @apply scale-95;
                }

                a {
                  img {
                    @apply scale-110;
                  }

                  h4 {
                    @apply transform translate-x-1 -translate-y-1 lg:translate-x-3 lg:-translate-y-3;
                  }
                }
              }
            }
          }
        }
      }
    }

    .dots {
      @apply relative flex justify-center items-center my-32;

      .dot-flashing {
        @apply relative w-[50px] h-[50px] rounded-full bg-ore_orange;
        animation: dotFlashing 1s infinite linear alternate;
        animation-delay: 1.5s;

        &:before,
        &:after {
          @apply content-[''] inline-block absolute top-0 w-[50px] h-[50px] rounded-full bg-ore_orange;
        }

        &:before {
          @apply -left-[75px];
          animation: dotFlashing 1s infinite alternate;
          animation-delay: 1s;
        }

        &:after {
          @apply left-[75px];
          animation: dotFlashing 1s infinite alternate;
          animation-delay: 2s;
        }
      }

      @keyframes dotFlashing {
        0% {
          @apply bg-ore_green;
        }
        50%,
        100% {
          @apply bg-ore_orange;
        }
      }
    }
  }

  .slide-fade-enter-active {
    transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
}
</style>
