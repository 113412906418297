<template>
  <a :href="cartPage" class="quotesWidget">
    Quotes
    <span class="circle" @mouseover="circleAni.restart()">
      {{ totalProducts + totalFinishes }}
    </span>
  </a>
</template>

<script setup>
import { cartState } from '../../Cart/Store.js'
import { finishState } from '@/components/Cart/finishStore.js'
import { useStore } from 'vuex'
import { computed, watch, ref, onMounted } from 'vue'
import gsap from 'gsap'
const store = useStore()
const cartPage = computed(() => {
  return store.state.acf_options.global_quote_page.replace(store._state.data.site.url, '')
})

const { totalProducts, cart } = cartState()
const { finishes, totalFinishes } = finishState()
const circleAni = ref()

onMounted(() => {
  circleAni.value = gsap.to('.circle', {
    scale: 1.2,
    yoyo: true,
    repeat: 1,
    duration: 0.5
  })
})

watch([cart, finishes], () => {
  if (circleAni.value) circleAni.value.restart()
})

</script>

<style lang="scss" scoped>
.quotesWidget {
  @apply text-[16px] transition-all duration-300 flex flex-row flex-nowrap justify-between items-center mr-2 min-h-[44px] bg-ore_green_dark/70 backdrop-blur rounded-full text-white pr-1 pl-3 px-1 mobile:pl-7 md:min-h-[54px] md:pr-2 md:mr-8 xl:text-[20px] xl:mr-0;
  span{
    @apply shadow-[1px_0px_9px_0px_rgba(255,76,0,0.75)] text-[20px] flex flex-row flex-nowrap justify-center items-center ml-2 w-9 h-9 bg-ore_orange rounded-full text-white mobile:ml-4 xl:ml-8;
  }

  &.active {
    @apply pointer-events-none -translate-y-[160px] delay-300 duration-700 ease-in-out;
  }
}
</style>
