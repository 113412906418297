<template>
  <div class="wrapper">
    <main>
      <article v-if="post" class="post">
        <CPTHero
        type="Post"
        :title="post.title.rendered"
        :backCTA="backCTA"
        />
        <div class="post-hero">
            <div v-if="post.featured_image" class="featured-image">
                <img :src="post.featured_image.large" :alt="post.featured_image.alt"
                data-scroll
                data-scroll-direction="vertical"
                data-speed="0.8"
                />
                <div v-if="post.acf.hero_tag" class="featured-image-tag" v-html="post.acf.hero_tag" ></div>
            </div>
        </div>
        <div class="post-content" v-html="post.content.rendered" />
        <div class="post-big-paragraph" v-html="post.acf.middle_section_text"/>
        <div class="post-gallery">
            <DoubleImagesSectionGroup
            :sections="post.acf.sections"
            :gallery = "post.acf['acf-single-post-gallery']"
            />
        </div>
      </article>
    </main>
  </div>
</template>

<script setup>
import { computed, onBeforeMount, onMounted } from 'vue'
import { useStore } from 'vuex'
import DoubleImagesSectionGroup from '@/components/utility/DoubleImagesSectionGroup.vue'
import CPTHero from '@/components/utility/CPTHero.vue'
import GoBack from '@/components/utility/GoBack.vue'

import { ScrollTrigger } from 'gsap/ScrollTrigger'

onMounted(() => {
  // ScrollTrigger.refresh()
})
const props = defineProps(['slug', 'newsSlug'])
const store = useStore()

const backCTA = {
  target: '',
  title: 'Back'
  // url: '/'
}

const request = {
  type: 'posts',
  slug: props.slug,
  showLoading: true
}

const post = computed(() => {
  return store.getters.singleBySlug(request)
})

const getPost = async () => {
  store.dispatch('getSingleBySlug', request).then(() => {
    store.dispatch('updateDocTitle', {
      parts: [
        document.title = post.value.title.rendered,
        store.state.site.name
      ]
    })
  })
}

onBeforeMount(async () => {
  getPost()
})
</script>

<style lang="scss" scoped>
.wrapper {
    main {
        .post {
            @apply flex flex-col items-center;
            section {
                @apply w-full flex justify-center;
            }
            &-hero {
                @apply relative w-full px-[14px] mb-10 flex flex-col items-center gap-20
                lg:mb-32
                xl:pt-[50px];
                .featured-image {
                    @apply relative w-full max-h-[768px] overflow-hidden rounded-[27px];
                    img {
                     @apply rounded-[13px] min-h-[465px] md:min-h-0 w-full max-h-[768px] object-cover
                     lg:rounded-[27px]
                    }
                    &-tag{
                        @apply w-[calc(100%-14px-14px)] max-w-[400px] left-[14px] px-5 py-3 rounded-[27px] absolute bottom-[14px] bg-ore_green
                        ;
                        &:deep(p) {
                            @apply font-[300] text-xl leading-[24px] text-white

                            ;
                        }
                    }
                }
            }
            &-content {
                @apply main-container flex flex-col items-center gap-16 lg:gap-[162px];
                &:deep(p) {
                    @apply lg:text-3xl lg:leading-9 xl:w-[1080px];
                }
            }
            &-big-paragraph {
                @apply main-container mt-16 mb-12
                lg:mt-[120px] lg:mb-[160px]
                xl:w-[1080px];
                &:deep(p){
                    @apply text-4xl leading-none
                    lg:text-[70px]
                }
                &:deep(p+p){
                    @apply mt-[50px]
                    lg:mt-[100px]
                }
            }
            &-gallery {
                .solution-content{
                    @apply px-5 mt-10 mb-[70px] flex flex-col
                    md:mt-[100px] md:max-w-full
                    lg:mt-[-300px] lg:max-w-[50%] lg:items-center
                    xl:mt-[-450px] xl:mb-[300px];
                    &-title {
                        @apply flex flex-col gap-[25px]
                        md:max-w-[400px]
                        lg:max-w-[320px];
                        &:deep(p) {
                            @apply text-2xl leading-none
                        }
                    }
                }
            }
        }
    }

}
</style>
