<template>
  <section id="hero" class="portfolio-hero">
    <div class="background">
      <img src="~@/assets/img/home-hero-background.svg" alt="Background" />
    </div>

    <div class="top-content">
      <div class="container">
        <div class="text">
          <div class="subheader" v-html="'Portfolio'" />
          <div class="title-wrapper">
            <div
              v-if="validateCarousel()"
              v-html="
                props.data.portfolio_hero_carousel[prev({ returnRef: true })]
                  .title
              "
              class="title prev-title"
            />
            <div
              v-html="props.data.portfolio_hero_carousel[current].title"
              class="title"
            />
            <div
              v-if="validateCarousel()"
              v-html="
                props.data.portfolio_hero_carousel[next({ returnRef: true })]
                  .title
              "
              class="title next-title"
            />
          </div>
          <Button
            :url="props.data.portfolio_hero_carousel[current].url"
            text="Learn More"
            color="dark-green"
            lineHover="bg-mint-30"
            circleColor="circle-orange"
          />

          <a v-if="props.data.portfolio_hero_carousel[current].url" :href="props.data.portfolio_hero_carousel[current].url" target="_self" class="desktop-arrow">
            <div class="button-icon">
              <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_541_966)">
                  <path
                    d="M7.82325 0H9.46067C9.56842 0 9.65497 0.0387222 9.71856 0.0984188L14.9346 4.86286C15.0212 4.94192 15.0212 5.0597 14.9346 5.13875L9.74152 9.90319C9.67617 9.96289 9.59138 10.0016 9.48363 10.0016H7.82325C7.62895 10.0016 7.58655 9.88383 7.7155 9.76605L12.0466 5.8293H0.215497C0.0865521 5.8293 0 5.75024 0 5.63246V4.43207C0 4.31429 0.0865521 4.23524 0.215497 4.23524H12.0908L7.7155 0.23556C7.58655 0.11778 7.62895 0 7.82325 0Z"
                    fill="#FF4C00" />
                </g>
                <defs>
                  <clipPath id="clip0_541_966">
                    <rect width="15" height="10" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
        </a>
        </div>
        <div v-if="validateCarousel()" class="carousel-wrapper">
          <div class="carousel-selector">
            <div class="carousel-selector-left">
              <div class="current-num-wrapper">
                <span class="carousel-selector-left-nums left-num curr-prev"
                  >0{{ prev(true) + 1 }}</span
                >
                <span class="carousel-selector-left-nums left-num curr"
                  >0{{ current + 1 }}</span
                >
                <span class="carousel-selector-left-nums left-num curr-next"
                  >0{{ next(true) + 1 }}</span
                >
              </div>
              <span class="slash">/</span>
              <span class="carousel-selector-left-nums"
                >0{{ totalImages }}</span
              >
            </div>
            <div class="carousel-selector-right">
              <button
                class="left-arrow"
                :class="{ 'in-anim': animating }"
                @click="prevWAnim()"
              >
                ←
              </button>
              <button
                class="right-arrow"
                :class="{ 'in-anim': animating }"
                @click="nextWAnim()"
              >
                →
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <WideImage
      :data="{
        wide_module_image_url: props.data.portfolio_hero_carousel[current].img,
        animate: props.data.portfolio_hero_carousel.length > 1,
        img: {
          prev: props.data.portfolio_hero_carousel[prev(true)].img,
          curr: props.data.portfolio_hero_carousel[current].img,
          next: props.data.portfolio_hero_carousel[next(true)].img,
        },
      }"
    />
    <div class="bottom-content">
      <div class="container small">
        <div class="big" v-html="props.data.portfolio_hero_text" />
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref } from 'vue'
import Button from '@/components/utility/Button.vue'
import WideImage from '@/components/modules/WideImage.vue'
import gsap from 'gsap'

// --- VARIABLES AND PROPS ---

const props = defineProps(['data'])
const current = ref(0)
const animating = ref(false)
const totalImages = ref(props.data.portfolio_hero_carousel.length)

// --- NEXT AND PREVIOUS INDEX ---

// This functions update the current index to switch to the next and/or previous index
// Accepts optional 'returnRef' param, which allows to return the index
// while not updating the current index
const next = (returnRef) => {
  let ref
  switch (current.value) {
    case totalImages.value - 1:
      if (returnRef) {
        ref = 0
        return ref
      }
      current.value = 0
      break
    default:
      if (returnRef) {
        ref = current.value + 1
        return ref
      }
      current.value++
      break
  }
}
const prev = (returnRef) => {
  let ref
  switch (current.value) {
    case 0:
      if (returnRef) {
        ref = totalImages.value - 1
        return ref
      }
      current.value = totalImages.value - 1
      break
    default:
      if (returnRef) {
        ref = current.value - 1
        return ref
      }
      current.value--
      break
  }
}

// --- FUNCTIONS W ANIMATION ---

// This are ran when clicking on left/right arrows
const nextWAnim = () => {
  next()
  animGroup(true)
}
const prevWAnim = () => {
  prev()
  animGroup(false)
}

// --- ANIMATIONS ---
const animationTimeline = async (textAnim, numAnim, imgAnim) => {
  const tl = gsap.timeline({
    defaults: {
      duration: 0.5,
      ease: 'power4.out'
    }
  })
  animating.value = true
  await tl
    .from('.title', {
      y: textAnim.distance
    })
    .fromTo(
      '.prev-title',
      {
        y: textAnim.distance
      },
      {
        y: textAnim.prevOffset
      },
      '<'
    )
    .fromTo(
      '.next-title',
      {
        y: textAnim.distance
      },
      {
        y: textAnim.nextOffset
      },
      '<'
    )
    .from(
      '.left-num',
      {
        y: numAnim.distance
      },
      '<'
    )
    .fromTo(
      '.curr-prev',
      {
        y: numAnim.distance
      },
      {
        y: numAnim.prevOffset
      },
      '<'
    )
    .from(
      '.image-wrapper',
      {
        x: imgAnim.distance,
        duration: 1
      },
      '<'
    )
  animating.value = false
}

const animGroup = (isNext) => {
  switch (isNext) {
    case true:
      animationTimeline(
        { distance: 100, prevOffset: -10, nextOffset: 5 },
        { distance: 100, prevOffset: -5 },
        { distance: '100%' }
      )
      break
    case false:
      animationTimeline(
        { distance: -100, prevOffset: -10 },
        { distance: -100 },
        { distance: '-100%' }
      )
      break
  }
}

// --- OTHER FUNCTIONS ---

// disables render of carousel selector if there's
// one element on the carousel array
const validateCarousel = () => {
  return props.data.portfolio_hero_carousel.length > 1
}
</script>

<style lang="scss">
.portfolio-hero {
  @apply relative lg:pt-[180px];

  .in-anim {
    @apply pointer-events-none;
  }

  @apply relative overflow-hidden overflow-y-visible min-h-[2050px];

  .background {
    @apply absolute top-[390px] right-0 w-[100vw] max-w-[750px]
    md:top-[400px] md:right-[-100px]
    lg:top-0 lg:right-0 lg:max-w-[990px] lg:w-[calc(100%_-_70px)] lg:mr-0 lg:ml-auto lg:bottom-[240px];

    img {
      @apply object-contain object-right w-[100%];
    }
  }

  .container {
    @apply wide-container flex flex-col justify-center items-start;

    &.small {
      @apply md:px-16 main-container xl:pr-32 xl:pl-44;
    }
  }

  .top-content {
    @apply relative z-2 lg:py-0 lg:absolute lg:left-[50px] lg:right-0 lg:top-[220px] lg:max-w-[900px];

   .container {
      @apply p-0 lg:min-w-full lg:mx-0 lg:mb-16 lg:flex-row lg:items-center lg:justify-between
      lg:bg-white lg:rounded-[27px] lg:px-4 lg:py-2;

      .text {
        @apply relative main-container pt-[130px] pb-9 md:px-[121px] w-full
        md:mb-9 md:pt-[190px]
        lg:w-[370px] lg:py-0 lg:pr-16 lg:pl-8 lg:m-0 lg:flex lg:flex-col lg:justify-center 2xl:pr-28;

        .subheader {
          @apply lg:text-[14px] lg:leading-[24px] lg:max-w-[250px];
        }

        a{
          &.button {
            @apply lg:hidden;
          }

          &.desktop-arrow{
            @apply hidden lg:block absolute right-[20px] top-[calc(50%_-_20px)];

            .button-icon {
             @apply transition ease-in-out duration-200 relative pr-[30px] min-h-[40px] inline-flex items-center;

              svg {
                @apply absolute right-0 top-[calc(50%_-_2px)] z-2;

                path {
                  @apply fill-white;
                  @apply transition ease-in-out duration-200;
                }
              }

              &:after {
                @apply content-[''] absolute w-[40px] h-[40px] top-[calc(50%_-_17px)] -right-[13px] rounded-full border-2 bg-ore_orange border-none transition-all;
              }
            }

            &:hover{
               .button-icon {
                  &:after{
                    @apply opacity-75;
                  }
               }
            }
          }
        }

        .title-wrapper {
          @apply overflow-hidden relative min-h-[95px] md:min-h-[110px] mt-4 mb-[0px]
            md:mb-11
            lg:min-h-[50px] lg:mb-0;
          .title {
            @apply text-[36px] leading-none mb-2 md:text-[50px] lg:text-[24px] lg:max-w-[250px];

            &.prev-title {
              @apply absolute top-[-110px];
            }
            &.next-title {
              @apply absolute bottom-[-100px];
            }
          }
        }
      }

      .carousel-wrapper {
        @apply px-[10px] w-full
        md:w-[472px] md:self-end md:mb-[10px]
        lg:self-auto lg:w-[calc(100%_-_370px)] lg:m-0 lg:py-0 lg:pl-8 lg:pr-0;
        .carousel-selector {
          @apply overflow-hidden bg-white w-full rounded-3xl py-5 px-10 flex gap-10 justify-between items-center
            md:gap-16;
          &-left {
            @apply text-2xl flex justify-start text-ore_orange
                    md:w-1/2 md:justify-center md:text-[40px];
            .current-num-wrapper {
              @apply relative min-w-[25px] md:min-w-[50px];
              .curr-prev {
                @apply absolute top-[-75px] md:top-[-70px];
              }
              .curr {
                @apply absolute;
              }
              .curr-next {
                @apply absolute left-0 top-[70px];
              }
            }
            &-nums {
              @apply text-center min-w-[25px] md:min-w-[50px];
            }
            .slash {
              @apply text-ore_green_dark mx-5;
            }
          }
          &-right {
            @apply flex w-1/2 justify-between text-[45px];
            .left-arrow {
              @apply min-w-[50px]  md:min-w-0 md:flex-1 md:mr-8;
            }
            .right-arrow {
              @apply min-w-[50px] md:min-w-0 md:flex-1 md:ml-8;
            }
          }
        }
      }
    }
  }

  .bottom-content {
    @apply relative z-2 min-h-[540px] pb-[40px] mt-14
    md:mt-[110px]
    lg:mt-[156px];

    .big {
      p {
        @apply text-[40px] sm:text-[60px] lg:text-[70px] leading-none font-normal;
      }
    }
  }
}
</style>
