<template>
  <section class="list-with-animation" :style="backgroundAnimationColor">
    <div class="list-with-animation_wrapper">
      <div
        class="list-with-animation__animation-wrapper"
        id="listWithAnimationAnimationWrapper"
      >
        <div
          id="listWithAnimationAnimation"
          ref="listWithAnimationAnimation"
          class="list-with-animation-animation"
        >
          <img v-if="props.data.list_with_animation_2_icon_color === 'orange'" class="animation-image" id="animationImage" src="@/assets/img/five-orange-circles.svg" alt="Five Circles">
          <img v-if="props.data.list_with_animation_2_icon_color === 'ore_mint_30'" class="animation-image" id="animationImage" src="@/assets/img/five-ore_mint_30-circles.svg" alt="Five Circles">
        </div>
      </div>
      <div class="list-with-animation__items">
        <div
          class="list-with-animation__item"
          :class="props.data.list_with_animation_2_icon_color"
          v-for="(item, index) in props.data.list_with_animation_2_list"
          :id="`list-with-animation__item-${index}`"
          :key="index"
        >
          <h3 :class="`icon-circle-${circles[index]}`">{{item.list_with_animation_2_title}}</h3>
          <div class="content">
            <div v-if="item.list_with_animation_2_content_text" class="content__text" v-html="item.list_with_animation_2_content_text"></div>
            <div v-if="item.list_with_animation_2_content_list" class="content__list" v-html="item.list_with_animation_2_content_list"></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { ref, computed, onMounted, onUpdated, onBeforeUnmount, onUnmounted } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const props = defineProps(['data'])
const circles = ref(['one', 'two', 'three', 'four', 'five'])
const screenWidth = ref(window.innerWidth)
const isDesktop = ref(false)
const backgroundAnimationColor = computed(() => {
  return {
    '--list-with-animation-2-bg': `rgba(${props.data.list_with_animation_2_bg.red}, ${props.data.list_with_animation_2_bg.green}, ${props.data.list_with_animation_2_bg.blue}, ${props.data.list_with_animation_2_bg.alpha})`
  }
})

onMounted(() => {
  // console.log('data', props)
  gsap.registerPlugin(ScrollTrigger)
  window.addEventListener('resize', onWindowResize)
  onWindowResize()
})

onUpdated(() => {
  // ScrollTrigger.refresh()
})

onBeforeUnmount(() => {
  clearScrollTrigger()
})

onUnmounted(() => {
  window.removeEventListener('resize', onWindowResize)
})

function assignDevice () {
  isDesktop.value = screenWidth.value >= 1024
}

function buildLottieAnimation () {
  const itemsQuantity = props.data.list_with_animation_2_list.length - 1

  gsap.to('.animation-image', {
    rotate: 180 * 3,
    scrollTrigger: {
      id: 'animation-mage',
      trigger: '.list-with-animation',
      start: () => {
        return 'start 0px'
      },
      end: () => {
        if (isDesktop.value) {
          return 'bottom center'
        }
        return calculatedEndTrigger('#listWithAnimationAnimation', `#list-with-animation__item-${itemsQuantity}`)
      },
      endTrigger: `#list-with-animation__item-${itemsQuantity}`,
      scrub: true,
      pin: '#listWithAnimationAnimation',
    }
  })
}

function calculatedEndTrigger (pin, endTrigger) {
  const chartHeight = document.querySelector(pin).offsetHeight
  const lastItemHeigth = document.querySelector(endTrigger).offsetHeight

  return `bottom ${lastItemHeigth + chartHeight + 144}px`
}

function onWindowResize () {
  screenWidth.value = window.innerWidth
  assignDevice()
  buildLottieAnimation()
}

function clearScrollTrigger () {
  /**
   * no error but media query events still fire after unmount
   */
  const animationImage = ScrollTrigger.getById('animation-mage')
  if (animationImage) animationImage.kill()
}

</script>

<style lang="scss" scoped>
.list-with-animation {
  @apply relative z-auto main-container p-0 w-full;
  &::before {
    background-color: var(--list-with-animation-2-bg);
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }

  .list-with-animation_wrapper {
    @apply flex flex-col lg:flex-row w-full md:pb-16 z-1 relative;

    .list-with-animation__animation-wrapper:deep() {
      background-color: var(--list-with-animation-2-bg);
      @apply flex justify-center mt-0 mb-6 md:mb-0 lg:mt-28 w-full lg:max-w-[288px] desktop:max-w-[320px] xl:max-w-[28rem] 2xl:max-w-[416px] z-[99];

      .list-with-animation-animation {
        background-color: var(--list-with-animation-2-bg);
        @apply w-full h-40 md:h-48 lg:h-56 py-8 lg:bg-transparent flex items-center justify-around 2xl:justify-center;

        .animation-image {
          @apply max-h-full max-w-full lg:max-w-xs;
        }
      }

      .pin-spacer {
        @apply h-0 p-0 mx-auto;
      }
    }

    .list-with-animation__items {
      @apply flex flex-col md:pt-0 md:pb-4 px-8 md:pl-16 md:pr-8 lg:pl-0 w-full;

      .list-with-animation__item{
        &:not(:last-child){
          @apply lg:border-b lg:border-solid lg:border-ore_green_dark border-opacity-[0.3];
        }
      }

      .orange {
        .content__list:deep() {
          li {
            &::before {
              @apply text-ore_orange;
            }
          }
        }

        .icon-circle-one {
          &::before{
            background-image: url('@/assets/img/circles/one.svg');
          }
        }

        .icon-circle-two {
          &::before{
            background-image: url('@/assets/img/circles/two.svg');
          }
        }

        .icon-circle-three {
          &::before{
            background-image: url('@/assets/img/circles/three.svg');
            @apply h-8;
          }
        }

        .icon-circle-four {
          &::before{
            background-image: url('@/assets/img/circles/four.svg');
            @apply h-10;
          }
        }

        .icon-circle-five {
          &::before{
            background-image: url('@/assets/img/circles/five.svg');
            @apply h-12;
          }
        }
      }

      .ore_mint_30 {
        .content__list:deep() {
          li {
            &::before {
              @apply text-ore_mint_30;
            }
          }
        }

        .icon-circle-one {
          &::before{
            background-image: url('@/assets/img/circles/ore_mint_30/one.svg');
          }
        }

        .icon-circle-two {
          &::before{
            background-image: url('@/assets/img/circles/ore_mint_30/two.svg');
          }
        }

        .icon-circle-three {
          &::before{
            background-image: url('@/assets/img/circles/ore_mint_30/three.svg');
            @apply h-8;
          }
        }

        .icon-circle-four {
          &::before{
            background-image: url('@/assets/img/circles/ore_mint_30/four.svg');
            @apply h-10;
          }
        }

        .icon-circle-five {
          &::before{
            background-image: url('@/assets/img/circles/ore_mint_30/five.svg');
            @apply h-12;
          }
        }
      }

      .list-with-animation__item:deep(){
        @apply lg:pt-12 lg:ml-16;

        h3 {
          @apply relative font-medium font-FHOscar text-2xl md:text-3xl leading-6 md:leading-8 text-ore_green_dark mb-10 md:mb-12;

          &::before{
            content: "";
            @apply absolute lg:inline-block right-0 lg:right-auto -top-1/4 md:top-1/4 lg:top-0 lg:-left-16 lg:mr-0 bg-no-repeat object-cover h-5 w-20 md:w-[47%];
          }
        }

        .content{
          @apply pb-24 md:pb-[104px] lg:pb-14 columns-1 lg:columns-2 md:gap-[36px] lg:gap-12 2xl:gap-x-11;

          &__text {
            @apply lg:break-after-column 2xl:pr-2;
            p {
              @apply font-FHOscar font-medium text-xl lg:text-2xl leading-6 text-ore_green_dark mb-4 md:mb-8 lg:mb-12 list-none;
            }
          }

          &__list {
            @apply columns-1 md:columns-2 lg:gap-12;
            ul {
              @apply lg:gap-x-[72px];
              li {
                @apply font-FHOscar font-medium text-xl leading-6 text-ore_green_dark mb-1 list-none;

                &::before {
                  @apply font-bold inline-block w-[5px] -ml-4 pr-4;
                  content: "\2022";
                }
              }
            }
          }
        }

      }
    }
  }
}
</style>
