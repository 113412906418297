<template>
  <section class="sticky-wrapper">
    <div id="sticky_button" class="fixed-button-container">
      <a class="fixed-button" :href="props.data.url">
        <span>{{ props.data.text }}</span>
        <div class="deco">
          <div class="deco-inner">
            <img
              src="@/assets/img/fixed_btn_deco.svg"
              alt="button Decoration"
            />
          </div>
        </div>
      </a>
    </div>
  </section>
</template>

<script setup>
import { onMounted, onBeforeUnmount } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
const props = defineProps(['data'])

const btnIntro = () => {
  ScrollTrigger.matchMedia({

    // large
    '(min-width: 768px)': function () {
      ScrollTrigger.create({
        id: 'pin',
        trigger: '#ore-wrapper',
        start: () => innerHeight / 9 + 'center',
        end: 'top bottom',
        endTrigger: 'footer',
        pin: '#sticky_button',
        pinSpacing: false,
        pinSpacer: false
      })
    },

    // small
    '(max-width: 767px)': function () {
      ScrollTrigger.create({
        id: 'pin-mobile',
        trigger: '#ore-wrapper',
        start: () => innerHeight / 100 + 'center',
        end: 'top bottom',
        endTrigger: 'footer',
        pin: '#sticky_button',
        pinSpacing: false,
        pinSpacer: false
      })
    },

    // all
    all: function () {
      gsap.to('.fixed-button', {
        y: 300,
        autoAlpha: 0,
        scrollTrigger: {
          id: 'fade-out',
          trigger: 'footer',
          start: 'top bottom',
          end: 'top+=10% center',
          scrub: true
        }
      })
    }

  })
}

const awaitFooter = () => {
  const element = document.getElementById('oreFooter')
  if (!element) {
    setTimeout(() => {
      awaitFooter()
    }, 100)
  } else {
    setTimeout(() => {
      // ScrollTrigger.refresh()
      btnIntro()
    }, 100)
  }
}

onBeforeUnmount(() => {
  const pin = ScrollTrigger.getById('pin')
  if (pin) pin.kill()

  const pinMobile = ScrollTrigger.getById('pin-mobile')
  if (pinMobile) pinMobile.kill()

  const fadeOut = ScrollTrigger.getById('fade-out')
  if (fadeOut) fadeOut.kill()
})

onMounted(() => {
  gsap.registerPlugin(ScrollTrigger)
  awaitFooter()
})
</script>

<style lang="scss" scoped>
.sticky-wrapper {
  @apply z-2;

  :deep(.pin-spacer) {
    @apply pointer-events-none overflow-hidden;
  }
  .pin-spacer + .pin-spacer {
    @apply pointer-events-auto;
  }
  .fixed-button-container {
    @apply absolute top-0 left-0  h-[95vh] w-[100vw] max-w-full z-9 flex justify-center items-end pointer-events-none
    md:h-[106vh]
    lg:h-[90vh]
    xl:h-[108vh];
    .fixed-button {
      @apply pointer-events-auto transition-all relative flex justify-center items-center h-[62px] w-full mx-[25px] max-w-[371px] text-white text-[24px];

      &:before{
        @apply content-[''] absolute top-0 bottom-0 left-0 right-0 bg-ore_green_dark/70 backdrop-blur-xl rounded-full z-0 transition-all duration-500;
            box-shadow: 0px 4px 20px rgba(34, 73, 70, 0.2),
        0px 2px 4px rgba(0, 22, 16, 0.2);
      }
      span {
        @apply text-center max-w-[60%] z-1;
      }
      .deco {
        @apply absolute right-[10px] top-0 flex justify-end items-center h-full w-[50px] py-[10px];
        &-inner {
          @apply flex justify-center items-center w-[41px] h-[41px] bg-ore_orange rounded-full shadow-[0px_0px_10px_rgba(255,76,0,0.45)];

          img {
            @apply w-[17.5px] h-[20.5px];
          }
        }
      }
    }

    &:hover {
      .fixed-button {
        &:before{
          @apply scale-105;
        }
      }
    }
  }
}
</style>
