<template>
  <section class="text-cols-w-deco-variant">
    <div class="container">
        <div class="deco-cols-variant">
        <div class="deco-cols-variant-decoration">
          <img
            class="deco-cols-variant-decoration-image"
            src="@/assets/img/three_circles_decoration.svg"
          />
        </div>
        <div class="deco-cols-variant-content">
            <div class="col left-col">
                <h3 class="col-title">{{data.left_col.title}}</h3>
                <div class="col-text" v-html="data.left_col.text"></div>
                <Button
                    v-if="data.left_col.cta && data.is_button_white"
                    :url="data.left_col.cta.url"
                    :text="data.left_col.cta.title"
                    color="white"
                    lineHover="bg-orange"
                    circleColor="circle-white"
                />
                <Button
                    v-else-if="data.left_col.cta && !data.is_button_white"
                    :url="data.left_col.cta.url"
                    :text="data.left_col.cta.title"
                    color="dark-green"
                    lineHover="bg-orange"
                    circleColor="circle-white"
                />
            </div>
            <div class="col right-col">
                <h3 class="col-title">{{data.right_col.title}}</h3>
                <div class="col-text" v-html="data.right_col.text"></div>
                <Button
                    v-if="data.right_col.cta && data.is_button_white"
                    :url="data.right_col.cta.url"
                    :text="data.right_col.cta.title"
                    color="white"
                    lineHover="bg-orange"
                    circleColor="circle-white"
                />
                <Button
                    v-else-if="data.right_col.cta && !data.is_button_white"
                    :url="data.right_col.cta.url"
                    :text="data.right_col.cta.title"
                    color="dark-green"
                    lineHover="bg-orange"
                    circleColor="circle-white"
                />
            </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, onBeforeUnmount } from 'vue'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Button from '@/components/utility/Button.vue'

defineProps(['data'])

gsap.registerPlugin(ScrollTrigger)
const animateDecoration = () => {
  gsap.to('.deco-cols-variant-decoration-image', {
    rotate: 180 * 3,
    scrollTrigger: {
      id: 'deco-variant',
      trigger: '.text-cols-w-deco-variant',

      start: 'top 75%',
      end: 'bottom top',
      scrub: 0.5
    }
  })
}

onMounted(() => {
  animateDecoration()
})

onBeforeUnmount(() => {
  const decoVariant = ScrollTrigger.getById('deco-variant')
  if (decoVariant) decoVariant.kill()
})

</script>

<style lang="scss" scoped>
.text-cols-w-deco-variant {
  @apply main-container px-0;
  .container {
    @apply z-1 relative w-full max-w-screen-xl
    mx-auto pl-[29px] pr-[34px] py-[50px]
    flex flex-col items-center
    md:px-[40px] md:pt-[25px] md:pb-[120px]
     xl:pt-[135px] xl:pb-[200px] xl:px-28;
    .deco-cols-variant {
      @apply mb-12 w-full relative flex flex-col items-center gap-[100px] text-[20px] leading-none
      md:px-0 md:flex-row md:gap-[60px]
      lg:px-[14px]
      lg:justify-between
      xl:justify-start xl:text-[30px] xl:gap-[360px];
      &-decoration {
        @apply absolute right-[40px] top-0
        md:left-0 md:top-[80px] md:-mt-9
        xl:relative xl:mt-0  xl:self-start xl:top-0
        ;
        img {
          @apply min-w-[59px] min-h-[55px];
        }
      }
      &-content {
        @apply flex flex-col gap-[90px] pt-[120px]
        md:pt-[150px] md:flex-row md:gap-[55px] md:w-full
        xl:justify-start xl:pt-0 xl:w-[calc(100%_-_360px)]
        ;

        .col {

            &-title{
                @apply text-[24px] leading-none
                md:min-h-[30px] md:text-[30px];
            }
            &-text {
            @apply flex flex-col
            gap-[25px]
            my-[25px]
            md:flex-col md:w-[340px] md:min-h-[140px] md:my-[30px] md:gap-[25px]
            lg:w-[40vw] lg:gap-[30px] lg:justify-between
            xl:w-[340px] xl:justify-start;
                :deep(p) {
                    @apply w-full;
                }
            }

        }
      }
    }
  }
}
</style>
