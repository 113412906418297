<template>
  <div class="image-module-component">
    <img :src="data.module_image_url.url" :alt="data.module_image_url.alt" />
  </div>
</template>

<script setup>

import { useStore } from 'vuex'
import { ref, onBeforeMount } from 'vue'
const store = useStore()
defineProps(['data'])
const products = ref(null)

// onBeforeMount(async () => {
//   await store.dispatch('getItems', {
//     type: 'quote',
//     params
//   })
//   products.value = store.getters.requestedItems({
//     type: 'quote',
//     params: {}
//   })
//   console.log(products.value)
// })

// onBeforeMount(async () => {
//   await store.dispatch('removeFromQuote', {
//     type: 'quote',
//     params: {
//       product_id: 1985,
//       quote_id: '58f6fda65aa9f7e640a135898b3f6e85'
//     }
//   })
//   products.value = store.getters.requestedItems({
//     type: 'quote',
//     params: {}
//   })
//   console.log(products.value)
// })

// onBeforeMount(async () => {
//   await store.dispatch('addToQuote', {
//     type: 'quote',
//     params: {
//       product_id: 4806,
//       variation_id: 4807,
//       attribute_size: '1031 - L 28.5" W 28.5" H 19.875"',
//       yith_wapo_product_img: 'http://ore.local/wp-content/uploads/2020/03/1030_single_hammeredSilver.jpg',
//       yith_wapo_is_single: true,
//       'yith_wapo[][131-0]': 'cacota',
//       'yith_wapo[][89-2]': 'Lip LED Lighting',
//       'yith_wapo[][89-3]': 'la mera mera',
//       'yith_wapo[][240-3]': 'la poyadura',
//       quantity: 1
//     }
//   })
//   products.value = store.getters.requestedItems({
//     type: 'quote',
//     params: {}
//   })
//   console.log(products.value)
// })

// onBeforeMount(async () => {
//   await store.dispatch('addToQuote', {
//     type: 'quote',
//     params: {
//       product_id: 1985,
//       variation_id: 2009,
//       attribute_size: '1012 L 36" W 36" H 27"',
//       yith_wapo_product_img: 'http://ore.local/wp-content/uploads/2018/04/1012_Trapezoid_cgray-1.jpg',
//       yith_wapo_is_single: true,
//       'yith_wapo[][131-0]': '',
//       'yith_wapo[][89-2]': 'Lip LED Lighting',
//       quantity: 1
//     }
//   })
//   products.value = store.getters.requestedItems({
//     type: 'quote',
//     params: {}
//   })
//   console.log(products.value)
// })

// onBeforeMount(async () => {
//   await store.dispatch('getItems', {
//     type: 'product-type',
//     params: {}
//   })
//   products.value = store.getters.requestedItems({
//     type: 'product-type',
//     params: {}
//   })
//   console.log(products.value)
// })

// onBeforeMount(async () => {
//   await store.dispatch('getSingleById', {
//     type: 'products',
//     id: 4292
//   })
//   products.value = store.getters.singleById({
//     type: 'products',
//     id: 4292
//   })
//   console.log(products.value)
// })

</script>

<style lang='scss' scoped>
.image-module-component {
  @apply w-full max-w-screen-xl mx-auto py-10 lg:py-20 px-6 lg:px-0;
  img {
    @apply min-h-[300px] max-h-[300px] lg:min-h-[600px] lg:max-h-[600px] object-cover w-full;
  }
}
</style>
