<template>
  <section class="text-container">
    <div class="main-container">
      <div :class="`text ${props.data.padding_size ? 'large' : ''}`" v-html="props.data.text_container_content" />
      <Button
      v-if="props.data.text_container_cta" :url="props.data.text_container_cta.url" :text="props.data.text_container_cta.title"
        color="dark-green"
        lineHover="bg-mint-30"
        circleColor="circle-orange" />
    </div>
  </section>
</template>

<script setup>
import Button from '@/components/utility/Button.vue'

const props = defineProps(['data'])
</script>

<style lang="scss" scoped>
.text-container {
  @apply relative overflow-hidden py-8;

  .main-container {
    @apply xl:pr-32 xl:pl-44;

    :deep(.text) {
      h3 {
        @apply text-[20px] mb-9 sm:mb-11;
      }

      p {
        @apply text-[40px] sm:text-[60px] lg:text-[70px] leading-none font-[400] mb-[50px] md:mb-[70px];

        strong {
          @apply font-medium relative leading-tight underline;
        }
      }

      strong {
        @apply font-medium relative leading-tight underline;
      }
    }
  }
}
</style>
