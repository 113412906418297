<template>
  <section class="proj-section">
    <div v-if="props.data.layout != 3"  class="proj-section-imgs relative" :class="`imgs-layout-${props.data.layout}`">
        <div data-speed="1" class="inner-parallax">
            <img class="top-img" :src="data.big_Img"
            data-scroll
            data-scroll-direction="vertical"
            data-speed="0.8"
        />
        </div>
        <img v-if="data.small_Img && props.data.show_two" class="bottom-img" :src="data.small_Img"
        data-scroll
        data-scroll-direction="vertical"
        data-speed="auto"
        />
        <div v-else class="bottom-img no-img"/>
    </div>
    <div v-else class="proj-section-imgs" :class="[`imgs-layout-${props.data.layout}`, props.data.show_two ? '': 'wide-img']">
        <div class="inner-parallax">
            <img class="top-img" :src="data.big_Img"
            data-scroll
            data-scroll-direction="vertical"
            data-speed="0.8"
            />
        </div>
        <div v-if="data.small_Img && props.data.show_two" class="inner-parallax">
            <img class="bottom-img" :src="data.small_Img"
            data-scroll
            data-scroll-direction="vertical"
            data-speed="0.8"
            />
        </div>
    </div>
    <slot/>
  </section>
</template>

<script setup>
const props = defineProps(['data'])
</script>

<style lang="scss" scoped>
.proj-section {
    @apply w-full px-2 flex flex-col
    md:px-[23px]
    xl:px-0 xl:max-w-[1700px];
    .no-img{
    //   @apply bg-red-400;
    }
    &-imgs {
        @apply flex flex-col gap-[10px] relative
        ;
       img{
            @apply rounded-[27px] object-cover
        }
        .top-img {
            @apply w-full h-full ;
        }
        .inner-parallax {
            @apply h-[540px] -z-1
            md:h-[768px] relative overflow-hidden
            object-cover w-full rounded-[27px]
        }
        &.imgs-layout-1 {
            @apply xl:pl-[96px] xl:pr-[114px];
            .inner-parallax {
                @apply h-[267px] w-full
                md:h-[517px] md:mb-[-120px] md:self-end
                lg:max-w-[1200px]
                xl:h-[790px] xl:mb-[-220px];

            }
            .bottom-img {
                @apply h-[465px]
                md:h-[455px] md:w-[411px] md:ml-4
                xl:w-[631px] xl:h-[700px] xl:md:ml-0;
                &.no-img{
                    @apply hidden;
                }
            }
        }

        &.imgs-layout-2 {
            @apply xl:pl-[96px] xl:pr-[84px];
            .inner-parallax {
                @apply h-[269px] w-full
                md:h-[435px] md:w-[660px] md:mb-[-70px]
                lg:w-auto lg:max-w-[1000px]
                xl:max-w-[1200px] xl:h-[790px] xl:mb-[-125px];
            }
            .bottom-img {
                @apply h-[351px]
                md:w-[475px] md:h-[408px] md:self-end
                xl:w-[815px] xl:h-[700px] xl:md:ml-0
                ;
                &.no-img{
                    @apply hidden;
                }
            }

        }
        &.imgs-layout-3 {
           @apply md:flex-row justify-center
           xl:gap-[13px] xl:px-2;
           img {
            @apply h-full w-full
            lg:h-[110%]
            ;
           }
           .inner-parallax {
            @apply md:w-[49.5%] md:max-w-[767px] md:min-h-[400px]
            md:max-h-[660px]
            xl:h-[660px]
            ;
           }
           &.wide-img{
            @apply w-full md:w-full lg:w-full;
            .inner-parallax {
                @apply max-w-none w-full;
            }
           }
        }
    }

}
</style>
