<template>
    <section class="decorated-list" :id="`list-${sectionID}`">
        <ul
        :class="`total-${data.items.length}-items`"
        >
            <li class="decorated-list-item" :class="`decorated-list-item-${idx}-${sectionID}`"
                v-for="item, idx in data.items" :key="idx">
                <div class="decorated-list-item-deco" :style="{
                    border: `1px solid ${data.bullet_c}`
                }"/>
                <div class="decorated-list-item-text" :class="`font-s-${data.content_font_size}`" v-html="item.text"/>
            </li>
        </ul>
    </section>
</template>

<script setup>
import { onMounted, onBeforeUnmount, getCurrentInstance } from 'vue'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
const sectionID = `video-${Math.floor(Math.random() * getCurrentInstance().uid * 1000)}`
const props = defineProps(['data'])

gsap.registerPlugin(ScrollTrigger)
const animateList = async () => {
  const distanceFour = [200, 150, 100, 50]
  const distanceThree = [200, 150, 100, 200, 150, 100]

  const tl = gsap.timeline({
    scrollTrigger: {
      id: 'timeline',
      trigger: props.data.add_id ? `#list-${sectionID}` : '.decorated-list',
      start: '0% center',
      end: '65% center',
      scrub: 1
    }
  })

  const itemAnimConfig = (idx) => {
    if (props.data.items.length === 4) {
      return {
        y: -distanceFour[idx],
        ease: 'power2'
      }
    } else {
      return {
        y: -distanceThree[idx],
        ease: 'power2'
      }
    }
  }
  const circleConfig = {
    background: props.data.bullet_c,
    border: `1px solid ${props.data.bullet_c}`,
    boxShadow: `0px 0px 20px ${props.data.bullet_c}, 0px 0px 10px ${props.data.bullet_c}`
  }
  const timeOffset = '-=0.5'

  tl.from(
    `.decorated-list-item-0-${sectionID}`,
    itemAnimConfig(0)
  )

    .to(
      `.decorated-list-item-0-${sectionID} > .decorated-list-item-deco`,
      circleConfig, '<'
    )
    .from(
      `.decorated-list-item-1-${sectionID}`,
      itemAnimConfig(1), timeOffset
    )

    .to(
      `.decorated-list-item-1-${sectionID} > .decorated-list-item-deco`,
      circleConfig, '<'
    )

    .from(
      `.decorated-list-item-2-${sectionID}`,
      itemAnimConfig(2), timeOffset
    )

    .to(
      `.decorated-list-item-2-${sectionID} > .decorated-list-item-deco`,
      circleConfig, '<'
    )
  if (props.data.items.length > 3) {
    tl.from(
      `.decorated-list-item-3-${sectionID}`,
      itemAnimConfig(3), timeOffset
    )
      .to(
      `.decorated-list-item-3-${sectionID} > .decorated-list-item-deco`,
      circleConfig, '<'
      )
      .from(
      `.decorated-list-item-4-${sectionID}`,
      itemAnimConfig(4), timeOffset
      )
      .to(
      `.decorated-list-item-4-${sectionID} > .decorated-list-item-deco`,
      circleConfig, '<'
      )
      .from(
      `.decorated-list-item-5-${sectionID}`,
      itemAnimConfig(5), timeOffset
      )
      .to(
      `.decorated-list-item-5-${sectionID} > .decorated-list-item-deco`,
      circleConfig, '<'
      )
  }
}
onMounted(async () => {
  // console.log('gusid:', gusID)
  await animateList()
})
onBeforeUnmount(() => {
  const timeline = ScrollTrigger.getById('timeline')
  if (timeline) timeline.kill()
})
</script>

<style lang="scss" scoped>
.decorated-list {
    @apply main-container pt-60 pb-20
    lg:flex lg:justify-center
    ;
    ul {
        @apply flex
        flex-row flex-wrap gap-4 mobile:gap-8 md:gap-12
        lg:w-fit
        ;
        li {
            @apply lg:w-[280px];
        }
    }
    .total-2-items {
        @apply md:flex-nowrap
        lg:w-[80%]
        xl:gap-[80px] xl:justify-between xl:px-28;
        li {
            @apply md:flex-grow xl:min-w-[280px] xl:max-w-[350px];
        }
    }
    .total-3-items {
        @apply md:flex-nowrap
        xl:gap-[80px] xl:justify-between xl:w-full xl:px-28;
        li {
            @apply md:flex-grow xl:min-w-[280px] xl:max-w-[350px];
        }
    }
    .total-4-items {
        li {
            @apply w-[45%]
            xl:w-[280px];
        }
    }
    .total-5-items {
      @apply md:flex-wrap
      xl:gap-[80px] xl:justify-start xl:w-full xl:px-28;
      li {
          @apply md:w-[45%] xl:w-auto xl:min-w-[280px] xl:max-w-[350px];
      }
    }
    .total-6-items {
        @apply md:flex-wrap
        xl:gap-[80px] xl:justify-between xl:w-full xl:px-28;
        li {
            @apply md:w-[45%] xl:w-auto xl:min-w-[280px] xl:max-w-[350px];
        }
    }

    &-item {
        @apply mb-16 xl:mb-0;
        &-deco {
            @apply mb-9 w-[26px] h-[26px] border-[1px] border-ore_green_dark rounded-full
        }
        &-text {
            &:deep(strong){
                @apply text-[30px]
            }
             &:deep(h3){
                @apply text-xl xs:text-[24px] leading-[24px] mb-7
                md:mb-10
                lg:text-[30px] lg:leading-[30px];
            }
            &:deep(p){
                @apply mb-7
                md:mb-10
                ;
            }
            &:deep(p):last-child{
                @apply mb-0;
            }
        }
        .font-s-medium {
            &:deep(p){
                 @apply text-[20px] leading-[24px];

            }

        }
        .font-s-large {
            &:deep(p){
                @apply text-[20px] leading-[24px]
                lg:text-[30px] lg:leading-[30px];
            }

        }
    }

}
</style>
