<template>
  <section class="blogs-list">
    <div class="container">
      <div class="buttons">
        <div class="nav">
            <div class="button-container">
                <button class="btn-l" @click="scroll('<')" :class="{'hide': !scrollPos}" > ← </button>
                <button class="btn-r" @click="scroll('>')" :class="{'hide': scrollPos}" > → </button>
            </div>
          <div class="tab" :class="{active: 'All' === currentTab}"  @click="switchTab('All')">See All</div>
          <div class="tab" :class="{active: item.name === currentTab}" @click="switchTab(item.name)" v-for="(item, index) in contentTabs" :key="`tab-${index}`">
            {{ item.label }}
          </div>
        </div>
      </div>

      <div class="cards" v-for="(posts, index1) in allData" :key="`cards-${index1}`">
        <template v-if="currentTab === 'All'">
          <template v-if="posts.postType === 'All'">
            <div v-for=" idx in postsCounter" :key="`allCard-${idx}`" class="card"
            :class="{
                'full': idx % 3 === 1,
                'dont-show': idx > posts.posts.length
            }"
            >
                <div class="image" v-if="posts.posts[idx-1]">
                <img :src="posts.posts[idx-1].featured_image?.large.length > 1 ? posts.posts[idx-1].featured_image.large : image" alt="Image">
              </div>
              <div class="text" >
                <div class="description" v-if="posts.posts[idx-1]">
                    <p  v-if="posts.posts[idx-1].type === contentTabs[0].single" class="date" v-html="formatDate(posts.posts[idx-1].date)" />
                    <p v-if="posts.posts[idx-1].type === contentTabs[1].single" class="type">{{contentTabs[1].singleLabel}}</p>
                  <p v-if="posts.posts[idx-1].type === contentTabs[0].single" class="type">{{contentTabs[0].singleLabel}}</p>

                </div>
                <div class="information" v-if="posts.posts[idx-1]">
                  <div class='single-title' v-html="posts.posts[idx-1].title.rendered"/>
                  <div v-html="truncate(posts.posts[idx-1].excerpt.rendered)" v-if="posts.posts[idx-1].type === 'post'" />
                  <p v-html="truncateNoP(posts.posts[idx-1].acf['acf-single-portfolio-information'])" v-if="posts.posts[idx-1].type === 'portfolio'" />
                  <Button :url="posts.posts[idx-1].link ? posts.posts[idx-1].link : '#'" text="Learn More" color="dark-green" lineHover="bg-mint-30"
                    circleColor="circle-orange" />
                </div>
              </div>
            </div>
          </template>
        </template>

        <template v-if="currentTab == contentTabs[0].name">
          <template v-if="posts.postType === contentTabs[0].name">
            <div v-for=" idx in postsCounter" :key="`allCard-${idx}`" class="card"
            :class="{
                'full': idx % 3 === 1,
                'dont-show': idx > posts.posts.length
            }"
            >
                <div class="image" v-if="posts.posts[idx-1]">
                <img :src="posts.posts[idx-1].featured_image?.large.length > 1 ? posts.posts[idx-1].featured_image.large : image" alt="Image">
              </div>
              <div class="text" >
                <div class="description" v-if="posts.posts[idx-1]">
                  <p  v-if="posts.posts[idx-1].type === contentTabs[0].single" class="date" v-html="formatDate(posts.posts[idx-1].date)" />
                    <p v-if="posts.posts[idx-1].type === contentTabs[1].single" class="type">{{contentTabs[1].singleLabel}}</p>
                  <p v-if="posts.posts[idx-1].type === contentTabs[0].single" class="type">{{contentTabs[0].singleLabel}}</p>

                </div>
                <div class="information" v-if="posts.posts[idx-1]">
                  <div class='single-title' v-html="posts.posts[idx-1].title.rendered"/>
                  <div v-html="truncate(posts.posts[idx-1].excerpt.rendered)" v-if="posts.posts[idx-1].type === 'post'" />
                  <p v-html="truncateNoP(posts.posts[idx-1].acf['acf-single-portfolio-information'])" v-if="posts.posts[idx-1].type === 'portfolio'" />
                  <Button :url="posts.posts[idx-1].link ? posts.posts[idx-1].link : '#'" text="Learn More" color="dark-green" lineHover="bg-mint-30"
                    circleColor="circle-orange" />
                </div>
              </div>
            </div>
          </template>
        </template>
        <template v-if="currentTab == contentTabs[1].name">
          <template v-if="posts.postType === contentTabs[1].name">
            <div v-for=" idx in postsCounter" :key="`allCard-${idx}`" class="card"
            :class="{
                'full': idx % 3 === 1,
                'dont-show': idx > posts.posts.length
            }"
            >
                <div class="image" v-if="posts.posts[idx-1]">
                <img :src="posts.posts[idx-1].featured_image?.large.length > 1 ? posts.posts[idx-1].featured_image.large : image" alt="Image">
              </div>
              <div class="text" >
                <div class="description" v-if="posts.posts[idx-1]">
                  <p  v-if="posts.posts[idx-1].type === contentTabs[0].single" class="date" v-html="formatDate(posts.posts[idx-1].date)" />
                    <p v-if="posts.posts[idx-1].type === contentTabs[1].single" class="type">{{contentTabs[1].singleLabel}}</p>
                  <p v-if="posts.posts[idx-1].type === contentTabs[0].single" class="type">{{contentTabs[0].singleLabel}}</p>

                </div>
                <div class="information" v-if="posts.posts[idx-1]">
                  <div class='single-title' v-html="posts.posts[idx-1].title.rendered"/>
                    <div v-html="truncate(posts.posts[idx-1].excerpt.rendered)" v-if="posts.posts[idx-1].type === 'post'" />
                    <p v-html="truncateNoP(posts.posts[idx-1].acf['acf-single-portfolio-information'])" v-if="posts.posts[idx-1].type === 'portfolio'" />
                  <Button :url="posts.posts[idx-1].link ? posts.posts[idx-1].link : '#'" text="Learn More" color="dark-green" lineHover="bg-mint-30"
                    circleColor="circle-orange" />
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
      <div v-if="postsCounter < currentTotal" class="loading">
        <button  @click="loadMore()"> <span>Load More</span> </button>
      </div>
    </div>

  </section>
</template>

<script setup>
import PlaceholderImage from '@/assets/img/dummy.jpg'
import Button from '@/components/utility/Button.vue'
import { onBeforeMount, ref, reactive } from 'vue'
import { useStore } from 'vuex'
import { format } from 'date-fns'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { el, ru } from 'date-fns/locale'
gsap.registerPlugin(ScrollTrigger)

// --- STATE ---
const contentTabs = ref([
  {
    name: 'posts',
    label: 'Blogs',
    singleLabel: 'Blog',
    single: 'post'
  },
  {
    name: 'portfolio',
    label: 'Portfolio',
    singleLabel: 'Portfolio',
    single: 'portfolio'
  }
])
const store = useStore()
const image = PlaceholderImage
const allData = reactive({})
const postsCounter = ref(3)
const currentTotal = ref(0)
const currentTab = ref('All')
const scrollPos = ref(0)

// --- VUEX ---
function dispatch (type, showLoading = true) {
  return store.dispatch('getItems', {
    type,
    params: {
      per_page: 100

    },
    showLoading
  })
}

function getter (type, showLoading = true) {
  return store.getters.requestedItems({
    type,
    params: {
      per_page: 100
    },
    showLoading
  })
}

// --- MISC FUNCTIONALITY ---
const switchTab = (type) => {
  if (currentTab.value !== type) {
    postsCounter.value = 3
    currentTab.value = type
    currentTotal.value = allData[currentTab.value].posts.length
  }
}

const formatDate = (date) => {
  const dateToFormat = new Date(date.split(' ')[0])
  // compensing for a problem where the returned date is a day before the original unformatted date
  dateToFormat.setDate(dateToFormat.getDate())
  return format(dateToFormat, 'MMMM dd, yyyy')
}

const loadMore = () => {
  if (postsCounter.value < currentTotal.value) {
    postsCounter.value = postsCounter.value + 3
  }
  shuffle()
}

const scroll = (dir) => {
  switch (dir) {
    case '>':
      // console.log('to right')
      scrollPos.value = 1
      anim(dir)
      break
    case '<':
      // console.log('to left')
      scrollPos.value = 0
      anim(dir)
  }
}
const truncate = (text) => {
  const maxChar = 187
  const textOnly = text.slice(3, text.length - 5)
  // console.log(textOnly)
  if (textOnly.length > maxChar) {
    const truncated = `${textOnly.slice(0, maxChar)}...`
    return '<p>' + truncated + '</p>'
  } else {
    return text
  }
}

const truncateNoP = (text) => {
  const maxChar = 187
  if (text.length > maxChar) {
    const truncated = `${text.slice(0, maxChar)}...`
    return truncated
  } else {
    return text
  }
}

// -- ANIMATIONS --
const anim = (dir) => {
  const distance = 100
  const target = '.tab'
  switch (dir) {
    case '>':
      gsap.to(target, {
        x: -distance
      })
      break
    case '<':
      gsap.to(target, {
        x: 0
      })
  }
}
// --- RETRIEVEING DATA FROM STORE ---
// ---       AND SORTING IT        ---
// The 'All' list is showing all data MINUS 1: it's returning undefined on the last project.
// need to fix: check getInfo() and shuffle()
const getInfo = async (showLoading) => {
  contentTabs.value.forEach(async (tabData) => {
    const type = tabData.name
    await dispatch(type, showLoading)
    const posts = await getter(type, showLoading)
    if (allData[type]) {
      allData[type].posts = [...allData[type].posts, ...posts]
    } else {
      allData[type] = { postType: type, posts }
    }
    await shuffle()
  })
}

async function shuffle () {
  const temp = []
  const posts = allData.posts?.posts
  const portf = allData.portfolio?.posts
  console.log()
  let run = 0; let first = 0; let second = 0
  while (run < posts?.length + portf?.length) {
    if (first > second) {
      if ((!portf[second] || !posts[first] || !posts[second]) || (!posts[second] || !portf[first] || !portf[second])) {
        if ((!portf[first] && portf[second]) || (!posts[first] && posts[second])) {
          temp[run] = portf[second]
        } else {
          temp[run] = portf[first]
        }
        second++
        first++
      } else if (!posts[second]) {
        temp[run] = posts[first]
        first++
      } else {
        temp[run] = portf[second]
        second++
      }
    } else {
      temp[run] = posts[first]
      first++
    }
    run++
  };

  if (!allData.All) {
    allData.All = { postType: 'All', posts: temp }
  } else {
    allData.All.posts = temp
  }
  if (currentTotal.value === 0) {
    currentTotal.value = allData.All.posts.length
  }
}

// --- HOOKS ---
onBeforeMount(async () => {
  await getInfo()
})
</script>

<style lang="scss" scoped>
.blogs-list {
  @apply relative pt-5 pb-16;

  .container {
    @apply wide-container px-[10px] max-w-[1540px];

    .buttons {
      @apply overflow-hidden relative mb-5 bg-ore_green w-full mx-auto rounded-[27px]
      md:max-w-[780px]
      lg:max-w-[875px]
      lg:mb-28
      ;
      .nav {
        @apply overflow-hidden relative p-4 flex justify-start max-w-full mobile:w-full sm:justify-between
        ;

        &:before {
          @apply content-[''] absolute right-0 top-0 bottom-0 left-0 w-full opacity-10 sm:opacity-0;
          background-image: linear-gradient(to right, transparent 0%, white 50%);
        }
        .button-container{
            @apply absolute left-0 top-0 w-full h-full flex justify-between items-center
            sm:hidden;

            button {
                @apply transition-all absolute bg-white rounded-full z-1 w-[40px] h-[40px];
            }
            .btn-l {
                @apply left-[15px];
            }
            .btn-r {
                @apply right-[15px];
            }
            .hide {
                @apply opacity-0 pointer-events-none;
            }
        }
        .tab {
          @apply cursor-pointer transition-all text-center text-white py-4 px-8 rounded-full inline-block flex-[1_0_auto] relative opacity-40 text-[24px] sm:grow-0 sm:px-12;
            &:hover {
                @apply opacity-100;
            }

          &:first-child {
            @apply px-12;
          }

          &.active {
            @apply bg-ore_orange opacity-100 shadow-[1px_0px_9px_0px_rgba(255,76,0,0.75)];
          }
        }
      }
    }

    .cards {
      @apply relative md:flex md:flex-wrap md:gap-y-[15px] md:gap-x-[25px];

      +.cards {

      }

      .card {
        @apply relative mt-5 bg-white p-[15px] rounded-[27px] overflow-hidden md:w-[calc(50%_-_12.5px)] md:mt-0
        lg:max-h-[1001px]
        lg:p-[25px];
        .image {
          @apply rounded-[27px] h-[240px] relative overflow-hidden lg:h-[455px];

          img {
            @apply absolute top-0 left-0 object-cover object-center w-full h-full;
          }
        }

        .text {
          @apply mt-8 px-[10px] md:mt-5 md:pr-5 lg:mt-14;

          .description {
            @apply flex justify-start gap-14 mb-7 lg:mb-12;

            p {
              @apply text-[16px] leading-none;
            }
          }

          :deep(.information) {
            @apply flex flex-col justify-between;

            p {
              @apply min-h-[120px] text-[20px] leading-none
              lg:max-h-[120px] lg:text-[30px] lg:mb-20;
            }
            .button{
               @apply mt-[0px] md:mt-[16px] mb-[15px];

            }
          }
        }

        &.full {
          @apply md:flex md:gap-[25px] md:w-full lg:max-h-[768px] lg:gap-[90px] lg:items-center;

          .image {
            @apply md:w-1/2 md:h-full lg:h-[700px];
          }

          .text {
            @apply md:w-1/2 lg:mt-2 lg:h-[50%] lg:pl-0 lg:flex lg:flex-col lg:justify-center;

            :deep(.information) {
                @apply md:mb-0 md:justify-start;
                div {
                }
              p {
                @apply lg:text-[40px] lg:mb-24 lg:max-h-full;
                &:last-child {
                  @apply lg:mb-24;
                }
              }

            }
          }
        }
        .single-title {
                @apply text-[40px] mb-[40px];
              }
        &.dont-show {
            @apply hidden;
        }
      }
    }
    .loading {
        @apply flex justify-center py-[70px]
        ;
        button {
            @apply bg-ore_green text-white p-8 transition-all rounded-[27px] inline-block flex-[1_0_auto] relative text-[24px] sm:grow-0 sm:px-12;
            span {
                @apply opacity-40 transition-all;
                //text-center text-white py-4 px-8 rounded-full inline-block flex-[1_0_auto] relative  text-[24px] sm:grow-0 sm:px-12;
            }
            &:hover {
                span {
                    @apply opacity-100;
                }
            }
            // &:focus {
            //     span {
            //         @apply opacity-100 bg-ore_orange shadow-[1px_0px_9px_0px_rgba(255,76,0,0.75)];
            //     }
            // }
        }
    }

  }
}
</style>
