<template>
  <header
    ref="header"
    id="header-nav"
  >
    <nav ref="navWrapper">
      <div class="branding">
        <div class="logo-container">
          <a href="/" title="Logo" @click="close">
            <img v-if="site.site_logo" :src="site.site_logo.url" />
          </a>
        </div>
      </div>
      <div class="wrap-nav">
        <ul class="nav-container" ref="nav" id="header-nav-container" :class="{active: activeState, scrolling: scrolling}">
          <template v-if="$store.state.site.introShown">
            <li
              v-for="item in mainMenu"
              :key="item.id"
              class="menu-item"
            >
              <router-link
                :to="$root.convertToRelative(item.url)"
                :title="item.content"
                :target="item.target ? item.target : '_self'"
                >{{ item.content }}</router-link
              >
            </li>
          </template>
        </ul>
        <QuotesWidget :class="{active: activeState}" />
        <div
          class="hamburguer"
          @click="toggle"
          :class="{ active: activeState }"
        >
          <div class="line" />
          <div class="line" />
        </div>
      </div>
    </nav>
    <SecondaryMenu
      :primaryMenu="mainMenu"
      :secondaryMenu="secondaryMenu"
      :active="activeState"
      @updated-state="toggle"
    />
  </header>
</template>

<script setup>
import { ref, onUnmounted, onBeforeMount, getCurrentInstance } from 'vue'
import { useStore } from 'vuex'
import SecondaryMenu from './SecondaryMenu.vue'
import QuotesWidget from './QuotesWidget.vue'

const store = useStore()
const mainMenu = store.getters.menu({ name: 'main-menu' })
const secondaryMenu = store.getters.menu({ name: 'secondary-menu' })
const site = store.getters.acfOptions()
const activeState = ref(false)
const curr = getCurrentInstance()
const scrollTop = ref(0)
const scrolling = ref(false)

function toggle () {
  activeState.value = !activeState.value
  const html = document.querySelector('html')

  if (activeState.value === true) {
    html.classList.add('no-overflow')
    curr.root.data.locoScroll.stop()
  } else {
    html.classList.remove('no-overflow')
    curr.root.data.locoScroll.start()
  }
}

function close () {
  if (activeState.value === true) {
    toggle()
  }
}

function closeHandler (e) {
  if (e.keyCode === 27 && activeState.value === true) {
    toggle()
  }
}

function handleScroll () {
  scrollTop.value = window.pageYOffset
  if (scrollTop.value >= 100) {
    scrolling.value = true
  } else {
    scrolling.value = false
  }
}

onBeforeMount(() => {
  document.addEventListener('keyup', closeHandler, { passive: true })
  document.addEventListener('scroll', handleScroll, { passive: true })
})

onUnmounted(() => {
  document.removeEventListener('keyup', closeHandler, false)
  document.removeEventListener('scroll', handleScroll, false)
})
</script>

<style lang="scss" scoped>
#header-nav {
  @apply fixed bg-transparent w-full z-10 pt-3 pb-1 lg:py-1;

  nav {
    @apply flex flex-row items-center justify-between pl-6 pr-2 md:px-5 2xl:px-0 max-w-[1600px] m-auto relative z-10;
    .logo-container {
      @apply w-20 md:w-36;
    }
    .wrap-nav {
      @apply flex flex-row items-center xl:max-w-[1200px];

      ul {
        @apply flex flex-row items-center content-end transition-all duration-300 w-full justify-end lg:justify-between lg:px-4 lg:gap-5 lg:mr-5 lg:min-h-[54px] xl:gap-14 xl:px-8 2xl:gap-20;

        li {
          @apply w-auto text-center text-xl font-normal pointer-events-auto ease-in delay-[200ms] duration-500;

          &.menu-item {
            @apply hidden lg:block;

            a {
              @apply relative inline-block;
              &:before {
                @apply bg-black content-[''] w-0 h-px block absolute -bottom-2 ease-out duration-500;
              }

                &:after {
                @apply content-[''] absolute w-1 h-1 bg-ore_mint -left-2 top-[calc(50%_-_2px)] rounded-full opacity-0 xl:w-2 xl:h-2 xl:-left-3 xl:top-[calc(50%_-_4px)] transition-all 2xl:-left-4;
              }

              &:hover {
                &:before {
                  @apply w-full ease-out duration-500;
                }
              }

                &.router-link-active,
              &.router-link-exact-active {
                 &:before {
                  @apply w-full ease-out duration-500;
                }
              }
            }
          }
        }

        &.scrolling {
          @apply bg-ore_green_dark/70 backdrop-blur rounded-full;

          li {
            a {
              @apply text-white relative;

              &.router-link-active,
              &.router-link-exact-active {
                &:after {
                  @apply opacity-100;
                }

                &:before{
                  @apply w-0;
                }

                &:hover{
                  &:after{
                    @apply bg-ore_orange;
                  }
                }
              }

              &:hover{
                &:before{
                  @apply hidden;
                }

                 &:after {
                  @apply opacity-100;
                }
              }
            }
          }
        }

      &.active {
        @apply lg:pointer-events-none lg:-translate-y-[160px] lg:delay-300 lg:duration-700 lg:ease-in-out;
      }
      }

      .hamburguer {
        @apply flex-[1_0_auto] flex flex-col items-center justify-center rounded-full border border-black transition duration-300 ease-in-out ml-0  w-[70px] h-[70px] md:w-[90px] md:h-[90px] lg:w-[120px] lg:h-[120px] lg:ml-4 xl:ml-14 2xl:ml-20;
        .line {
          @apply w-[40px] h-[3px] bg-black my-[6px] transition duration-300 ease-in-out md:w-[36px] lg:my-[10.5px] lg:w-[48px];
        }

        &:hover {
          @apply scale-75 cursor-pointer;
          .line {
            @apply my-[5.5px] lg:my-[9.5px] ease-in-out duration-300;
          }
        }

        &.active {
          @apply scale-100;

          .line {
            &:first-child {
              @apply transform rotate-45  w-[30px] translate-x-[1px] translate-y-[8px] md:w-[35px] md:translate-x-[3px] md:translate-y-[8px] lg:w-[48px] lg:translate-x-[3px] lg:translate-y-[12px];
            }
            &:last-child {
              @apply transform -rotate-45  w-[30px] translate-x-[1px] translate-y-[-7px] md:w-[35px] md:translate-x-[2px] md:translate-y-[-7px] lg:w-[48px] lg:translate-x-[2px] lg:translate-y-[-12px];
            }
          }

          &:hover {
            .line {
              @apply my-[6px] lg:my-[10.5px];
            }
          }
        }
      }
    }
  }
}
</style>
