import {
  fetchItems,
  fetchSingle,
  fetchSingleById,
  fetchSearch,
  fetchAcfOptions,
  setQuoteItem,
  deleteQuoteItem
} from '@/api'

export default {
  getSingleBySlug ({ getters, commit }, { type, slug, showLoading = false }) {
    if (!getters.singleBySlug({ type, slug })) {
      if (showLoading) {
        commit('SET_LOADING', true)
      }
      return fetchSingle({ type, params: { slug } }).then(
        ({ data: [item] }) => {
          commit('ADD_ITEM', { type, item })
          if (showLoading) {
            commit('SET_LOADING', false)
          }
          return item
        }
      )
    }
  },
  getSingleById (
    { getters, commit },
    { type, id, embed = false, showLoading = false, batch = false }
  ) {
    if (!getters.singleById({ type, id })) {
      if (showLoading) {
        commit('SET_LOADING', true)
      }
      return fetchSingleById({ type, id, batch, embed }).then(({ data }) => {
        if (batch) {
          data.forEach((item) => commit('ADD_ITEM', { type, item }))
        } else {
          commit('ADD_ITEM', { type, item: data })
        }
        if (showLoading) {
          commit('SET_LOADING', false)
        }
      })
    }
  },
  getItems ({ getters, commit }, { type, params, showLoading = false }) {
    if (!getters.request({ type, params })) {
      if (showLoading) {
        commit('SET_LOADING', true)
      }
      return fetchItems({ type, params }).then(
        ({
          data: items,
          headers: { 'x-wp-total': total, 'x-wp-totalpages': totalPages }
        }) => {
          items.forEach((item) => commit('ADD_ITEM', { type, item }))
          commit('ADD_REQUEST', {
            type,
            request: {
              params,
              total: parseInt(total),
              totalPages: parseInt(totalPages),
              data: items.map((i) => i.id)
            }
          })
          if (showLoading) {
            commit('SET_LOADING', false)
          }
        }
      )
    }
  },
  updateDocTitle ({ state, commit }, { parts = [], sep = ' – ' }) {
    parts[0]
      ? commit('SET_DOC_TITLE', unescape(parts.join(sep)))
      : commit('SET_DOC_TITLE', unescape(parts[1]))
    document.title = state.site.docTitle
  },
  getAcfOptions ({ commit }) {
    return fetchAcfOptions().then((response) => {
      commit('ACF_OPTIONS', response.data.acf)
    })
  },
  performSearch ({ commit }, { term, params }) {
    return fetchSearch({ term, params })
      .then((response) => {
        return response
      })
      .catch(err => {
        console.error({ err }, `No results for ${term}.`)
        throw err
      })
  },
  // add to quote action
  addToQuote ({ getters, commit }, { type, params, showLoading = false }) {
    if (showLoading) {
      commit('SET_LOADING', true)
    }
    return setQuoteItem({ type, params })
      .then(({ data: items, headers: { 'x-wp-total': total, 'x-wp-totalpages': totalPages } }) => {
        commit('CLEAR_ITEMS', { type: 'quote', data: {} })
        items.forEach(item => commit('ADD_ITEM', { type: 'quote', item }))
        if (showLoading) {
          commit('SET_LOADING', false)
        }
      })
  },
  removeFromQuote ({ getters, commit }, { type, params, showLoading = false }) {
    if (showLoading) {
      commit('SET_LOADING', true)
    }
    return deleteQuoteItem({ type, params })
      .then(({ data: items, headers: { 'x-wp-total': total, 'x-wp-totalpages': totalPages } }) => {
        commit('CLEAR_ITEMS', { type: 'quote', id: params.quote_id })
        items.forEach(item => commit('ADD_ITEM', { type: 'quote', item }))
        if (showLoading) {
          commit('SET_LOADING', false)
        }
      })
  }
}
