<template>
  <section class="OurValues">
    <div class="main-container">
      <div class="titles">
      <h2>{{ data.title }}</h2>
      <p>{{ data.big_title }}</p>
    </div>
    <div class="content">
      <div class="text" v-html="data.content"></div>
      <div class="cta" v-if="data.call_to_action">
        <div class="space"></div>
        <Button color="white" lineHover="bg-orange" circleColor="circle-white" :url="data.call_to_action.url"
          :text="data.call_to_action.title" />
      </div>
    </div>
    <div class="listValues">
      <div ref="circles" class="lottie" />
      <div class="arrow_down">
        <img :src="ArrowDown" alt="">
      </div>
      <div class="list">
        <div class="listInner">
          <div class="item" v-for="(item, index) in data.value_list" :key="index">
            <div class="number">0{{ index + 1 }}</div>
            <div class="text">{{ item.value }}</div>
          </div>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>

<script setup>
import Button from '@/components/utility/Button.vue'
import animData from '@/assets/lottie/circles.json'
import LottieWeb from 'lottie-web'
import { onMounted, ref, onBeforeUnmount } from 'vue'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import ArrowDown from '@/assets/img/arrow_down.png'

const props = defineProps(['data'])
const circles = ref(null)
const yPositionDesk = props.data.value_list.length * 160
const yPositionTab = props.data.value_list.length * 130
const yPositionMob = props.data.value_list.length * 100

gsap.registerPlugin(ScrollTrigger)

const scrollCircle = () => {
  ScrollTrigger.matchMedia({
    '(max-width: 767px)': () => {
      gsap.set('.lottie', {
        scale: 3,
        y: '-40vh',
        x: '10vw'
      })
      gsap.set('.list', {

        y: '-20vh',
        x: '-10vw'
      })
      gsap.fromTo('.listInner', {
        y: 0
      }, {
        scrollTrigger: {
          id: 'list-inner',
          trigger: '.listValues',

          start: 'top center',
          end: 'bottom center',
          scrub: true

        },
        y: -yPositionMob// calcular segun la cantidad de item * h del item
      })

      ScrollTrigger.create({
        id: 'list-values',
        trigger: '.listValues',

        start: 'top center',
        end: 'bottom bottom',
        pin: '.list'

      })

      ScrollTrigger.create({
        id: 'list-values-two',
        trigger: '.listValues',

        start: 'top center',
        end: 'bottom bottom',
        pin: '.lottie'

      })

      ScrollTrigger.create({
        id: 'list-values-three',
        trigger: '.listValues',

        start: 'top center',
        end: 'bottom center',
        pinSpacing: false,

        onUpdate: ({ progress, direction, isActive }) => {
          circles.value.goToAndStop((progress * 1.0) * (circles.value.totalFrames - 1), true)
        }

      })
    },
    '(min-width: 768px) and (max-width: 1023px)': () => {
      gsap.set(['.list', '.lottie'], {
        y: '-140vh'
      })

      gsap.fromTo('.listInner', {
        y: 0
      }, {
        scrollTrigger: {
          id: 'list-values-four',
          trigger: '.listValues',

          start: 'top center',
          end: 'bottom center',
          scrub: true

        },
        y: -yPositionTab// calcular segun la cantidad de item * h del item
      })

      ScrollTrigger.create({
        id: 'list-values-five',
        trigger: '.listValues',

        start: 'top center',
        end: 'bottom bottom',
        pin: '.list'

      })

      ScrollTrigger.create({
        id: 'list-values-six',
        trigger: '.listValues',

        start: 'top center',
        end: 'bottom bottom',
        pin: '.lottie'

      })

      ScrollTrigger.create({
        onEnter: () => {
          // console.log('onEnter')
        },
        id: 'list-values-seven',
        trigger: '.listValues',

        start: 'top center',
        end: 'bottom center',

        pinSpacing: false,
        onUpdate: ({ progress, direction, isActive }) => {
          circles.value.goToAndStop((progress * 1.0) * (circles.value.totalFrames - 1), true)
        }

      })
    },
    '(min-width: 1024px)': () => {
      gsap.set(['.list', '.lottie'], {
        y: '-145vh'
      })
      gsap.set('.arrow_down', {
        y: '-132vh',
        x: -160
      })
      gsap.fromTo('.arrow_down', {
        paddingTop: '0px'
      }, {
        scrollTrigger: {
          id: 'list-values-eight',
          trigger: '.listValues',

          start: 'top center',
          end: 'bottom center',
          scrub: true

        },

        paddingTop: '100px'

      })
      gsap.fromTo('.listInner', {
        y: 0
      }, {
        scrollTrigger: {
          id: 'list-values-nine',
          trigger: '.listValues',

          start: 'top center',
          end: 'bottom center',
          scrub: true

        },
        y: -yPositionDesk// calcular segun la cantidad de item * h del item
      })

      ScrollTrigger.create({
        id: 'list-values-ten',
        trigger: '.listValues',

        start: 'top center',
        end: 'bottom bottom',
        pin: '.list'
      })

      ScrollTrigger.create({
        id: 'list-values-eleven',
        trigger: '.listValues',

        start: 'top center',
        end: 'bottom bottom',
        pin: '.lottie'
      })
      ScrollTrigger.create({
        id: 'list-values-twelve',
        trigger: '.listValues',

        start: 'top center',
        end: 'bottom bottom',
        pin: '.arrow_down'
      })

      ScrollTrigger.create({
        id: 'list-values-thirteen',
        trigger: '.listValues',

        start: 'top center',
        end: 'bottom center',
        pinSpacing: false,
        onUpdate: ({ progress, direction, isActive }) => {
          circles.value.goToAndStop((progress * 1.0) * (circles.value.totalFrames - 1), true)
        }

      })
    }
  })
}

onMounted(() => {
  scrollCircle()

  const anim = LottieWeb.loadAnimation({
    container: circles.value,
    renderer: 'svg',
    autoplay: false,
    loop: false,
    animationData: animData
  })
  circles.value = anim
})

onBeforeUnmount(() => {
  const listInner = ScrollTrigger.getById('list-inner')
  if (listInner) listInner.kill()

  const listValues = ScrollTrigger.getById('list-values')
  if (listValues) listValues.kill()

  const listValues2 = ScrollTrigger.getById('list-values-two')
  if (listValues2) listValues2.kill()

  const listValues3 = ScrollTrigger.getById('list-values-three')
  if (listValues3) listValues3.kill()

  const listValues4 = ScrollTrigger.getById('list-values-four')
  if (listValues4) listValues4.kill()

  const listValues5 = ScrollTrigger.getById('list-values-five')
  if (listValues5) listValues5.kill()

  const listValues6 = ScrollTrigger.getById('list-values-six')
  if (listValues6) listValues6.kill()

  const listValues7 = ScrollTrigger.getById('list-values-seven')
  if (listValues7) listValues7.kill()

  const listValues8 = ScrollTrigger.getById('list-values-eight')
  if (listValues8) listValues8.kill()

  const listValues9 = ScrollTrigger.getById('list-values-nine')
  if (listValues9) listValues9.kill()

  const listValues10 = ScrollTrigger.getById('list-values-ten')
  if (listValues10) listValues10.kill()

  const listValues11 = ScrollTrigger.getById('list-values-eleven')
  if (listValues11) listValues11.kill()

  const listValues12 = ScrollTrigger.getById('list-values-twelve')
  if (listValues12) listValues12.kill()

  const listValues13 = ScrollTrigger.getById('list-values-thirteen')
  if (listValues13) listValues13.kill()
})

</script>
<style lang="scss" scoped>
.OurValues{
    @apply relative z-auto ;
box-shadow: 0 0 0 1px #FF4C00;
@apply relative;
   &::before {
        @apply bg-ore_orange;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
    }
  .main-container{
    @apply relative z-1 lg:px-14;
    .titles{
        @apply flex flex-col lg:flex-row justify-between items-start py-16 md:py-32 lg:pt-56 lg:pb-36;
      h2{
        @apply text-ore_green_dark text-3xl block w-full lg:w-3/12 mb-8;
      }
      p{
        @apply text-ore_green_dark text-4xl md:text-6xl lg:text-7xl block w-full lg:w-9/12;
      }
    }
    .content{

      .text{
        @apply w-full lg:w-1/2 columns-1 md:columns-2 gap-1;
      }
      .text:deep(p){
        @apply text-ore_green_dark text-xl w-full md:w-full pb-10;
      }
      .cta{
        @apply  flex flex-row;
        .space{
          @apply w-0 md:w-1/2 lg:w-3/12;
        }
      }
    }
    .listValues{
      @apply flex flex-row justify-between py-10 md:py-56 h-[100vh] md:h-[300vh] items-center;
      .lottie{
        @apply w-3/12 max-h-24  md:max-h-full ;
      }
      .arrow_down{
        @apply hidden lg:block;
      }
      .list{
        @apply w-full h-[100px] md:h-[120px] lg:h-[150px] overflow-hidden;
        .listInner{
          .item{
            @apply flex flex-row justify-center items-center py-2 md:py-5 lg:py-10;
            .number{
              @apply text-ore_green_dark text-3xl p-10;
            }
            .text{
              @apply text-ore_green_dark text-4xl md:text-6xl lg:text-[100px];
            }
          }
        }
      }
    }
  }

}
</style>
