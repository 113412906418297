<template>
  <div
    id="ore-wrapper"
    @click="handleClicks"
  >
    <transition name="fade" mode="out-in">
      <SiteIntro v-if="shouldShowIntro" />
    </transition>
    <nav-menu class="main-nav" name="main-menu" />
    <div id="ore-content">
      <router-view v-slot="{ Component }">
        <Transition
          name="fade"
          mode="out-in"
          @before-enter="transitionElement"
          @after-leave="completeTransition"
        >
          <component :key="$route.path" :is="Component" />
        </Transition>
      </router-view>
    </div>
    <app-footer />
    <transition mode="out-in" name="fade">
      <site-loading v-if="loading" />
    </transition>
  </div>
</template>

<script>
import NavMenu from './components/template-parts/NavMenu/NavMenu.vue'
import AppFooter from './components/template-parts/Footer.vue'
import SiteLoading from './components/utility/SiteLoading.vue'
import SiteIntro from './components/utility/Intro.vue'
import { ProductState } from './components/Customizer/state'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Lenis from '@studio-freight/lenis'
import emitter from 'tiny-emitter/instance'

const { clearState } = ProductState()
gsap.registerPlugin(ScrollTrigger)
export default {
  components: {
    NavMenu,
    AppFooter,
    SiteLoading,
    SiteIntro
  },
  data () {
    return {
      introShown: false,
      screenWidth: screen.width,
      site: this.$store.state.site,
      showMenu: true,
      isMobile: false,
      isTablet: false,
      isDesktop: false,
      locoScroll: null,
      scroll: 0,
      loader: false,
      retries: 0
    }
  },
  computed: {
    shouldShowIntro () {
      return !this.introShown && !sessionStorage.getItem('introShown')
    },
    loading () {
      return this.$store.state.site.loading
    },
    logo () {
      if (this.site.logo) {
        return this.$store.getters.singleById({
          type: 'media',
          id: this.site.logo
        })
      } else {
        return ''
      }
    }
  },
  watch: {
    $route (to, from) {
      clearState()
      // ScrollTrigger.refresh()
    }
  },
  methods: {
    activateSmoothScroll () {
      if (this.locoScroll) return
      this.locoScroll = new Lenis({
        duration: 2,
        easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)),
        orientation: 'vertical',
        gestureOrientation: 'vertical',
        smoothWheel: true,
        wheelMultiplier: 1,
        smoothTouch: false,
        touchMultiplier: 2,
        infinite: false
      })
      this.locoScroll.on('scroll', ({ progress }) => {
        this.scroll = progress * 100
      })

      // this.locoScroll.on('scroll', ScrollTrigger.update)

      requestAnimationFrame(this.raf)
    },
    raf (time) {
      this.locoScroll.raf(time)
      requestAnimationFrame(this.raf)
    },
    transitionElement () {
      this.beforeEnter()
      this.showMenu = true
    },
    completeTransition () {
      this.showMenu = false
    },
    deviceAsign () {
      if (this.screenWidth < 768) {
        this.isMobile = true
        this.isTablet = false
        this.isDesktop = false
      } else if (this.screenWidth >= 768 && this.screenWidth < 1024) {
        this.isTablet = true
        this.isMobile = false
        this.isDesktop = false
      } else if (this.screenWidth >= 1024) {
        this.isTablet = false
        this.isMobile = false
        this.isDesktop = true
      }
    },
    beforeEnter () {
      emitter.emit('scrollBeforeEnter')
    },
    decodeTitle (title) {
      const txt = document.createElement('textarea')
      txt.innerHTML = title
      return txt.value
    },
    convertToRelative (url) {
      const path = url.replace(this.$store.state.site.url, '')
      return path
    },
    isExternal (url) {
      const domain = (url) => {
        return url.replace('http://', '').replace('https://', '').split('/')[0]
      }
      return domain(location.href) !== domain(url)
    },
    hasIntroShown () {
      if (sessionStorage.getItem('introShown')) {
        this.$store.state.site.introShown = true
        this.introShown = true
        return true
      } else {
        return false
      }
    },
    introComplete () {
      this.introShown = true
      sessionStorage.setItem('introShown', true)
      this.$store.state.site.introShown = true
    },
    getLinkEl (el) {
      while (el.parentNode) {
        if (el.tagName === 'A') return el
        el = el.parentNode
      }
    },
    handleClicks (e) {
      const a = this.getLinkEl(e.target)
      if (a && a.href.includes(this.site.url)) {
        const { altKey, ctrlKey, metaKey, shiftKey, button, defaultPrevented } =
          e
        // don't handle if has class 'no-router'
        if (a.className.includes('no-router')) return
        // don't handle with control keys
        if (metaKey || altKey || ctrlKey || shiftKey) return
        // don't handle when preventDefault called
        if (defaultPrevented) return
        // don't handle right clicks
        if (button !== undefined && button !== 0) return
        // don't handle if `target="_blank"`
        if (a.target && a.target.includes('_blank')) return
        // don't handle same page links
        const currentURL = new URL(a.href, window.location.href)
        if (currentURL && currentURL.pathname === window.location.pathname) {
          // if same page link has same hash prevent default reload
          if (currentURL.hash === window.location.hash) e.preventDefault()
          return
        }
        // Prevent default and push to vue-router
        e.preventDefault()
        const path = a.href.replace(this.site.url, '')
        this.$router.push(path)
      }
    },
    handleAnchors (el) {
      if (!this.locoScroll) {
        setTimeout(() => {
          this.handleAnchors()
        }, 100)
      } else {
        if (el) {
          const innerElement = document.querySelector(`${el}`)
          if (innerElement) {
            setTimeout(() => {
              console.log('starting scroll...')
              this.locoScroll.scrollTo(innerElement, {
                immediate: false,
                lock: true,
                offset: 80,
                force: true,
                onComplete: () => {
                  console.log('scroll done')
                }
              })
            }, 500)
          } else {
            if (this.retries > 10) return null
            setTimeout(() => {
              this.retries++
              console.log('retrying')
              this.handleAnchors(el)
            }, 300)
          }
        }
      }
    }
  },
  created () {
    this.$store.dispatch('getAcfOptions')
  },
  mounted () {
    emitter.on('smoothScrollTo', (el) => {
      this.handleAnchors(el)
    })
    this.activateSmoothScroll()
    this.deviceAsign()
    this.hasIntroShown()
    emitter.on('scrollNow', () => {
      if (!this.$router.currentRoute._value.hash) {
        this.locoScroll.scrollTo('top', { immediate: true })
      } else {
        const innerElement = document.querySelector(this.$router.currentRoute._value.hash)
        if (innerElement) this.$root.locoScroll.scrollTo(innerElement, { immediate: true })
      }
    })
  },
  destroyed () {
    if (this.observer) {
      this.observer.disconnect()
    }
    window.removeEventListener('resize', (e) => {
      this.deviceAsign()
      this.screenWidth = screen.width
    })
  }
}
</script>

<style lang="scss">
@import "@/assets/styles/index.scss";

/* Vue transition classes
-------------------------------------------- */

// .pin-spacer{
//     @apply pointer-events-none overflow-hidden;
// }
// .pin-spacer + .pin-spacer {
//     @apply pointer-events-auto;
// }

.fade-enter-from {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.4s ease-out;
}

.fade-leave-to {
  opacity: 0;
}

.fade-leave-active {
  transition: opacity 0.4s ease-in;
}

.slide-fade-enter-active {
  transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }

</style>
