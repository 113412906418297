<template>
  <div class="quantity-selector-inner">
    <div class="add-to-cart-button" :class="ready && !updating ? 'ready' : ''" @click="addToQuote()">
      {{updating ? 'adding to cart...' : route.query.customizer ? 'Update Quote' : 'Add To Quote'}}
    </div>
    <div class="quantity-selector">
      <div class="less-button" @click="decrementQuantity()"><img src="@/assets/img/minus.svg"></div>
      <div class="quantity">{{quantity.toLocaleString('en-US', { minimumIntegerDigits: 2 })}}</div>
      <div class="more-button" @click="incrementQuantity()"><img src="@/assets/img/plus.svg"></div>
    </div>
  </div>
</template>
<script setup>
import { watch, onMounted, ref, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { ProductState } from './state'
import { cartState } from '../Cart/Store'
import { useStore } from 'vuex'
const store = useStore()
const cartPage = computed(() => {
  return store.state.acf_options.global_quote_page.replace(store._state.data.site.url, '')
})

const { addToCart, updateProduct } = cartState()
// import { useStore } from 'vuex'
const {
  quantity,
  incrementQuantity,
  decrementQuantity,
  allFieldsFilled,
  product,
  currentImage,
  finish,
  finishCategory,
  size,
  designOptions,
  specsPDF,
  clearState
} = ProductState()

const router = useRouter()
const route = useRoute()
// const store = useStore()
const ready = ref(false)
const updating = ref(false)

onMounted(() => {
  if (allFieldsFilled.value) {
    ready.value = true
  } else {
    ready.value = false
  }
})

watch(allFieldsFilled, (next, previous) => {
  if (next) {
    ready.value = true
  } else {
    ready.value = false
  }
})

const navigate = async () => {
  const navigationResult = await router.push(cartPage.value)

  if (navigationResult) {
    // navigation prevented
  } else {
    // navigation succeeded (this includes the case of a redirection)
    clearState()
    updating.value = false
  }
}

const addToQuote = () => {
  if (allFieldsFilled.value) {
    const query = route.query
    if ('customizer' in query && query.customizer) {
      updateProduct({
        product: product.value,
        currentImage: currentImage.value,
        finish: finish.value,
        finishCategory: finishCategory.value,
        quantity: quantity.value,
        size: size.value,
        designOptions: designOptions.value,
        specsPDF: specsPDF.value
      }, route.query.index)
      updating.value = true
      navigate()
    } else {
      addToCart({
        product: product.value,
        currentImage: currentImage.value,
        finish: finish.value,
        finishCategory: finishCategory.value,
        quantity: quantity.value,
        size: size.value,
        designOptions: designOptions.value,
        specsPDF: specsPDF.value
      })
      updating.value = true
      navigate()
    }
  }
}

</script>
<style lang="scss" scoped>
  .quantity-selector-inner {
    @apply flex bg-white rounded-lg mt-3 p-4 items-center;
    .add-to-cart-button {
      @apply w-6/12 xl:w-7/12 text-center text-white text-[16px] xl:text-[24px] bg-[#DEE3DF] rounded-full p-2 pointer-events-none transition-all duration-300 ease-in-out;
      &.ready {
        @apply transition-all duration-300 ease-in-out bg-ore_orange shadow-[1px_0px_15px_0px_rgba(255,76,0,0.75)] cursor-pointer pointer-events-auto;
      }
    }
    .quantity-selector {
      @apply flex w-6/12 xl:w-5/12 justify-end;
      .less-button {
        @apply flex items-center cursor-pointer px-4 md:px-6 border-r border-solid border-ore_mint;
      }
      .quantity {
        @apply text-[20px] xl:text-[30px] px-4 md:px-8;
      }
      .more-button {
        @apply flex items-center cursor-pointer px-4 md:px-6 border-l border-solid border-ore_mint;
      }
    }
  }
</style>
