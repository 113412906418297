<template>
  <div class="wide-image-module-component" :style="`background-color: ${data.bg_color ? data.bg_color : 'transparent'}`">
  <div class="vimeo-video" v-if="data.is_video && data.wide_image_video_option" @click="setClick()">
      <VideoPlayer :data="data"  />
      <div class="video-player" :class="clicked ? 'clicked': 'no'">
        <div class="play">
            <img class="play-icon" src="@/assets/img/play_video.svg" />
        </div>
      </div>
  </div>
    <div class="wide-image-parallax" v-else>
      <div class="video-container" v-if="data.is_video && !data.wide_image_video_option" @click="handleVideo()">
        <video class="video-background" :id="videoID">
            <source :src="data.wide_module_video" type="video/mp4" />
          </video>
          <div class="video-player">
            <div class="play" :id="playID">
              <img class="play-icon" src="@/assets/img/play_video.svg" />
            </div>
          </div>
      </div>
      <div class="multi-img" v-if="data.animate">
        <div class="image-wrapper">
          <img
            class="parallax-img img-prev"
            :src="data.img.prev.url"
            :alt="data.wide_module_image_url.alt"
            />
        </div>
        <div class="image-wrapper">
          <img
            class="parallax-img img-curr"
            :src="data.img.curr.url"
            :alt="data.wide_module_image_url.alt"
            />
        </div>
        <div class="image-wrapper">
          <img
            class="parallax-img img-next"
            :src="data.img.next.url"
            :alt="data.wide_module_image_url.alt"
            />
        </div>
      </div>
      <img
        v-if="!data.is_video"
        class="parallax-img perra"
        :src="data.wide_module_image_url.url || data.wide_module_image_url.guid"
        :alt="data.wide_module_image_url.alt"
      />
    </div>
  </div>
</template>

<script setup>
import { onMounted, ref, getCurrentInstance, onBeforeUnmount } from 'vue'
import { gsap } from 'gsap'
import VideoPlayer from '@/components/utility/VideoPlayer.vue'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

const clicked = ref(false)
const videoID = `video-${Math.floor(Math.random() * getCurrentInstance().uid * 1000)}`
const playID = `play-${Math.floor(Math.random() * getCurrentInstance().uid * 1000)}`

defineProps(['data'])

const playVideo = async () => {
  try {
    await document.getElementById(videoID).play()
  } catch (err) {
    console.log(err)
  }
}

function setClick () {
  clicked.value = true
}

const handleVideo = () => {
  if (document.getElementById(videoID).paused) {
    playVideo()
    gsap.to(`#${playID}`, {
      autoAlpha: 0
    })
  } else {
    document.getElementById(videoID).pause()
    gsap.to(`#${playID}`, {
      autoAlpha: 1
    })
  }
}

onMounted(() => {
  gsap.registerPlugin(ScrollTrigger)
  gsap.set('.wide-image-parallax .parallax-img', { scale: 1.2 })
  gsap.fromTo('.wide-image-parallax .parallax-img', { y: -300 }, {
    scrollTrigger: {
      trigger: '.wide-image-parallax',
      id: 'wide-mage-parallax',
      start: 'top-=50% center',
      end: 'bottom+=50% center',
      scrub: true,
      markers: false
    },
    y: 0
  })

  // ScrollTrigger.refresh()
  if (document.getElementById(videoID)) {
    document.getElementById(videoID).addEventListener('ended', () => {
      gsap.to(`#${playID}`, {
        autoAlpha: 1
      })
    })
  }
})

onBeforeUnmount(() => {
  const siteFooter = ScrollTrigger.getById('wide-mage-parallax')
  if (siteFooter) siteFooter.kill()
  if (document.getElementById(videoID)) {
    document.getElementById(videoID).removeEventListener('ended', () => {
      gsap.to(`#${playID}`, {
        autoAlpha: 1
      })
    })
  }
})
</script>

<style lang="scss" scoped>
.wide-image-module-component {
  @apply w-full mx-auto py-[10px] px-[10px]
  md:px-[20px] md:py-[20px]
  lg:py-[15px] lg:px-[15px];
  .wide-image-parallax {
    @apply h-[540px]
    md:h-[768px] relative overflow-hidden
    object-cover w-full rounded-[27px];
    .parallax-img {
      @apply absolute object-cover w-full min-h-[600px]
      border-l-[0px] border-r-[1px] border-transparent
      md:min-h-[1000px];
      //transform: translateX(100px) !important
    }
    .multi-img {
      // @apply bg-ore_orange
      // min-w-[100vw];
      .img-prev {
        @apply right-[100%];
      }
      .img-curr {
      }
      .img-next {
        @apply left-[100%];
      }
    }
    .video-container {
      @apply relative h-full z-2;
    }

    video {
      @apply p-0 m-0 h-full w-full object-cover;
    }
  }

      .video-player {
      @apply absolute top-0 w-full h-full flex justify-center items-center z-1;
      .play {
        @apply transition-all h-[100px] w-[100px] bg-ore_green_dark/[70%] backdrop-blur rounded-full flex justify-center items-center
                md:h-[188px] md:w-[188px];
        &-icon {
          @apply scale-[65%];
        }
      }

      &.played {
        @apply hidden;
      }

      &:hover{
        @apply cursor-pointer;
        .play {
          @apply scale-110;
        }
      }
    }

    .vimeo-video {
      @apply relative z-2;

      .video-player{
        @apply pointer-events-none;

        &.clicked {
          @apply opacity-0;
        }
      }
    }
}
</style>
