<template>
  <div class="design-card-inner-container">
    <div class="preview" @mouseenter="hovered = true" @mouseleave="hovered = false">
      <img src="@/assets/img/show_more.svg" class="preview-button">
    </div>
    <Transition name="slide-fade" mode="out-in">
      <div v-if="hovered" class="preview-area" :class="!design.data['acf-product-options-design-image'] ? 'no-image' : ''">
        <img v-if="design.data['acf-product-options-design-image']" :src="design.data['acf-product-options-design-image'].url" class="preview-image">
        <div class="preview-description">{{design.data['acf-product-options-design-textarea']}}</div>
      </div>
    </Transition>
    <div class="design-card-content">
      <h2 class="design-title">{{design.label}}</h2>
      <div class="checkbox">
        <label class="label">
          <input  class="label__checkbox" v-model="checked" type="checkbox" />
          <div class="label__text">
            <div class="label__check">
              <img src="@/assets/img/check.svg" class="icon">
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from 'vue'
import { ProductState } from './state'
const { addDesignOption, removeDesignOption, designOptions } = ProductState()

const props = defineProps(['design'])
const checked = ref(false)
const hovered = ref(false)

onMounted(() => {
  if (designOptions.value.find(x => x.value === props.design.value)) {
    checked.value = true
  }
})

watch(checked, (next, prev) => {
  if (next) {
    addDesignOption(props.design)
  } else {
    removeDesignOption(props.design)
  }
})

// console.log(props.design)

</script>
<style lang="scss" scoped>
  .design-card-inner-container {
    @apply bg-white flex flex-row p-5 relative;
    &:not(:first-child) {
      @apply border-t border-t-ore_green_dark/20;
    }
    .preview {
      @apply relative;
      .preview-button {
      }
    }
    .preview-area {
        @apply absolute -left-80 -top-[200%] w-80 flex flex-col bg-white p-4 shadow-lg rounded-md transition-all duration-300 ease-in-out;
        &.no-image {
          @apply -top-[30%];
        }
        .preview-image {
          @apply mb-6;
        }
        .preview-description {

        }
      }
    .design-card-content {
      @apply pl-6 flex items-center w-full justify-between;
      .design-title {
        @apply text-[20px];
      }
      .checkbox {
        .label__checkbox {
          @apply hidden;
        }

        .label__check {
          @apply border-gray-300 rounded-full border border-solid
          bg-gray-200 w-7 h-7 cursor-pointer flex items-center content-center
          transition-all duration-300 ease-in-out;

          .icon {
            @apply opacity-0 transition-opacity duration-300 delay-100 ease-linear h-3 w-3 relative left-2;
          }

          &:hover {
            @apply border-2 border-solid border-gray-300;
          }
        }

        .label__checkbox:checked + .label__text .label__check {
          @apply shadow-ore_orange shadow-lg;
          animation: check .5s cubic-bezier(0.895, 0.030, 0.685, 0.220) forwards;

          .icon {
            @apply opacity-0 scale-0;
            animation: icon .3s cubic-bezier(1.000, 0.008, 0.565, 1.650) .1s 1 forwards;
          }
        }

        @keyframes icon {
          from {
            @apply opacity-0 scale-50;
          }
          to {
            @apply opacity-100 scale-100;
          }
        }

        @keyframes check {
          0% {
            @apply w-6 h-6 border-4;
          }
          10% {
            @apply w-6 h-6 border-4 opacity-10 bg-black/20;
          }
          12% {
            @apply w-6 h-6 border-0 opacity-40 bg-black/10;
          }
          50% {
            @apply w-7 h-7 border-0 opacity-60 bg-ore_orange;
          }
          100% {
            @apply w-7 h-7 border-0 opacity-100 bg-ore_orange;
          }
        }

      }
    }
  }
  .slide-fade-enter-active {
  transition: all 0.3s ease-out;
  }

  .slide-fade-leave-active {
    transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
  }

  .slide-fade-enter-from,
  .slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
  }
</style>
