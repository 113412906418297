<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <section class="cart-module">
    <div class="cart-inner-container">
      <FormKit
        type="form"
        id="formKit"
        #default="{ value, state: { valid } }"
        :plugins="[stepPlugin]"
        @submit="submitHandler"
        @submit-invalid="showErrors"
        :actions="false"
      >
        <!-- content -->
        <!-- cart -->
        <div v-show="cart.length > 0 || finishes.length > 0">
          <!-- stepper -->
          <div class="steps-container">
            <div class="previous">
              <FormKit v-if="activeIndex != 1"
                type="button"
                @click="setStep(-1); activeIndex = steps[activeStep].index, refreshScrollTrigger()"
                v-text="'Back'"
                href="#"
              />
              <FormKit v-else
                type="button"
                @click="returnBack()"
                v-text="'Add to quote'"
              />
            </div>
            <div class="number">
              <span>0{{activeIndex}}</span>-
              <span>03</span>
            </div>
            <ul class="steps">
              <li
                v-for="(step, stepName) in steps"
                :key="`stepName-${stepName}`"
                :class="['step', { 'has-errors': checkStepValidity(stepName) }]"
                @click="activeStep = stepName, activeIndex = step.index, refreshScrollTrigger()"
                :data-step-valid="step.valid && step.errorCount === 0"
                :data-step-active="activeStep === stepName"
              >
                <span
                  class="step-number"
                  v-text="`0${step.index}`"
                />
                {{ camel2title(stepName) }}
              </li>
            </ul>
            <div class="next">
              <div v-show="activeIndex != Object.keys(steps).length">
                <FormKit
                type="button"
                class="next"
                :disabled="activeStep == props.data.steps_names[props.data.steps_names.length-1].step_name"
                @click="activeIndex = setStep(1), activeIndex = steps[activeStep].index, refreshScrollTrigger()"
                v-text="'Next'"/>
              </div>
              <div class="flex items-center gap-2" v-show="activeIndex == Object.keys(steps).length">
                <Popper placement="bottom" :hover="true">
                <svg class="animate-pulse" xmlns="http://www.w3.org/2000/svg" width="20" height="20">
                  <path class="fill-ore_orange" d="M19.64 16.36 11.53 2.3A1.85 1.85 0 0 0 10 1.21 1.85 1.85 0 0 0 8.48 2.3L.36 16.36C-.48 17.81.21 19 1.88 19h16.24c1.67 0 2.36-1.19 1.52-2.64zM11 16H9v-2h2zm0-4H9V6h2z"/>
                </svg>
                <template #content>
                  <div class="flex flex-col w-80 bg-white shadow-xl p-4 rounded-xl">
                   <p class="text-sm text-ore_green_dark">There are validation errors on your form submission. Please review the fields on each step (you can click on a step to go back and forth) and make the necessary adjustments.</p>
                  </div>
                </template>
              </Popper>
                <FormKit
                  type="submit"
                  class="submit"
                  :label="labelSend"
                  :disabled="!valid || disabled"/>
              </div>
            </div>
          </div>
          <div class="main-container cart-container">
            <div v-show="activeStep == props.data.steps_names[0].step_name">
              <h2 class="text-3xl lg:text-[60px] mt-20 mb-10 text-ore_green font-medium">Products</h2>
              <CartCard  v-for="(item, index) of cart" :key="`${index}-cart-card`" :data="item" :index="index" />
              <div v-if="cart.length === 0">
                <p class="mb-6">No products added to your quote...</p>
                <Button
                  color="green"
                  lineHover="bg-green"
                  circleColor="circle-white"
                  :url="data.products_page.url"
                  :text="data.products_page.title"
                />
              </div>
              <h2 class="text-3xl lg:text-[60px] mt-20 mb-10 text-ore_green font-medium">Finish samples</h2>
              <div class="flex gap-5 flex-wrap">
                <CartFinishCard v-for="(finish, index) of finishes" :key="`${index}-cart-card`" :finish="finish.finish" :category="finish.finishId"  />
              </div>
              <div v-if="finishes.length === 0">
                <p class="mb-6">No finish samples added to your quote...</p>
                <Button
                  color="green"
                  lineHover="bg-green"
                  circleColor="circle-white"
                  :url="data.finish_samples_page.url"
                  :text="data.finish_samples_page.title"
                />
              </div>

            </div>
            <div class="form-body" v-if="isDataFormLoaded">
              <div class="step-content-container">
                <div
                  class="step-content"
                  v-for="(step, index) in props.data.steps_names"
                  :key="`step-content-${index}`"
                  v-show="activeStep === step.step_name">
                  <ul class="validation-errors" v-if="messages.length">
                    <li v-for="(message, indexMessage) in messages" :key="`message-${indexMessage}`">
                      {{ message }}
                    </li>
                  </ul>
                  <FormKit
                    :id="step.step_name"
                    type="group"
                    :name="step.step_name"
                    v-model="requestFormData"
                  >
                    <FormKitSchema :schema="formByPage[index]" :data="requestFormData"/>
                  </FormKit>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-show="cart.length === 0 && finishes.length === 0">
          <div class="empty-cart-container">

            <h1>{{data.empty_cart_message}}</h1>
            <div class="links">
              <a class='nav-links' :href="link.link.url" :target="link.link.target" v-for="(link, index) of data.empty_cart_links" :key="`${index}-link`">{{link.link.title}}</a>
            </div>
          </div>
          <div class="carousel-container">
            <h2 class="related-products-title" v-html="data.empty_cart_featured_products_title" />
          </div>
          <div class="carousel-container">
            <div v-for="(product, index) of data.empty_cart_products" :key="index" class="carousel-card" :class="index > 2 ? 'block md:hidden' : ''">
              <a @click="router.push(`/${product.post_type}/${product.post_title.replaceAll(' ', '-').toLowerCase()}`)" class="carousel-card-link cursor-pointer">
                <img :src="product.acf['acf-single-product-image'].sizes.large" alt="" class="product-carousel-image" />
                <p>{{product.post_title}}</p>
              </a>
            </div>
          </div>
        </div>
      </FormKit>
    </div>
  </section>
</template>
<script setup>
import { onBeforeMount, ref } from 'vue'
import Button from '@/components/utility/Button.vue'
import { useStore } from 'vuex'
import { cartState } from './Store.js'
import { finishState } from './finishStore.js'
import { useRouter } from 'vue-router'
import { reset } from '@formkit/core'
import { getValidationMessages } from '@formkit/validation'
import Axios from 'axios'
import camel2title from '../../../utils/utils'
import useSteps from '../../../utils/useSteps'
import CartCard from './CartCard.vue'
import CartFinishCard from './CartFinishCard.vue'
import Popper from 'vue3-popper'
import { useDecimalToInches } from '@/composables/useDecimalToInches.js'

const props = defineProps(['data'])
const { steps, visitedSteps, activeStep, setStep, stepPlugin } = useSteps()
const store = useStore()
const router = useRouter()
const form = ref({})
const formByPage = ref({})
const storeParams = ref({})
const requestFormData = ref({})
const messages = ref([])
const activeIndex = ref(1)
const isDataFormLoaded = ref(false)
const disabled = ref(false)
const labelSend = ref('Submit')
const quoteForm = store._state.data.quote_form
const { cart, clearCart } = cartState()
const { finishes, clearFinishes } = finishState()

const showErrors = (node) => {
  messages.value = []
  const validations = getValidationMessages(node)
  validations.forEach((inputMessages) => {
    messages.value = messages.value.concat(
      inputMessages.map((message) => message.value)
    )
  })
  console.log(messages.value)
}
const returnBack = () => {
  if (props.data.return_link.url) {
    const url = props.data.return_link.url.replace(store.state.site.url, '')
    router.push(url)
  } else {
    router.push('/')
  }
}
const postData = async (URL, body, node) => {
  try {
    const response = await Axios.post(URL, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        withCredentials: true
      }
    })
    disabled.value = false
    labelSend.value = 'Submit'
    messages.value = []
    if (response.status === 200 && response.data.is_valid) {
      node.clearErrors()
      reset('formKit')
      const path = props.data.thank_you_page_url.url.replace(store._state.data.site.url, '')
      router.push(path)
    }
  } catch (error) {
    disabled.value = false
    labelSend.value = 'Submit'
    node.setErrors(error.formErrors, error.fieldErrors)
    console.error(`There was an error submitting the form: ${error}`)
  }
}

const navigateThankYou = async () => {
  await router.push(props.data.thank_you_page_url.url.replace(store._state.data.site.url, ''))
  clearCart()
  clearFinishes()
}

// NEW POSTING METHOD
// Add Quote Products to Gravity forms
const addToGFQuote = (cart) => {
  const data = cart.value.map((x) => {
    // console.log(x)
    return {
      image: x.currentImage,
      product: x.product.title.rendered,
      finish: `${x.finishCategory} - ${x.finish['acf-single-product-options-finish-option'].label}`,
      size: x.size.attributes.attribute_size,
      options: x.designOptions.map((option) => ` ${option.label}`),
      length: useDecimalToInches(x.size.dimensions.length).toRegularString(),
      width: useDecimalToInches(x.size.dimensions.width).toRegularString(),
      height: useDecimalToInches(x.size.dimensions.height).toRegularString(),
      diameter: useDecimalToInches(x.size?.diameter).toRegularString() || '',
      quantity: x.quantity
    }
  })
  return data
}

const addFinishToGFQuote = (cart) => {
  const data = finishes.value.map((x) => {
    return {
      image: x.finish['acf-product-options-finish-image'].url,
      finish: x.finishId
    }
  })
  return data
}

const submitHandler = async (_data, node) => {
  disabled.value = true
  labelSend.value = 'Sending...'
  messages.value = []
  const curedForm = new FormData()
  const requestFormDataHelper = { ...requestFormData.value }
  if ('slots' in requestFormDataHelper) {
    delete requestFormDataHelper.slots
  }
  const keys = Object.keys(requestFormDataHelper)
  keys.forEach(element => {
    if (element === 'Finish request') {
      const formElement = form.value.fields.find((x) => x.label === 'Finish request')
      addFinishToGFQuote(cart).forEach((x) => {
        for (const prop in x) {
          curedForm.append(`input_${formElement.id}[]`, x[prop])
        }
      })
      return
    }
    if (element === 'Product list') {
      // console.log(form.value)
      const formElement = form.value.fields.find((x) => x.label === 'Product list')
      addToGFQuote(cart).forEach((x) => {
        for (const prop in x) {
          curedForm.append(`input_${formElement.id}[]`, x[prop])
        }
      })
      return
    }
    if (requestFormDataHelper[element] === undefined) {
      return
    }
    const fieldSettings = form.value.fields.find((x) => {
      const result = x.type === 'checkbox' ? element.replaceAll('_', ' ') : element
      return x.label === result
    })
    if (fieldSettings.type === 'address' || fieldSettings.type === 'name') {
      requestFormDataHelper[element].forEach((subElement, indexSubElement) => {
        curedForm.append(`input_${fieldSettings.inputs[indexSubElement].id.replace('.', '_')}`, subElement ?? '')
      })
    } else if (fieldSettings.type === 'checkbox') {
      curedForm.append(`input_${fieldSettings.id}_1`, requestFormDataHelper[element] ? fieldSettings.label : '')
    } else if (fieldSettings.type === 'date') {
      let result = ''
      if (requestFormDataHelper[element]) {
        const date = requestFormDataHelper[element]
        const [year, month, day] = date.split('-')
        result = [month, day, year].join('/')
      }
      curedForm.append(`input_${fieldSettings.id}`, result)
    } else {
      curedForm.append(`input_${fieldSettings.id}`, fieldSettings.type === 'fileupload' ? requestFormDataHelper[element].map(a => a.file)[0] ?? '' : requestFormDataHelper[element] ?? '')
    }
  })

  // await addToYITHQuote(cart)
  await postData(form.value.submitURL, curedForm, node)
  // await Axios.get(`${store._state.data.site.url}/wp-json/wp/v2/clear-quote`)
  navigateThankYou()
}

const checkStepValidity = (stepName) => {
  console.log(stepName)
  return (steps[stepName].errorCount > 0 || steps[stepName].blockingCount > 0) && visitedSteps.value.includes(stepName)
}

const selectOptions = (choices) => {
  if (choices) {
    return choices.map((choice) => {
      return choice.text
    })
  } else {
    return []
  }
}

const validationTypes = (required, type) => {
  const validation = []
  if (required) {
    validation.push('required')
  }
  if (type === 'email') {
    validation.push('email')
  }
  if (type === 'phone') {
    validation.push('number')
    validation.push('length:10,10')
  }
  if (type === 'zipcode') {
    validation.push('number')
    validation.push('length:5,5')
  }
  const finalValidation = validation.join('|')
  return finalValidation
}

const returnFormKiType = (type) => {
  switch (type) {
    case 'phone':
      return 'tel'
    case 'fileupload':
      return 'file'
    case 'address':
    case 'name':
    case 'checkbox':
      return 'list'
    default:
      return type
  }
}

const setFormByPage = () => {
  const schema = [[{
    $formkit: 'hidden',
    name: 'hidden',
    type: 'hidden'
  }]]
  form.value.fields.forEach((field, indexField) => {
    if (field.type === 'page') {
      return
    }
    const index = field.pageNumber
    const validations = validationTypes(field.isRequired, field.type)
    let fieldSettings = {
      $formkit: returnFormKiType(field.type),
      name: field.label,
      label: field.labelPlacement ? '' : field.label,
      placeholder: field.placeholder,
      validation: validations,
      outerClass: field.cssClass,
      validationVisibility: 'blur'
    }

    if (field.conditionalLogic && field.conditionalLogic?.rules.length > 0) {
      fieldSettings.if = `$${field.conditionalLogic.rules[0].value.replaceAll(' ', '_')} !== true`
    }

    if (field.type === 'select') {
      fieldSettings.options = selectOptions(field.choices)
    }
    if (field.type === 'textarea') {
      fieldSettings.rows = 8
    }
    if (field.type === 'fileupload') {
      fieldSettings.accept = field.allowedExtensions ?? ''
      /**
       * Formkit input file only supports uploading one single file
       */
      fieldSettings.multiple = false
    }
    if (field.type === 'address' || field.type === 'name') {
      const schema = []
      if (field.labelPlacement.length === 0) {
        schema.push({
          $el: 'div',
          attrs: {
            class: 'formkit-outer always-full'
          },
          children: [{
            $el: 'div',
            attrs: {
              class: 'formkit-wrapper'
            },
            children: [{
              $el: 'label',
              attrs: {
                class: 'formkit-label'
              },
              children: field.label || field.placeholder
            }]
          }]
        })
      }
      field.inputs.forEach(element => {
        schema.push({
          $formkit: element.isHidden ? 'hidden' : 'text',
          name: element.label,
          label: field.subLabelPlacement === 'hidden_label' ? '' : element.customLabel !== '' ? element.customLabel : element.label,
          placeholder: element.placeholder,
          validation: element.isHidden ? [] : element.label === 'ZIP / Postal Code' ? validationTypes(field.isRequired, 'zipcode') : validations,
          validationLabel: element.placeholder !== '' ? element.placeholder : element.customLabel !== '' ? element.customLabel : element.label,
          outerClass: 'half-width',
          autoComplete: element.placeholder
        })
      })
      fieldSettings.children = schema
    }

    if (field.type === 'checkbox') {
      fieldSettings = {}
      fieldSettings.$formkit = 'checkbox'
      fieldSettings.name = (field.choices[0].value).replaceAll(' ', '_')
      fieldSettings.label = field.choices[0].text
      fieldSettings.value = true
    }

    schema[index] ??= []
    schema[index].push(fieldSettings)
  })

  return schema
}

const getFormData = async () => {
  storeParams.value = {
    type: 'forms',
    id: quoteForm.gf_id,
    showLoading: true
  }
  await store.dispatch('getSingleById', storeParams.value)
  form.value = store.getters.singleById(storeParams.value)
  // console.log(form.value)
  formByPage.value = setFormByPage()
  isDataFormLoaded.value = true
}

const refreshScrollTrigger = () => {
  setTimeout(() => {
    // ScrollTrigger.refresh()
  }, 0)
}

onBeforeMount(() => {
  getFormData()
  refreshScrollTrigger()
})

</script>
<style lang="scss" scoped>
.cart-module {
  @apply wide-container bg-ore_mint_30 pt-48 pb-60 lg:px-14;
  .steps-container {
    @apply grid grid-cols-2 place-content-between lg:grid-cols-none lg:place-content-between lg:grid-flow-col gap-y-2 bg-ore_green text-ore_mint_30 font-FHOscar font-normal text-2xl leading-6 px-[22px] lg:px-7 py-[15px] lg:py-5 rounded-[20px] mb-10;

    .previous:deep() {
      @apply flex;

      .formkit-input{
        @apply flex flex-row justify-start items-center gap-8 text-[20px] leading-6;
        &::before {
          @apply w-5 transition-all duration-500;
          content: url('@/assets/img/back_arrow.svg');
        }
        &:hover{
          @apply scale-[1.025];
        }
      }

      .formkit-input[disabled]{
        @apply opacity-60;
      }
    }
    .number {
      @apply flex text-[20px] leading-6 justify-end items-center lg:hidden;

      span:last-child{
        @apply relative mr-10;
        &::after{
          @apply absolute rounded-[20px] w-[10px] h-[10px] bg-ore_orange -right-9 -top-2;
          content: '';
        }
      }
    }

    .steps {
      @apply w-full flex flex-col lg:flex-row items-start justify-center lg:items-center;

      .step {
        @apply flex flex-col text-2xl leading-6 font-FHOscar font-normal text-ore_mint_30 lg:border-r lg:border-ore_orange lg:px-[61px] cursor-pointer;
        .step-number {
          @apply hidden lg:flex items-center lg:relative font-FHOscar font-normal text-xl leading-6 text-ore_mint;

          &::after {
            @apply bg-ore_orange w-[10px] h-[10px] rounded-[10px] absolute left-8;
            content: "";
          }
        }
      }

      .step[data-step-active="false"]{
        @apply hidden lg:inline-flex opacity-60;
      }

      .step:last-child {
        @apply border-none;
      }
    }
    .next:deep(){
      @apply flex justify-end items-center;
      .formkit-input {
        @apply bg-ore_orange text-white rounded-3xl text-[20px] leading-6 py-2 px-10 transition-all duration-500;
        filter: drop-shadow(0px 0px 30px rgba(255, 76, 0, 0.5));
      }
      .formkit-input[disabled]{
        @apply opacity-60;
      }
      &:hover{
        @apply scale-[1.025];
      }
    }
  }
  .cart-container {
    .form-body:deep() {
      @apply flex w-full;
      .step-content-container {
        @apply w-full flex flex-col py-4 md:py-12 desktop:py-14;
        .step-content {
          @apply flex flex-col md:flex-row md:flex-wrap w-full;
          .validation-errors {
            @apply bg-red-200 text-red-500 rounded-2xl p-4 mt-2;
          }
          .formkit-outer{
            @apply flex flex-col w-full lg:basis-2/4 mb-4;
            &.half-width{
              @apply basis-2/4;
            }
            &:nth-of-type(even){
              @apply md:basis-[48%] mr-[2%];
            }
            &:nth-of-type(odd), &.always-full ~ .half-width:nth-of-type(even){
              @apply lg:basis-[48%] lg:mr-[2%];
            }

            &.always-full {
              @apply md:basis-full;
              .formkit-wrapper{
                @apply lg:w-[48%];
                .formkit-label {
                  @apply w-1/2 lg:w-full;
                }
              }
            }
            .formkit-wrapper {
              @apply w-full flex flex-col;
              .formkit-label{
                @apply text-ore_green font-FHOscar font-normal text-xl leading-10 mt-8 lg:mt-9 py-4 ml-4 lg:ml-0 border-t-2 border-ore_green w-1/2;
              }
              .formkit-inner{
                @apply flex flex-col w-full;
                  &:focus-visible, &:focus {
                  --tw-ring-shadow: none;
                  @apply border-none outline-none shadow-none;
                }

                .formkit-input {
                  @apply flex w-full border-none text-ore_green p-5 rounded-2xl font-FHOscar font-normal text-lg leading-10;

                  &:focus-visible, &:focus {
                    --tw-ring-shadow: none;
                    @apply border-none outline-none shadow-none;
                  }

                  &[type="date"]{
                    @apply cursor-pointer;
                  }

                  &[type="file"]{
                    @apply text-transparent flex items-center justify-center pl-2 leading-none;

                    &::-webkit-file-upload-button {
                      @apply invisible;
                    }

                    &::before {
                      content: "Attach File";
                      -webkit-user-select: none;
                      @apply flex items-center justify-center text-white bg-ore_orange rounded-[100px] outline-none whitespace-nowrap cursor-pointer text-xl lg:text-2xl py-1 px-8 lg:p-4 w-36 lg:w-72;
                    }
                  }
                }

                select {
                  @apply cursor-pointer;
                }

                .formkit-no-files{
                  @apply pl-2;
                }

                .formkit-file-list{
                  @apply flex flex-col lg:flex-row lg:flex-wrap gap-y-1 lg:gap-x-1 pl-2 lg:pl-0;

                  .formkit-file-item{
                    @apply flex flex-row-reverse items-center justify-center bg-ore_green text-white rounded-[100px] text-lg lg:text-xl leading-10 py-1 lg:py-4 px-6 lg:px-20 gap-x-8 relative;
                    .formkit-file-name {
                      @apply inline-block text-ellipsis max-w-full overflow-hidden whitespace-nowrap;
                    }
                  }
                  .formkit-file-remove{
                    @apply text-transparent py-0 w-10 h-10 absolute left-0 top-[6px] lg:top-3;
                    &::before{
                      content: url('@/assets/img/remove.svg');
                    }
                  }
                }

                .formkit-file-remove{
                  @apply ml-2 text-xl text-ore_green_dark py-4;
                }
              }
            }
            .formkit-messages{
              @apply text-red-500 text-base pl-3 pt-1;
            }

            .formkit-help{
              @apply text-ore_green text-[14px] pl-3 pt-1;
            }
          }
          .formkit-input[type="hidden"] ~ .formkit-outer:nth-of-type(odd):not([data-type="checkbox"]):not(.always-full){
            @apply lg:basis-2/4 lg:mr-0;
          }
          .formkit-outer[data-type="checkbox"] ~ .formkit-outer:nth-of-type(even){
            @apply md:basis-full md:mr-0 lg:basis-[48%] lg:mr-[2%];
          }
          .formkit-outer[data-type="checkbox"]{
            @apply md:basis-full;
            .formkit-wrapper {
              @apply flex-row justify-start items-center mt-4;
              .formkit-inner {
                @apply max-w-max;
                .formkit-input{
                  @apply max-w-max cursor-pointer;
                }
                input.formkit-input{
                  @apply rounded-[100px] bg-cover bg-transparent lg:mr-4;
                  background-image: url('@/assets/img/checkbox-unchecked.svg');
                }
                input.formkit-input:checked{
                  @apply bg-auto;
                  background-image: url('@/assets/img/checkbox-checked.svg');
                }
              }
              .formkit-label{
                @apply my-0 border-t-0 py-0 leading-6 cursor-pointer;
              }
            }
          }
          .formkit-outer[data-type="select"]{
            .formkit-wrapper{
              .formkit-inner{
                select.formkit-input {
                  @apply bg-auto;
                  background-image: url('@/assets/img/down-circle-arrow-orange.svg');
                }
              }
            }
          }
        }
      }
    }
  }
  .empty-cart-container {
    @apply h-[600px] rounded-3xl p-10 md:p-20 bg-white flex flex-col justify-center relative;
    h1 {
      @apply text-3xl leading-tight lg:text-[120px] text-ore_green mb-32;
    }

    .links {
      @apply flex flex-col md:flex-row;
      .nav-links {
        @apply text-[24px] text-ore_green relative mr-16 w-max underline;
        &:not(:first-child) {
          @apply ml-0 md:ml-12;
        }
        &::before {
          @apply content-[''] absolute border border-solid border-ore_green rounded-full h-2 w-2 -right-8 top-4 pointer-events-none;
        }
        &:not(:last-child)::after {
          @apply content-none md:content-[''] absolute border-r border-solid border-ore_orange h-16 w-1 -right-20 -top-3 pointer-events-none;
        }
      }
    }
  }

  .carousel-container {
      @apply flex flex-row py-5 gap-5 flex-wrap md:flex-nowrap;
      .related-products-title {
        @apply text-ore_orange text-[40px] my-10;
      }
      .carousel-card {
        @apply w-[47%] sm:w-[48%] md:w-4/12 min-h-[200px] sm:min-h-[285px] lg:min-h-[485px] overflow-hidden relative;
        &::before {
          @apply bg-white rounded-2xl content-[""] overflow-hidden absolute w-full h-full z-1 top-0 transition-transform duration-300 ease-in-out;
        }
        &:hover {
          .product-carousel-image {
            @apply transform scale-110 transition-transform duration-300 ease-in-out;
          }
          &:before {
            @apply transform scale-95 transition-transform duration-300 ease-in-out;
          }

          p {
            @apply transform translate-x-3 -translate-y-3 transition-transform duration-300 ease-in-out;
          }
        }
        .carousel-card-link {
          @apply z-2 flex flex-col items-center justify-between p-8 h-full;
          .product-carousel-image {
            @apply object-contain w-[525px] transition-transform duration-300 ease-in-out z-2 rounded-full;
          }
          p {
            @apply text-center self-start z-2 transition-transform duration-300 ease-in-out;
          }
        }
      }
    }
}
</style>
