<template>
  <section class="fuse">
    <div class=" container">
      <img
        class="big-fuse"
        src="@/assets/img/FuSE.svg"
        data-scroll
        data-scroll-direction="vertical"
        data-speed="0.9"
      />
      <div class="fuse-mid">
        <div class="fuse-mid-img-container">
          <img class="fuse-mid-img-container-img object-center" :src="data.img" />
        </div>
        <div class="fuse-mid-content">
          <h2 class="fuse-mid-content-title">{{ data.small_title }}</h2>
          <div class="fuse-mid-content-text" v-html="data.main_header" />
          <Button
            :url="data.cta.url"
            :text="data.cta.title"
            color="white"
            lineHover="bg-orange"
            circleColor="circle-white"
          />
        </div>
      </div>
      <div class="fuse-btm">
        <div class="fuse-btm-decoration">
          <img
            class="fuse-btm-decoration-image"
            src="@/assets/img/three_circles_decoration.svg"
          />
        </div>
        <div class="fuse-btm-content">
          <h3 class="fuse-btm-content-title">{{ data.content_title }}</h3>
          <div class="fuse-btm-content-text" v-html="data.content"></div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import Button from '@/components/utility/Button.vue'
import { onMounted, onBeforeUnmount } from 'vue'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
const animateDecoration = () => {
  gsap.to('.fuse-btm-decoration-image', {
    rotate: 180 * 3,
    scrollTrigger: {
      id: 'fuse-anim',
      trigger: '.fuse',
      start: 'top top',
      end: 'bottom top',
      scrub: 0.5
    }
  })
}
defineProps(['data'])
onMounted(() => {
  animateDecoration()
  gsap.set('.fuse-mid-img-container-img', { scale: 1.3 })
  gsap.fromTo('.fuse-mid-img-container-img', { y: -200 }, {
    scrollTrigger: {
      trigger: '.fuse-mid-img-container-img',
      id: 'ukiyoWrapperFuse',
      start: 'top-=50% center',
      end: 'bottom+=70% center',
      scrub: true,
      markers: false
    },
    y: 50
  })
  // ScrollTrigger.refresh()
})

onBeforeUnmount(() => {
  const ukiyoWrapperFuse = ScrollTrigger.getById('ukiyoWrapperFuse')
  if (ukiyoWrapperFuse) ukiyoWrapperFuse.kill()

  const fuseAnim = ScrollTrigger.getById('fuse-anim')
  if (fuseAnim) fuseAnim.kill()
})
</script>

<style lang="scss" scoped>
.fuse {
    @apply relative z-auto;
    &::before {
        @apply bg-ore_orange;
        content: "";
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
    }

  .container {
    @apply z-1 relative w-full max-w-screen-xl
    mx-auto px-[10px] py-[50px]
    flex flex-col items-center
    md:px-[60px] md:pb-[120px]
    xl:pt-[135px] xl:pb-[200px] xl:px-0;

    .big-fuse {
      @apply px-[15px] w-full max-w-[1192px] max-h-[377px] mb-[50px]
      md:px-0 md:mb-[100px]
      xl:mb-[150px];
    }
    .fuse-mid {
      @apply flex flex-col-reverse mb-[75px] gap-[62px]
      md:mb-[150px]
      lg:px-[14px]
      xl:gap-[165px]
      sm:flex-row;
      .fuse-mid-img-container {
        @apply overflow-hidden rounded-2xl;
        &-img {
          @apply rounded-[27px] object-cover relative
          min-h-[400px]
          sm:min-h-[300px] sm:max-w-[305px] sm:max-h-[300px]
          md:w-[300px] md:h-[300px] md:max-w-none md:max-h-full
          xl:w-[476px] xl:h-[485px];
        }
      }

      &-content {
        @apply px-[15px] md:flex basis-full lg:basis-7/12
        md:px-0 md:flex-col md:justify-center md:mt-5;
        &-title {
          @apply mb-9
          text-[20px] leading-none mt-10
          sm:mb-11
          md:text-[30px] md:leading-[30px] md:mt-0;
        }
        &-text {
          @apply text-[30px] leading-[30px] mb-10
          md:mb-5
          lg:text-[70px] lg:leading-[70px] lg:mb-10;
        }
        .button {
          @apply pl-0
          ;
        }
      }
    }
    .fuse-btm {
      @apply px-[15px] mb-12 w-full relative flex flex-col items-center gap-[100px] text-[20px] leading-none
      md:px-0 md:flex-row md:gap-[40px]
      lg:px-[14px]
      lg:justify-between
      xl:justify-start
      xl:gap-[380px] xl:text-[30px];
      &-decoration {
        @apply absolute right-[15px] bottom-2 lg:mx-auto
        md:relative md:pl-[30px] md:-mt-9
        md:self-center
        lg:self-start lg:mt-2
        xl:pl-[200px];
        img {
          @apply min-w-[59px] min-h-[55px];
        }
      }
      &-content {
        @apply flex flex-col gap-[30px]
        md:gap-[40px]
        lg:w-[calc(100%_-_360px)]
        ;

        &-title{
          @apply text-[24px] leading-none md:text-[20px] lg:text-[30px];
        }
        &-text {
          @apply flex flex-col
          gap-[70px]
          md:flex-row md:gap-8 lg:gap-[30px] lg:justify-between;
           :deep(p) {
            @apply md:flex-1 lg:max-w-[320px] md:w-6/12 lg:w-[calc(50%_-_15px)];
          }

          :deep(ul) {
            @apply font-medium text-[24px] pr-16 leading-none md:w-6/12 md:pr-0 lg:w-[calc(50%_-_15px)];

            li{
              @apply relative pl-6 md:pl-8;

              &:before{
                 @apply content-['→'] w-auto h-auto block absolute left-0;
              }

              &.cross {
                @apply relative ml-6 leading-none my-1 text-2xl md:pl-7;

                &:before{
                  @apply content-['+'] md:left-[6px];
                }

                +.cross {
                  @apply leading-none;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
