import { ref, computed, onUpdated } from 'vue'

const product = ref(null)
const currentImage = ref(null)
const specsPDF = ref(null)
const finish = ref(null)
const finishCategory = ref(null)
const quantity = ref(1)
const size = ref(null)
const designOptions = ref([])

export const ProductState = () => {
  const setProduct = data => { product.value = data }
  const setCurrentImage = image => { currentImage.value = image }
  const setSpecsPDF = link => { specsPDF.value = link }
  const incrementQuantity = () => { if (quantity.value <= 98) quantity.value++ }
  const decrementQuantity = () => { if (quantity.value >= 2) quantity.value-- }
  const setFinish = data => { finish.value = data }
  const setFinishCategory = data => { finishCategory.value = data }
  const setSize = data => { size.value = data }
  const addDesignOption = option => { if (!designOptions.value.find(x => x.value === option.value)) { designOptions.value = [...designOptions.value, option] } }
  const removeDesignOption = option => { designOptions.value = designOptions.value.filter(x => x.value !== option.value) }
  const allFieldsFilled = computed(() => {
    if (!product.value) return false
    if (!currentImage.value) return false
    if (!finish.value) return false
    if (!quantity.value) return false
    if (!size.value) return false
    if (!size.value.diameter) {
      if (!size.value.dimensions.length) return false
      if (!size.value.dimensions.width) return false
      if (!size.value.dimensions.height) return false
    }
    if (size.value.diameter) {
      if (size.value.diameter === 0) return false
      if (!size.value.dimensions.height) return false
    }
    return true
  })
  const clearState = () => {
    product.value = null
    currentImage.value = null
    finish.value = null
    specsPDF.value = null
    quantity.value = 1
    size.value = null
    designOptions.value = []
  }

  // onUpdated(() => {
  //   console.log({
  //     product: product.value,
  //     currentImage: currentImage.value,
  //     finish: finish.value,
  //     specsPDF: specsPDF.value,
  //     quantity: quantity.value,
  //     size: size.value,
  //     designOptions: designOptions.value,
  //     allFieldsFilled: allFieldsFilled.value
  //   })
  // })

  return {
    product,
    currentImage,
    finish,
    finishCategory,
    specsPDF,
    quantity,
    size,
    designOptions,
    allFieldsFilled,
    setProduct,
    setCurrentImage,
    setSpecsPDF,
    incrementQuantity,
    decrementQuantity,
    setFinish,
    setFinishCategory,
    setSize,
    addDesignOption,
    removeDesignOption,
    clearState
  }
}
