<template>
  <div class="drawer-menu" :class="{ active: props.active }">
    <div class="green-background" />
    <div class="green-section">
      <ul>
        <li v-for="(item, index) in menus" :key="index">
          <router-link
            :to="$root.convertToRelative(item.url)"
            :title="item.content"
            :target="item.target ? item.target : '_self'"
            :class="item.class"
            @click="buttonClick"
            exact
            ><span class="text">{{ item.content }}</span
            ><span class="number">{{
              index < 10 ? `0${index + 1}` : index
            }}</span>
          </router-link>
          <div class="dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </li>
      </ul>
      <div class="addresses">
        <div class="address left" v-html="site.site_header_left_contact" />
        <div class="address right" v-html="site.site_header_right_contact" />
      </div>
    </div>

    <div class="video-section">
      <div class="tagline">
        <!-- <div class="tag" v-html="site.site_header_bottom_tagline" /> -->
        <img
          src="@/assets/img/white_header_refined.svg"
          alt="Refined environment"
        />
        <div class="line" />
      </div>

      <video autoplay="autoplay" muted="muted" loop="loop" class="hero-video">
        <source :src="site.header_background_video" type="video/mp4" />
      </video>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, onBeforeMount, watch, ref } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const props = defineProps(['primaryMenu', 'secondaryMenu', 'active'])
const site = store.getters.acfOptions()
const emit = defineEmits(['updated-state'])

function buttonClick () {
  emit('updated-state')
}

const deviceWidth = ref(0)
const menus = ref([])

watch(deviceWidth, () => {
  menus.value = []
  if (deviceWidth.value < 1024) {
    menus.value.push(...props.primaryMenu, ...props.secondaryMenu)
  } else {
    menus.value.push(...props.secondaryMenu)
  }
})

onBeforeMount(() => {
  deviceWidth.value = window.innerWidth
})
onMounted(() => {
  window.addEventListener('resize', () => {
    deviceWidth.value = window.innerWidth
  })
})
onUnmounted(() => {
  window.removeEventListener('resize', () => {
    deviceWidth.value = window.innerWidth
  })
})
</script>

<style lang="scss" scoped>
.drawer-menu {
  @apply h-[100vh] z-9 fixed top-0 right-0 left-0 pointer-events-none ease-out overflow-hidden;

  &:before {
    @apply content-[''] absolute right-0 bottom-0 left-0 top-0 bg-black/[0.8] opacity-0 ease-linear duration-1000;
  }

  .green-background {
    @apply absolute right-0 bottom-0 top-0 left-0 bg-ore_mint lg:right-[calc(60%)] lg:w-[60%] -translate-y-full delay-[220ms] duration-1000 ease-[cubic-bezier(0,0.6,0.25,1)];
  }

  .green-section {
    @apply wide-container pt-[160px] lg:pt-[160px] lg:pb-[90px] xl:pt-[170px] 2xl:pt-[210px] lg:-translate-y-full delay-[220ms] duration-1000 ease-[cubic-bezier(0,0.6,0.25,1)];

    ul {
      @apply relative h-[calc(100vh_-_310px)] md:h-[calc(100vh_-_460px)] overflow-y-auto lg:h-[calc(100vh_-_450px)] lg:w-[60%] xl:pl-[100px] 2xl:h-[calc(100vh_-_490px)] opacity-0 delay-150 duration-300;

      &::-webkit-scrollbar {
        @apply w-[2px] lg:w-[4px];
      }

      /* Track */
      &::-webkit-scrollbar-track {
        @apply bg-ore_green_dark/[0.1];
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        @apply bg-ore_orange;
      }

      li {
        @apply relative border-b border-b-ore_green_dark/[0.1] pr-[5px] lg:pr-[20px] xl:pr-[50px] overflow-hidden duration-700 delay-[350ms];

        a {
          @apply text-[30px] leading-none pt-[18px] pb-[8px] font-medium flex justify-between relative lg:text-[50px] lg:pt-[13px] lg:pb-[15px] transform translate-y-[-100%] transition-transform duration-700 ease-in;

          &:before {
            @apply content-[''] absolute right-0 bottom-[2px] h-[2px] bg-ore_orange w-[46px] lg:w-[70px] transition-opacity duration-300 opacity-0;
          }

          span {
            &.text {
              @apply transition-transform duration-200 ease-in;
            }
            &.number {
              @apply font-light text-[24px] tracking-[1.2px] pl-[25px] lg:text-[30px] lg:tracking-[2.4px] self-end lg:pb-0 transition-all duration-300 opacity-0 lg:opacity-100;
            }
          }

          &.router-link-active,
          &.router-link-exact-active {
            &:before {
              @apply opacity-100;
            }

            span {
              &.number {
                @apply opacity-100 lg:text-[40px];
              }
            }
          }

          &:hover {
            &::before {
              // @apply lg:opacity-100;
            }

            // span {
            //   &.number {
            //     @apply lg:opacity-100 lg:text-[40px];
            //   }
            // }
          }

          &.person-icon {
            span {
              &.text {
                @apply relative;

                &:before {
                  @apply content-[''] absolute top-0 right-[-30px] w-[18px] h-[18px] bg-[url('/src/assets/img/person-icon.svg')] bg-cover lg:w-[30px] lg:h-[30px] lg:right-[-50px];
                }
              }
            }
          }
        }

        @for $j from 1 through 10 {
          &:nth-child(#{$j}) {
            a {
              $size: calc((300 - (50 * $j)));
              transition-delay: $size + ms;
            }
          }
        }

        .dots {
          @apply absolute left-0 w-[24px] h-[24px] top-[calc(50%_-_8px)] origin-center opacity-0 md:top-[calc(50%_-_12px)];
          animation-duration: 0.2s;
          animation-name: dotsBackwards;
          animation-fill-mode: forwards;

          span {
            @apply w-2 h-2 block bg-ore_orange rounded-full origin-center;
            transition: 0.2s ease transform;

            &:nth-child(2) {
              @apply translate-x-4 -translate-y-2;
            }
            &:nth-child(3) {
              @apply translate-x-2;
            }
          }
        }

        &:hover {
          .dots {
            animation-duration: 0.2s;
            animation-name: dots;
            animation-fill-mode: forwards;
            animation-delay: 0.2ms;

            span {
              transition: 0.3s ease all;

              &:nth-child(1) {
                @apply translate-x-[7px] translate-y-[5px];
              }
              &:nth-child(2) {
                @apply translate-x-[6px] -translate-y-[3px];
              }
              &:nth-child(3) {
                @apply translate-x-[6px] -translate-y-[11px];
              }
            }
          }

          a {
            span {
              &.text {
                transform: translateX(40px);
              }
            }
          }
        }
      }
    }

    .addresses {
      @apply relative flex flex-row justify-between h-[150px] lg:justify-start lg:w-[60%] lg:mt-[50px] xl:pl-[100px] xl:mt-[70px] xl:mb-[30px] opacity-0 transition-opacity;

      :deep(.address) {
        @apply w-1/2 pt-10 lg:border-l lg:border-ore_green_dark/[0.1] lg:w-auto;

        + .address {
          @apply lg:ml-[90px];
        }

        p {
          @apply relative text-[18px] leading-none lg:text-[24px];

          a {
            @apply relative inline-block underline transition-all;

            &:hover {
              @apply text-ore_orange;
            }
          }
        }
      }
    }

    @media (min-height: 1000px) {
      @apply lg:pt-[120px] lg:pb-[140px] xl:pb-[100px];

      ul {
        @apply lg:h-[calc(100vh_-_460px)] xl:h-[calc(100vh_-_490px)] 2xl:xl:h-[calc(100vh_-_530px)];
      }

      .addresses {
        @apply xl:mb-[100px];
      }
    }

    @media (min-width: 1600px) and (min-height: 1000px) {
      @apply pt-[300px];

      ul {
        @apply 2xl:h-[calc(100vh_-_670px)];
      }

      .addresses {
        @apply 2xl:mb-[160px] 2xl:mt-[70px];
      }
    }
  }

  .video-section {
    @apply min-h-[150px] hidden w-full relative md:flex flex-col justify-start py-5 opacity-0 lg:absolute lg:right-0 lg:left-[calc(60%)] lg:w-[40%] lg:h-full lg:top-0 lg:bottom-0 lg:justify-end lg:pb-[90px] lg:opacity-100 lg:-translate-y-full xl:pb-[60px] duration-[540ms] ease-[cubic-bezier(0,0.6,0.25,1)] delay-[220ms];

    .tagline {
      @apply relative z-1 wide-container flex-1 pt-5 lg:flex-initial h-[150px] lg:max-w-[600px] lg:ml-0 lg:pt-10 xl:pl-[122px] opacity-0 transition-opacity;

      &:before {
        @apply content-[''] absolute bottom-0 top-0 left-6 w-[1px] h-full bg-white/[0.4] md:left-14 xl:left-[122px];
      }

      img {
        @apply max-w-[137px] md:max-w-[210px];
      }

      :deep(.tag) {
        p {
          @apply text-white text-[26px] leading-[16px] tracking-[-1.2px] lg:text-[40px] lg:leading-[24px] lg:tracking-[-0.8px];

          span {
            @apply tracking-[-0.5px] lg:tracking-[-0.3px];
          }
        }
      }

      .line {
        @apply w-[44px] h-[4px] bg-white mt-3 lg:mt-6;
      }
    }

    video {
      @apply absolute w-full h-full top-0 left-0 object-cover z-0;
    }

    @media (min-height: 1000px) {
      @apply lg:pb-[140px] xl:pb-[100px];
    }

    @media (min-width: 1600px) and (min-height: 1000px) {
      @apply 2xl:pb-[160px];
    }
  }

  &.active {
    @apply pointer-events-auto opacity-100;

    &:before {
      @apply opacity-100;
    }

    .green-background {
      @apply translate-y-0;
    }

    .green-section {
      @apply translate-y-0;

      ul {
        @apply delay-[600ms] opacity-100;

        li {
          @apply delay-[600ms] ease-[cubic-bezier(0,0.48,0.51,1)];

          a {
            @apply transform translate-y-[0] transition-transform duration-300 ease-[cubic-bezier(0,0.48,0.51,1)];
          }

          @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
              a {
                transition-delay: calc((25ms * $i) + 0.6s);
                // transition-delay: calc(0.125s * $i);
              }
            }
          }
        }
      }

      .addresses {
        @apply opacity-100 transition-opacity duration-75 ease-in delay-[800ms];
      }
    }

    .video-section {
      @apply opacity-100 lg:translate-y-0 duration-[640ms] ease-[cubic-bezier(0,0.6,0.25,1)];

      .tagline {
        @apply opacity-100 transition-opacity duration-300 delay-[300ms];
      }
    }
  }
}
@keyframes dots {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 0.5;
    transform: rotate(0deg);
  }
  20% {
    opacity: 1;
  }
  100% {
    transform: rotate(-90deg);
    opacity: 1;
  }
}

@keyframes dotsBackwards {
  0% {
    transform: rotate(-90deg);
    opacity: 1;
  }
  10% {
    // opacity: 0.5;
  }
  20% {
    opacity: 0;
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    transform: rotate(0deg);
  }
}
</style>
