<template>
  <section class="finish-sample-picker">
    <div class="banner">
      <div class="text">
        <div
          class="title"
          v-html="`<p>${data.top_banner.banner_title}</p>`"
        />
        <div
          v-html="`<p>${data.top_banner.banner_content}</p>`"
        />
      </div>

      <div class="button-container">
        <Button
          color="mint-30"
          lineHover="bg-green"
          circleColor="circle-white"
          :url="data.top_banner.banner_link.url"
          :text="data.top_banner.banner_link.title"
        />
      </div>
    </div>
    <div class="section-title">
      <div class="main-line">
        <h1 class="section-title-description-header">{{ data.top_area.main_title }}</h1>
        <div class="breadcrumbs">
          <a href="/">Home</a>
          <p>Finishes</p>
        </div>
        <img class="arrow" src="@/assets/img/arrow_down.svg" />
      </div>
      <p class="section-title-description" v-html="data.top_area.main_description" />
    </div>
    <div class="quantity-selector-inner">
      <div class="add-to-cart-button" :class="totalFinishes > 0 ? 'ready' : ''" @click="totalFinishes > 0 ? goToCart() : ''">
        Go to Quote
      </div>
      <div class="quantity-selector">
        <div class="quantity">{{totalFinishes.toLocaleString('en-US', { minimumIntegerDigits: 2 })}}</div>
      </div>
    </div>
    <div class="finish-categories" v-for="(category, index) of data.finish_category" :key="`finish-category-${index}`">
      <h2 class="category-title">{{ category.title }}</h2>
      <p class="category-description" v-if="category.description" v-html="category.description" />
      <div class="finish-list">
        <FinishPickerCard v-for="(finish, fIndex) of category.finishes" :key="`finish-card-${fIndex}`" :finish="finish" :category="category.title" />
      </div>
    </div>
  </section>
</template>
<script setup>
import { useRouter } from 'vue-router'
import { onMounted, computed } from 'vue'
import FinishPickerCard from './FinishPickerCard.vue'
import Button from '@/components/utility/Button.vue'
import { finishState } from '@/components/Cart/finishStore.js'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useStore } from 'vuex'

const store = useStore()
defineProps(['data'])
const router = useRouter()
const cartPage = computed(() => {
  const cartPage = store.state.acf_options.global_quote_page.replace(store._state.data.site.url, '')
  return cartPage
})

const { totalFinishes } = finishState()
gsap.registerPlugin(ScrollTrigger)

const goToCart = async () => {
  await router.push(cartPage.value)
}

const pinning = () => {
  gsap.set('.quantity-selector-inner', {
    opacity: 0
  })

  gsap.to('.quantity-selector-inner', {
    opacity: 1,
    scrollTrigger: {
      id: 'finish-anim',
      trigger: '.finish-categories',
      start: 'top center',
      end: 'top+=500 center',
      scrub: 0.5,
      markers: false
    }
  })

  ScrollTrigger.create({
    id: 'finish-pinning-anim',
    trigger: '.finish-categories',
    start: 'top-=150 center+=30%',
    end: 'top-=100 bottom-=5%',
    pin: '.quantity-selector-inner',
    pinSpacing: false,
    endTrigger: '#oreFooter',
    markers: false
  })
}

onMounted(() => {
  pinning()
})

</script>
<style lang="scss" scoped>
  .finish-sample-picker {
    @apply wide-container py-36 flex flex-col;
    .section-title {
      @apply mb-[60px] lg:mb-40;
      .main-line {
        @apply flex justify-between;
        .breadcrumbs {
          @apply hidden lg:flex items-center;
          a, p {
            @apply text-[24px] px-6 relative;
            &:after:not(:last-child) {
              @apply content-[''] w-[1px] h-full right-0 absolute bg-ore_orange;
            }
          }
        }
        .arrow {
          @apply hidden lg:block;
        }
      }
      .section-title-description-header {
        @apply text-[50px] lg:text-[128px] leading-tight font-FHOscar mb-10;
      }
      .section-title-description {
        @apply font-FHOscar text-[28px] lg:text-[40px] leading-tight lg:leading-[40px] ml-0 xl:ml-44 w-full xl:w-[44%];
      }
    }
    .banner {
    @apply relative bg-ore_green max-w-[1390px] mx-auto rounded-[27px] p-8 mb-8 md:flex md:justify-between md:items-center md:gap-[50px] lg:gap-[220px] lg:pl-12 lg:pr-[90px] lg:py-8 xl:justify-between xl:mb-[120px];

    :deep(.text) {
      @apply relative mb-4 max-w-[315px] ml-0 md:flex md:justify-start md:max-w-[490px] md:items-center md:m-0 lg:max-w-[590px];

      p {
        @apply text-ore_mint_30 mb-2 text-[20px] leading-none md:px-[30px] md:border-l-ore_orange md:border-l-[1px] md:leading-[24px];
      }

      .title {
        @apply md:flex-[1_0_auto] md:pl-0 md:max-w-[220px];

        p {
          @apply leading-none md:text-[30px] md:border-none;
        }
      }
    }

    .button-container {
      @apply md:flex-[1_0_auto] md:flex md:justify-end;

      .button {
        @apply md:m-0 md:p-0 md:inline-block;
      }
    }
  }
  .finish-categories {
    @apply mb-24;
    .category-title {
      @apply text-[40px] mb-3 text-ore_orange;
    }
    .category-description {
      @apply w-full xl:w-5/12 mb-10 leading-normal text-ore_orange;
    }
    .finish-list {
      @apply flex flex-wrap gap-5;
    }
  }

  .quantity-selector-inner {
    @apply flex bg-white rounded-lg mt-3 p-4 w-full lg:w-4/12 items-center justify-around mx-auto z-50;
    .add-to-cart-button {
      @apply w-7/12 text-center text-white text-[16px] xl:text-[24px] bg-[#DEE3DF] rounded-full px-6 py-2 pointer-events-none transition-all duration-300 ease-in-out;
      &.ready {
        @apply transition-all duration-300 ease-in-out bg-ore_orange shadow-[1px_0px_15px_0px_rgba(255,76,0,0.75)] cursor-pointer pointer-events-auto;
      }
    }
    .quantity-selector {
      @apply flex w-auto relative right-0;
      .quantity {
        @apply text-[20px] xl:text-[30px] px-4 md:px-8;
      }
    }
  }

  }
</style>
