<template>
  <div class="intro" ref="intro">
    <div class="intro-container">
      <div ref="animation" class="intro-animation"></div>
    </div>
  </div>
</template>

<script>
import LottieWeb from 'lottie-web'
import introJson from '@/assets/lottie/intro.json'
export default {
  data () {
    return {
      finished: false
    }
  },
  components: {
    // LottieWeb
  },
  methods: {
    completeAnimation () {
      if (this.finished) {
        this.$root.introComplete()
      } else {
        setTimeout(() => {
          this.completeAnimation()
        }, 100)
      }
    }
  },
  mounted () {
    const _this = this
    window.addEventListener('load', () => {
      _this.finished = true
    })
    document.documentElement.classList.add('no-overflow')
    const { animation } = this.$refs
    const anim = LottieWeb.loadAnimation({
      container: animation,
      renderer: 'svg',
      autoplay: true,
      loop: false,
      animationData: introJson
    })

    anim.onComplete = () => {
      document.documentElement.classList.remove('no-overflow')
      _this.completeAnimation()
    }
  },
  destroyed () {
    LottieWeb.destroy()
  }
}
</script>

<style lang="scss">
.intro {
  @apply fixed top-0 left-0 w-full h-full z-[60] bg-gradient-to-tr from-pageLoadingfrom to-pageLoadingTo backdrop-blur-xl;
  .intro-container {
    @apply left-0 w-[100vw] h-[100vh] flex items-center justify-center bg-cover bg-no-repeat relative top-[-50px];
    .intro-animation {
      @apply w-[60%] h-[60%] md:w-[300px] md:h-[300px] m-auto;
    }
  }
}
</style>
