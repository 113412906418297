<template>
  <div class="design-picker" v-if="product">
    <div class="design-type">
      <h2>Design Option</h2>
      <div class="design-card-container">
        <DesingSelectorCheck v-for="(design, idx) of product.acf['acf-single-product-options-design']" :design="design" :key="`design-${idx}`" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ProductState } from './state'
import DesingSelectorCheck from './DesingSelectorCheck.vue'
const { product } = ProductState()

</script>
<style lang="scss" scoped>
  .design-picker {
    @apply ml-0 lg:ml-28;
    .design-type {
      @apply pt-12 pb-12;
      h2 {
        @apply text-[20px] text-ore_green_dark border-t-2 border-t-ore_green_dark w-4/12 pt-3 mb-10;
      }
      .design-card-container {
        @apply grid grid-cols-1;

      }
    }
  }
</style>
