import { ref, computed, onBeforeMount } from 'vue'

const finishes = ref([])

export const finishState = () => {
  const addToFinishes = data => {
    const newData = [...finishes.value, data]
    finishes.value = newData
    window.localStorage.setItem('ore_finishes', JSON.stringify(finishes.value))
  }

  const removeFromFinishes = finishId => {
    const newFinishes = finishes.value.filter((x) => x.finishId !== finishId)
    finishes.value = newFinishes
    window.localStorage.setItem('ore_finishes', JSON.stringify(finishes.value))
  }

  const clearFinishes = () => {
    finishes.value = []
    window.localStorage.setItem('ore_finishes', JSON.stringify(finishes.value))
  }

  const totalFinishes = computed(() => finishes.value.length)

  onBeforeMount(() => {
    if (window.localStorage.getItem('ore_finishes')) {
      finishes.value = JSON.parse(window.localStorage.getItem('ore_finishes'))
    }
  })

  return {
    finishes,
    totalFinishes,
    addToFinishes,
    removeFromFinishes,
    clearFinishes
  }
}
