<template>
  <section class="custom-hero-wrapper" id="hero">
    <div
      class="custom-hero"
      :class="`section-pset-${props.data.background_image_pset}`"
    >
      <div
        v-if="
          props.data.background_image_pset != 1 &&
          props.data.background_image_pset != 5
        "
        class="background"
        :class="`bg-${props.data.background_image_pset}`"
      >
        <img src="~@/assets/img/hero-donut.svg" alt="Background" />
      </div>
      <div
        class="top-content"
        :class="
          props.data.background_image_pset == 1 ||
          props.data.background_image_pset == 5
            ? 'development'
            : ''
        "
      >
        <div
          class="rectangle top"
          v-if="
            props.data.background_image_pset == 1 ||
            props.data.background_image_pset == 5
          "
        ></div>
        <div
          class="rectangle middle"
          v-if="
            props.data.background_image_pset == 1 ||
            props.data.background_image_pset == 5
          "
        ></div>
        <div
          class="rectangle bottom"
          v-if="
            props.data.background_image_pset == 1 ||
            props.data.background_image_pset == 5
          "
        ></div>

        <div class="container">
          <div class="text">
            <div
              class="subheader"
              v-if="props.data.custom_hero_subheader"
              v-html="props.data.custom_hero_subheader"
            />
            <h1 v-html="props.data.custom_hero_title" class="title" />
            <Button
              class="mobile-btn"
              v-if="props.data.custom_hero_cta_top"
              :url="props.data.custom_hero_cta_top.url"
              :text="buttonText(props.data.custom_hero_cta_top.title)"
              color="dark-green"
              lineHover="bg-mint-30"
              circleColor="circle-orange"
            />
            <Button
              class="desktop-btn"
              v-if="
                props.data.custom_hero_cta_top &&
                props.data.background_image_pset != 1 &&
                props.data.custom_hero_cta_top &&
                props.data.background_image_pset != 5
              "
              :url="props.data.custom_hero_cta_top.url"
              :text="buttonText(props.data.custom_hero_cta_top.title)"
              color="dark-green"
              lineHover="bg-orange"
              circleColor="circle-white"
            />
          </div>

          <!-- Image  -->
          <div
            v-if="props.data.background_image_pset == 1"
            class="image prlx img-pset-1"
          >
            <img
              v-if="data.img"
              class="products"
              :src="data.img.url"
              :alt="data.img.alt"
            />
            <img
              v-else
              class="products"
              src="~@/assets/img/home-hero-products.png"
              alt="Products"
            />

            <img
              src="~@/assets/img/hero-bg-1.svg"
              alt="Background"
              class="background"
            />
          </div>
          <div
            v-else-if="props.data.background_image_pset == 2"
            class="image prlx img-pset-2"
          >
            <img
              v-if="data.img"
              class="products"
              :src="data.img.url"
              :alt="data.img.alt"
            />
            <img
              v-else
              class=""
              src="~@/assets/img/hero-img-2.png"
              alt="Products"
            />
          </div>
          <div
            v-else-if="props.data.background_image_pset == 3"
            class="image prlx img-pset-3"
          >
            <img
              v-if="data.img"
              class="products"
              :src="data.img.url"
              :alt="data.img.alt"
            />
            <img
              v-else
              class=""
              src="~@/assets/img/hero-img-3.png"
              alt="Products"
            />
          </div>
          <div
            v-else-if="props.data.background_image_pset == 4"
            class="image prlx img-pset-4"
          >
            <img
              v-if="data.img"
              class="products"
              :src="data.img.url"
              :alt="data.img.alt"
            />
            <img
              v-else
              class="desktop"
              src="~@/assets/img/hero-img-4-dt.png"
              alt="Products"
            />

            <!-- Background image for mobile -->
            <img
              v-if="data.img"
              class="mobile"
              :src="data.img.url"
              :alt="data.img.alt"
            />
            <img
              v-else
              src="~@/assets/img/hero-img-4-mb.png"
              alt="Products"
              class="mobile"
            />
          </div>
          <div
            v-if="props.data.background_image_pset == 5"
            class="image img-pset-5"
          >
            <div class="parallaxIt-container">
              <img
                class="parallaxIt"
                ref="parallaxIt"
                :src="data.expertise_hero_background.url"
                alt="Products"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="props.data.custom_hero_bottom_section"
        class="bottom-content"
        :class="[
          props.data.background_image_pset == 1 ||
          props.data.background_image_pset == 5
            ? 'development'
            : '',
          `bottom-content-${props.data.background_image_pset}`,
        ]"
      >
        <div class="container small">
          <p v-html="props.data.custom_hero_tag" class="tag" />
          <div class="big" v-html="props.data.custom_hero_text" />
          <Button
            v-if="props.data.custom_hero_cta_bottom"
            :url="props.data.custom_hero_cta_bottom.url"
            :text="buttonText(props.data.custom_hero_cta_bottom.title)"
            color="dark-green"
            lineHover="bg-mint-30"
            circleColor="circle-orange"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
// import circleRightBG from '@/assets/img/circle_right_bg.svg'
// import circleLeftBG from '@/assets/img/circle_left_bg.svg'
// import rectangle from '@/assets/img/hero-bg-1.svg'
import Button from '@/components/utility/Button.vue'
// import WideImage from '@/components/modules/WideImage.vue'
import { onMounted, onBeforeUnmount, ref } from 'vue'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'
const parallaxIt = ref()

const parallax = () => {
  if (!document.querySelector('.custom-hero-wrapper .prlx')) {
    setTimeout(() => {
      parallax()
    }, 100)
  } else {
    gsap.to('.custom-hero-wrapper .prlx', {
      y: '300',
      ease: 'none',
      scrollTrigger: {
        id: 'custom-hero-anim',
        trigger: '.custom-hero-wrapper',
        start: 'top top',
        end: 'bottom top',
        scrub: 0.5,
        markers: false,
      },
    })
  }
}

onMounted(() => {
  gsap.registerPlugin(ScrollTrigger)
  parallax()
  if (!parallaxIt.value) return
  gsap.set(parallaxIt.value, { scale: 1.5 })
  gsap.fromTo(
    parallaxIt.value,
    { y: 100 },
    {
      scrollTrigger: {
        trigger: parallaxIt.value,
        id: 'ukiyoParallaxItImg',
        start: 'top center',
        end: 'bottom+=110% center',
        scrub: true,
        markers: false,
      },
      y: -200,
    }
  )
})

onBeforeUnmount(() => {
  const ukiyoParallaxItImg = ScrollTrigger.getById('ukiyoParallaxItImg')
  if (ukiyoParallaxItImg) ukiyoParallaxItImg.kill()
  if (parallax.value) parallax.value.destroy()
  const customHeroAnim = ScrollTrigger.getById('custom-hero-anim')
  if (customHeroAnim) customHeroAnim.kill()
})

const props = defineProps(['data'])

const buttonText = (acfData) => {
  switch (acfData) {
    case '':
      return 'Learn More'
    default:
      return acfData
  }
}

// const selectedPreset = () => {
//   const pset = parseInt(props.data.background_image_pset)
//   switch (pset) {
//     case 1:
//       return null
//     case 2:
//       return circleLeftBG
//     case 3:
//       return circleRightBG
//     case 4:
//       return circleLeftBG
//     case 5:
//       return rectangle
//   }
// }
</script>

<style lang="scss" scoped>
.custom-hero-wrapper {
  @apply z-0 bg-no-repeat bg-cover lg:bg-contain lg:bg-left-top;
  @media (min-width: 1600px) {
    *,
    ::before,
    ::after {
      box-sizing: unset;
    }
  }
  &.section-1 {
    *,
    ::before,
    ::after {
      box-sizing: border-box;
    }
  }
  &.section-3 {
    @apply bg-right md:bg-top lg:bg-left-top;
  }
}
.custom-hero {
  @apply relative overflow-x-hidden overflow-y-auto
  min-h-[1300px]
  md:min-h-[1400px]
  lg:min-h-[1300px]
  xl:min-h-[1400px];

  &.section-pset-1,
  &.section-pset-5 {
    @apply min-h-max;
  }

  &.section-pset-2 {
    .mobile-btn {
      @apply lg:hidden;
    }
    .desktop-btn {
      @apply hidden lg:flex;
    }
  }

  &.section-pset-3 {
    @apply lg:min-h-[1620px];
    .bottom-content {
      @apply md:mt-[400px] md:max-w-[1200px]
        lg:mt-[425px];
    }
    .mobile-btn {
      @apply lg:hidden;
    }
    .desktop-btn {
      @apply hidden lg:flex;
    }
  }

  &.section-pset-4 {
    .bottom-content {
      @apply -mt-5
        pt-8
        max-w-[1100px]
        md:mt-[280px]
        lg:mt-[40px]
        desktop:mt-[70px]
        xl:mt-[140px]
        2xl:mt-[200px];
    }
    .mobile-btn {
      @apply xl:hidden;
    }
    .desktop-btn {
      @apply hidden xl:flex;
    }
  }

  .background {
    @apply absolute w-full;

    img {
      @apply object-contain object-right w-[100%];
    }

    &.bg-2 {
      @apply w-[1727px] top-[-805px] right-[-360px]
        mobile:w-[3039px] mobile:top-[-1760px] mobile:right-[-655px]
        lg:w-[4217px] lg:top-[-2960px] lg:right-[-263px];
    }

    &.bg-3 {
      @apply w-[1727px] top-[-804px] left-[-336px]
        md:w-[3039px] md:top-[-1664px] md:left-[-588px]
        lg:w-[4403px] lg:top-[-2834px] lg:left-[-213px];
    }

    &.bg-4 {
      @apply w-[1727px] top-[-805px] right-[-360px]
        md:w-[3039px] md:top-[-1760px] md:right-[-655px]
        lg:w-[4217px] lg:top-[-2960px] lg:right-[-263px];
    }

    &.bg-pset-1 {
      @apply top-[400px] left-6 right-auto md:top-[460px] md:left-14 lg:top-0 lg:left-auto lg:right-[670px];

      &.mobile {
        @apply hidden lg:block;
      }

      img {
        @apply max-w-none w-[680px] sm:w-[760px] md:w-[1273px] lg:w-[1455px];
      }
    }

    &.bg-pset-5 {
      @apply top-[400px] left-6 right-auto md:top-[460px] md:left-14 lg:top-0 lg:left-auto lg:right-[670px];

      &.mobile {
        @apply hidden lg:block;
      }

      img {
        @apply max-w-none w-[680px] sm:w-[760px] md:w-[1273px] lg:w-[1455px];
      }
    }
  }

  .container {
    @apply wide-container max-h-[1080px] flex flex-col justify-center items-start;

    &.small {
      @apply main-container;
    }
  }

  .top-content {
    @apply relative min-h-[100vh] max-h-[1080px] z-2 lg:py-0;
    .rectangle {
      @apply hidden lg:block absolute bg-ore_orange right-0 left-[170px];

      &.top {
        @apply h-[180px] top-0 2xl:left-[calc(50%_-_700px)];
      }

      &.middle {
        @apply h-full right-[55%] bottom-0 xl:left-auto xl:w-[calc(700px_-_5%)];
      }

      &.bottom {
        @apply h-[calc(30vh_+_300px)] bottom-0 2xl:left-[calc(50%_-_700px)];
      }
    }

    &.development {
      @apply max-h-max min-h-0 lg:pb-[calc(30vh_+_300px)] lg:min-h-[calc(100vh_+_300px)] relative lg:pt-[180px]
      2xl:min-h-0;

      .container {
        @apply h-full max-h-max relative;

        .text {
          @apply lg:min-h-0 lg:h-full lg:pb-0 lg:w-[55%]
          pt-[125px] lg:mr-0 lg:ml-auto
          2xl:w-1/2 2xl:pt-[250px] 2xl:pl-0 2xl:pr-0 2xl:pb-[0px];

          .subheader {
            @apply lg:max-w-[570px];
          }

          .title {
            @apply lg:max-w-[610px] lg:mb-6;

            &:deep(h1) {
              @apply lg:mb-1;
            }

            + a {
              @apply lg:mt-0;
            }
          }
        }
      }
    }

    &:deep(h1) {
      @apply text-[24px] mb-[40px] sm:text-[40px] leading-none;
    }

    .container {
      @apply lg:flex-row-reverse lg:items-center;

      .text {
        @apply pt-[150px] pb-4 md:px-[70px]
        lg:w-6/12 lg:px-12 lg:min-h-[100vh] lg:pt-24 lg:pb-0 lg:flex lg:flex-col lg:justify-center
        xl:pt-9
        2xl:pr-[190px]
        2xl:pl-[95px];
        // initial:
        // 2xl:pr-28
        // 2xl:pl-[270px];
        .subheader {
          @apply mb-5 text-xl leading-6 md:text-[30px] md:leading-[30px];
        }

        .title {
          @apply mb-8;

          &:deep(p) {
            @apply text-[24px] leading-none mb-5 md:text-[40px]
            lg:text-[30px]
            xl:text-[40px];
          }
        }
      }

      .image {
        @apply -z-1 relative h-[815px] w-[640px] sm:h-[1400px] sm:w-[1200px] lg:w-6/12 lg:h-full;
        .parallaxIt-container {
          @apply relative min-h-[430px] md:min-h-[550px] rounded-xl overflow-hidden;
        }
        img {
          @apply absolute w-[100vw] left-[-25px] sm:min-h-[490px] sm:w-full top-[150px] lg:w-[1170px] lg:top-[calc(50%_-_205px)] lg:max-w-none 2xl:left-[-70px];

          &.desktop {
            @apply hidden lg:block;
          }

          &.mobile {
            @apply w-[100vw] h-auto left-0 sm:h-[1400px] sm:w-[854px] top-0 z-[-1] md:w-[1000px] lg:hidden;
          }
        }

        &.img-pset-1 {
          @apply z-1 mt-10;

          img {
            &.products {
              @apply absolute min-h-0 max-w-none min-w-[530px] w-[90%] top-[90px] -left-[115px] md:top-[160px] md:left-[-70px] lg:w-[1100px] lg:left-auto lg:top-[calc(50%_-_223.5px)] lg:right-[80px] xl:right-[70px] 2xl:right-0;
            }

            &.background {
              @apply relative left-0 top-0 w-auto -z-1 max-w-[740px] sm:max-w-none lg:hidden;
            }
          }
        }

        &.img-pset-2 {
          @apply top-0;

          img {
            @apply absolute
                    min-h-0
                    max-w-none min-w-[765px] w-[180vw] xs:max-w-[990px] md:max-w-none md:w-[150vw] lg:w-[1050px] xl:w-[1180px] 2xl:w-[1170px]
                    top-[0] lg:top-[-215px] xl:top-[-250px] 2xl:top-[-280px]
                    left-[-250px] md:left-[-250px] lg:left-[-400px] xl:left-[-270px];
          }
        }

        &.img-pset-3 {
          @apply top-0;

          img {
            @apply absolute min-h-0 max-w-none min-w-[660px] w-[125vw] top-[0] left-[-150px]
            md:left-[-250px] md:top-[-60px] md:w-[150vw]
            lg:w-[1100px] lg:left-[-400px] lg:top-[-215px]
            desktop:w-[1200px]
            xl:w-[1320px] xl:left-[-350px] xl:top-[0px] 2xl:top-[-380px]
            2xl:left-[-320px] 2xl:w-[1250px];
          }
        }

        &.img-pset-4 {
          @apply top-0;

          img {
            //@apply absolute w-[100vw] left-[-25px] sm:min-h-[490px] sm:w-full top-[150px] lg:w-[1170px] lg:top-[calc(50%_-_205px)] lg:max-w-none 2xl:left-[-70px];
            @apply -z-1 absolute w-[100vw] sm:w-full lg:w-[1170px]
            sm:min-h-[490px]
            top-[150px] lg:top-[calc(50%_-_205px)] lg:max-w-none
            left-[-25px] 2xl:left-[-70px];

            &.desktop {
              @apply top-[-35vw]
              left-[-25vw] lg:left-[-35vw] 2xl:left-[-430px]
              min-h-0 h-[auto]
              w-[80vw] xl:w-[1200px]
              xl:top-[-575px]
              xl:left-[-530px];
            }

            &.mobile {
              //@apply w-[100vw] h-auto left-0 sm:h-[1400px] sm:w-[854px] top-0 z-[-1] md:w-[1000px] lg:hidden;
              @apply h-auto min-w-[850px] w-[200vw] max-w-none top-0 left-[-60vw] right-auto sm:top-[-250px] sm:left-[-50vw] md:max-w-[1500px] md:left-[-380px];

              @media (max-width: 365px) {
                @apply left-[-250px];
              }

              @media (min-width: 465px) and (max-width: 640px) {
                @apply top-[-175px];
              }
            }
          }
        }

        &.img-pset-5 {
          @apply z-1 h-auto w-[calc(100%_+_40px)] -ml-[20px] lg:absolute lg:w-[40%] lg:top-[calc(65%_-_300px)] lg:left-8 2xl:w-[35%];

          img.parallaxIt {
            @apply w-full left-0 top-0 rounded-3xl;
          }
        }
      }
    }
  }

  .bottom-content {
    //xl:pt-[300px]
    @apply relative z-2 mt-[300px] min-h-[100px] pb-[40px]
    md:mt-[250px]
    lg:mt-[385px]
    2xl:mt-[325px]
    lg:main-container lg:max-w-[1100px];
    &:deep(p.tag) {
      @apply text-[20px] mb-9 sm:mb-11 font-medium;
    }
    &-3 {
      @apply mt-[-100px]
        md:mt-[450px]
        lg:mt-[300px]
        xl:mt-[140px]
        2xl:mt-[140px];
    }
    .big {
      &:deep(p) {
        @apply text-[36px] sm:text-[60px] lg:text-[70px] leading-none font-normal;
      }
    }

    &.development {
      @apply mt-14 min-h-0 lg:pl-[calc(170px)] lg:pr-[80px] lg:mt-[50px] xl:pt-0;
    }
  }
}
</style>
