
<!--color options (use this for the text and underline color):

  mint
  mint-30
  orange
  white
  green
  green-dark

  lineHover options (use this for the hover line and arrow color):
  bg-mint
  bg-mint-30
  bg-orange
  bg-ore-white
  bg-green
  bg-green-dark

  circleColor options:
  circle-mint
  circle-mint-30
  circle-orange
  circle-white
  circle-green
  circle-green-dark
 -->

<template>
  <a v-if="props.url" :href="props.url" :download="download" :title="text ? text : ''" :target="target ? target : '_self'"
    :class="`button cursor-pointer ${props.color ? props.color : ''} ${props.lineHover ? props.lineHover : ''} ${props.circleColor ? props.circleColor : ''}`" :id="`button-${uid}`">
    <div class="button-icon">
      <span v-html="text" />
      <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_541_966)">
          <path
            d="M7.82325 0H9.46067C9.56842 0 9.65497 0.0387222 9.71856 0.0984188L14.9346 4.86286C15.0212 4.94192 15.0212 5.0597 14.9346 5.13875L9.74152 9.90319C9.67617 9.96289 9.59138 10.0016 9.48363 10.0016H7.82325C7.62895 10.0016 7.58655 9.88383 7.7155 9.76605L12.0466 5.8293H0.215497C0.0865521 5.8293 0 5.75024 0 5.63246V4.43207C0 4.31429 0.0865521 4.23524 0.215497 4.23524H12.0908L7.7155 0.23556C7.58655 0.11778 7.62895 0 7.82325 0Z"
            fill="#FF4C00" />
        </g>
        <defs>
          <clipPath id="clip0_541_966">
            <rect width="15" height="10" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </a>

  <div data-attribute="1" v-else :class="`button cursor-pointer ${props.color ? props.color : ''} ${props.lineHover ? props.lineHover : ''} ${props.circleColor ? props.circleColor : ''}`"
    :id="`button-${uid}`">
    <div class="button-icon">
      <span v-html="text" />

      <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_541_966)">
          <path
            d="M7.82325 0H9.46067C9.56842 0 9.65497 0.0387222 9.71856 0.0984188L14.9346 4.86286C15.0212 4.94192 15.0212 5.0597 14.9346 5.13875L9.74152 9.90319C9.67617 9.96289 9.59138 10.0016 9.48363 10.0016H7.82325C7.62895 10.0016 7.58655 9.88383 7.7155 9.76605L12.0466 5.8293H0.215497C0.0865521 5.8293 0 5.75024 0 5.63246V4.43207C0 4.31429 0.0865521 4.23524 0.215497 4.23524H12.0908L7.7155 0.23556C7.58655 0.11778 7.62895 0 7.82325 0Z"
            fill="#FF4C00" />
        </g>
        <defs>
          <clipPath id="clip0_541_966">
            <rect width="15" height="10" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'

const uid = ref(0);

(function generateId () {
  uid.value = `${Math.floor(Math.random() * (564 * 100))}`
})()

const props = defineProps({
  text: {
    type: String,
    required: false
  },
  url: {
    type: String,
    required: false
  },
  target: {
    type: String,
    required: false
  },
  download: {
    tyle: Boolean,
    required: false
  },
  color: {
    type: String,
    required: false
  },
  lineHover: {
    type: String,
    required: false
  },
  circleColor: {
    type: String,
    required: false
  },
  local: {
    type: Boolean,
    required: false,
    default: false
  },
  content: {
    type: Boolean,
    required: false,
    default: false
  }
})
</script>

<style lang="scss" scoped>
$ore_orange: #FF4C00;
$ore_mint: #BDE9C9;
$ore_mint_30: #EBF8EF;
$ore_green_dark: #001610;
$ore_green: #224946;

.button {
  @apply my-5 py-3.5 text-ore_green_dark transition ease-in-out duration-200 w-fit;

  .button-icon {
    @apply transition ease-in-out duration-200 relative pr-[60px] min-h-[40px] inline-flex items-center;

    span {
      @apply relative overflow-hidden text-2xl pb-1;

      &:before {
        @apply content-[''] absolute w-[280%] h-[1px] bg-ore_green_dark bottom-0 right-0 transition-all duration-500;
        background: linear-gradient(to right, rgba($ore_green_dark, 1) 50%, transparent 50% 60%, rgba($ore_green_dark, 1) 60%);
        transform: translateX(0%);
      }
    }

    svg {
      @apply absolute right-0 top-[calc(50%_-_2px)] transform scale-0 z-2;

      path {
        @apply fill-ore_green_dark;
        @apply transition ease-in-out duration-200;
      }

      animation: reduceArrow 0.1s ease-in-out;
      animation-fill-mode: forwards;
    }

    &:after {
      @apply content-[''] absolute w-[40px] h-[40px] top-[calc(50%_-_17px)] right-[-10px] rounded-full border-2 border-ore_orange transform scale-[0.25];
      animation: reduceCircleDefault 0.25s ease-in-out;
      animation-fill-mode: forwards;
    }
  }

  &:hover {
    .button-icon {
      span {
        &:before {
          background: linear-gradient(to right, rgba($ore_green_dark, 1) 45%, transparent 45% 80%, rgba($ore_green_dark, 1) 80%);
          transform: translateX(55%);
        }
      }

      svg {
        animation: expandArrow 0.25s ease-in-out forwards;
      }

      &:after {
        animation: expandCircleDefault 0.25s ease-in-out;
        animation-fill-mode: forwards;
      }
    }
  }

  //Custom arrow color
  &.bg-mint {
    .button-icon {
      svg {
        path {
          @apply fill-ore_mint;
        }
      }
    }
  }

  &.bg-mint-30 {
    .button-icon {
      svg {
        path {
          @apply fill-ore_mint_30;
        }
      }
    }
  }

  &.bg-orange {
    .button-icon {
      svg {
        path {
          @apply fill-ore_orange;
        }
      }
    }
  }

  &.bg-ore-white {
    .button-icon {
      svg {
        path {
          @apply fill-white;
        }
      }
    }
  }

  &.bg-green {
    .button-icon {
      svg {
        path {
          @apply fill-ore_green;
        }
      }
    }
  }

  &.bg-green-dark {
    .button-icon {
      svg {
        path {
          @apply fill-ore_green_dark;
        }
      }
    }
  }

  //Custom text and underline color
  &.mint {
    @apply text-ore_mint;

    .button-icon {
      span {
        &:before {
          background: linear-gradient(to right, rgba($ore_mint, 1) 50%, transparent 50% 60%, rgba($ore_mint, 1) 60%);
        }
      }
    }

    &:hover {
      .button-icon {
        span {
          &:before {
            background: linear-gradient(to right, rgba($ore_mint, 1) 45%, transparent 45% 80%, rgba($ore_mint, 1) 80%);
            transform: translateX(55%);
          }
        }
      }
    }
  }

  &.mint-30 {
    @apply text-ore_mint_30;

    .button-icon {
      span {
        &:before {
          background: linear-gradient(to right, rgba($ore_mint_30, 1) 50%, transparent 50% 60%, rgba($ore_mint_30, 1) 60%);
        }
      }
    }

    &:hover {
      .button-icon {
        span {
          &:before {
            background: linear-gradient(to right, rgba($ore_mint_30, 1) 45%, transparent 45% 80%, rgba($ore_mint_30, 1) 80%);
            transform: translateX(55%);
          }
        }
      }
    }
  }

  &.orange {
    @apply text-ore_orange;

    .button-icon {
      span {
        &:before {
          background: linear-gradient(to right, rgba($ore_orange, 1) 50%, transparent 50% 60%, rgba($ore_orange, 1) 60%);
        }
      }
    }

    &:hover {
      .button-icon {
        span {
          &:before {
            background: linear-gradient(to right, rgba($ore_orange, 1) 45%, transparent 45% 80%, rgba($ore_orange, 1) 80%);
            transform: translateX(55%);
          }
        }
      }
    }
  }

  &.white {
    @apply text-white;

    .button-icon {
      span {
        &:before {
          background: linear-gradient(to right, rgba(white, 1) 50%, transparent 50% 60%, rgba(white, 1) 60%);
        }
      }
    }

    &:hover {
      .button-icon {
        span {
          &:before {
            background: linear-gradient(to right, rgba(white, 1) 45%, transparent 45% 80%, rgba(white, 1) 80%);
            transform: translateX(55%);
          }
        }
      }
    }
  }

  &.green {
    @apply text-ore_green;

    .button-icon {
      span {
        &:before {
          background: linear-gradient(to right, rgba($ore_green, 1) 50%, transparent 50% 60%, rgba($ore_green, 1) 60%);
        }
      }
    }

    &:hover {
      .button-icon {
        span {
          &:before {
            background: linear-gradient(to right, rgba($ore_green, 1) 45%, transparent 45% 80%, rgba($ore_green, 1) 80%);
            transform: translateX(55%);
          }
        }
      }
    }
  }

  &.green-dark {
    @apply text-ore_green_dark;

    .button-icon {
      span {
        &:before {
          background: linear-gradient(to right, rgba($ore_green_dark, 1) 50%, transparent 50% 60%, rgba($ore_green_dark, 1) 60%);
        }
      }
    }

    &:hover {
      .button-icon {
        span {
          &:before {
            background: linear-gradient(to right, rgba($ore_green_dark, 1) 45%, transparent 45% 80%, rgba($ore_green_dark, 1) 80%);
            transform: translateX(55%);
          }
        }
      }
    }
  }

  //Custom circles
  &.circle-mint {
    .button-icon {
      &:after {
        @apply border-ore_mint;
        animation: reduceCircleMint 0.25s ease-in-out forwards;
      }
    }

    &:hover {
      .button-icon {
        &:after {
          animation: expandCircleMint 0.25s ease-in-out;
        }
      }
    }
  }

  &.circle-mint-30 {
    .button-icon {
      &:after {
        @apply border-ore_mint_30;
        animation: reduceCircleMint30 0.25s ease-in-out forwards;
      }
    }

    &:hover {
      .button-icon {
        &:after {
          animation: expandCircleMint30 0.25s ease-in-out forwards;
        }
      }
    }
  }

  &.circle-orange {
    .button-icon {
      &:after {
        @apply border-ore_orange;
        animation: reduceCircleOrange 0.25s ease-in-out forwards;
      }
    }

    &:hover {
      .button-icon {
        &:after {
          animation: expandCircleOrange 0.25s ease-in-out forwards;
        }
      }
    }
  }

  &.circle-white {
    .button-icon {
      &:after {
        @apply border-white;
        animation: reduceCircleWhite 0.25s ease-in-out forwards;
      }
    }

    &:hover {
      .button-icon {
        &:after {
          animation: expandCircleWhite 0.25s ease-in-out forwards;
        }
      }
    }
  }

    &.circle-white-home {
    .button-icon {
      &:after {
        @apply border-ore_orange;
        animation: reduceCircleWhiteHome 0.25s ease-in-out forwards;
      }
    }

    &:hover {
      .button-icon {
        &:after {
          animation: expandCircleWhiteHome 0.25s ease-in-out forwards;
        }
      }
    }
  }

  &.circle-green {
    .button-icon {
      &:after {
        @apply border-ore_green;
        animation: reduceCircleGreen 0.25s ease-in-out forwards;
      }
    }

    &:hover {
      .button-icon {
        &:after {
          animation: expandCircleGreen 0.25s ease-in-out forwards;
        }
      }
    }
  }

  &.circle-green-dark {
    .button-icon {
      &:after {
        @apply border-ore_green_dark;
        animation: reduceCircleDarkGreen 0.25s ease-in-out forwards;
      }
    }

    &:hover {
      .button-icon {
        &:after {
          animation: expandCircleDarkGreen 0.25s ease-in-out forwards;
        }
      }
    }
  }
}

// Animations
@keyframes expandArrow {
  from {
    transform: scale(0);
    right: 10px;
  }

  to {
    transform: scale(1);
    right: 12px;
  }
}

@keyframes reduceArrow {
  from {
    transform: scale(1);
    right: 12px;
  }

  to {
    transform: scale(0);
    right: 10px;
  }
}

// Animations for the deafult button
@keyframes expandCircleDefault {
  from {
    @apply right-[-10px] bg-transparent transform scale-[0.25];
  }

  to {
    @apply right-[0px] bg-ore_orange transform scale-[1];
  }
}

@keyframes reduceCircleDefault {
  from {
    @apply right-[0px] bg-ore_orange transform scale-[1];
  }

  to {
    @apply right-[-10px] bg-transparent transform scale-[0.25];
  }
}

//Animations for button with white attribute
@keyframes expandCircleWhite {
  from {
    @apply right-[-10px] bg-transparent transform scale-[0.25];
  }

  to {
    @apply right-[0px] bg-white transform scale-[1];
  }
}

@keyframes reduceCircleWhite {
  from {
    @apply right-[0px] bg-white transform scale-[1];
  }

  to {
    @apply right-[-10px] bg-transparent transform scale-[0.25];
  }
}

//Animations for button with mint attribute
@keyframes expandCircleMint {
  from {
    @apply right-[-10px] bg-transparent transform scale-[0.25];
  }

  to {
    @apply right-[0px] bg-ore_mint transform scale-[1];
  }
}

@keyframes reduceCircleMint {
  from {
    @apply right-[0px] bg-ore_mint transform scale-[1];
  }

  to {
    @apply right-[-10px] bg-transparent transform scale-[0.25];
  }
}

//Animations for button with mint-30 attribute
@keyframes expandCircleMint30 {
  from {
    @apply right-[-10px] bg-transparent transform scale-[0.25];
  }

  to {
    @apply right-[0px] bg-ore_mint_30 transform scale-[1];
  }
}

@keyframes reduceCircleMint30 {
  from {
    @apply right-[0px] bg-ore_mint_30 transform scale-[1];
  }

  to {
    @apply right-[-10px] bg-transparent transform scale-[0.25];
  }
}

//Animations for button with Orange attribute
@keyframes expandCircleOrange {
  from {
    @apply right-[-10px] bg-transparent transform scale-[0.25];
  }

  to {
    @apply right-[0px] bg-ore_orange transform scale-[1];
  }
}

@keyframes reduceCircleOrange {
  from {
    @apply right-[0px] bg-ore_orange transform scale-[1];
  }

  to {
    @apply right-[-10px] bg-transparent transform scale-[0.25];
  }
}

//Animations for button with Green attribute
@keyframes expandCircleGreen {
  from {
    @apply right-[-10px] bg-transparent transform scale-[0.25];
  }

  to {
    @apply right-[0px] bg-ore_green transform scale-[1];
  }
}

@keyframes reduceCircleGreen {
  from {
    @apply right-[0px] bg-ore_green transform scale-[1];
  }

  to {
    @apply right-[-10px] bg-transparent transform scale-[0.25];
  }
}

//Animations for button with Dark Green attribute
@keyframes expandCircleDarkGreen {
  from {
    @apply right-[-10px] bg-transparent transform scale-[0.25];
  }

  to {
    @apply right-[0px] bg-ore_green_dark transform scale-[1];
  }
}

@keyframes reduceCircleDarkGreen {
  from {
    @apply right-[0px] bg-ore_green_dark transform scale-[1];
  }

  to {
    @apply right-[-10px] bg-transparent transform scale-[0.25];
  }
}

//Animations for button with White attribute, built specially for Home banner
@keyframes expandCircleWhiteHome {
  from {
    @apply right-[-10px] bg-transparent transform scale-[0.25] border-white;
  }

  to {
    @apply right-[0px] bg-white transform scale-[1] border-white;
  }
}

@keyframes reduceCircleWhiteHome {
  0% {
    @apply right-[0px] bg-white transform scale-[1] border-white;
  }

  99% {
    @apply right-[-10px] bg-transparent transform scale-[0.25] border-white;
  }
  100% {
    @apply  border-ore_orange;
  }
}
</style>
