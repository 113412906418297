
export default {
  ADD_ITEM (state, { type, item }) {
    if (item && type && !state[type].hasOwnProperty(item.id ? item.id : item.quote_id)) { // eslint-disable-line
      state[type][item.id ? item.id : item.quote_id] = item
    }
  },
  CLEAR_ITEMS (state, { type, id }) {
    state[type] = { }
  },
  ADD_REQUEST (state, { type, request }) {
    state[type].requests.push(request)
  },
  SET_LOADING (state, loading) {
    if (loading === false) {
      setTimeout(() => {
        state.site.loading = loading
      }, 500)
    } else {
      state.site.loading = loading
    }
  },
  SET_INTRO (state, introShown) {
    state.site.introShown = introShown
  },
  SET_DOC_TITLE (state, title) {
    state.site.docTitle = title
  },
  SEARCH_TERM (state, searchTerm) {
    state.search_term = searchTerm
  },
  ACF_OPTIONS (state, options) {
    state.acf_options = options
  }
}
