<template>
  <section class="title-with-list" :class="{'only-list': props.data.title_with_list_only_list, 'not-list': !props.data.title_with_list_only_list}">
    <div v-if="props.data.title_with_list_title" class="title-with-list__title">
      <h2 class="text">{{props.data.title_with_list_title}}</h2>
      <div class="circles">
        <span class="circle-one"></span>
        <span class="circle-two"></span>
        <span class="circle-three"></span>
      </div>
    </div>
    <div class="title-with-list__list" >
      <div class="item" v-for="(item, index) of props.data.title_with_list_list" :key="index">
        <div class="text" v-html="item.title_with_list_item_list"></div>
        <div class="cta-wrapper" v-if="item.title_with_list_item_cta">
          <Button
            :url="item.title_with_list_item_cta.url"
            :text="item.title_with_list_item_cta.title"
            color="dark-green"
            lineHover="bg-mint-30"
            circleColor="circle-orange"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { onMounted, onUpdated, onBeforeUnmount } from 'vue'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'
import Button from '@/components/utility/Button.vue'

gsap.registerPlugin(ScrollTrigger)

const props = defineProps(['data'])

onMounted(() => {
  // console.log('data', props)
  applyTransition()
})

onUpdated(() => {
  // ScrollTrigger.refresh()
})

onBeforeUnmount(() => {
  /*
  ** No error but media query events still fire after unmount
  */
  const titleWithList = ScrollTrigger.getById('title-with-list')
  if (titleWithList) titleWithList.kill()

  const titleWithListThree = ScrollTrigger.getById('title-with-list-three')
  if (titleWithListThree) titleWithListThree.kill()
})

function applyTransition () {
  const scrollTrigger = {
    id: 'title-with-list',
    trigger: '.title-with-list',
    start: 'top center',
    end: 'bottom center',
    startTrigger: '.title-with-list',
    endTrigger: '.title-with-list',
    // markers: true,
    scrub: true
  }

  const scrollTriggerThree = {
    id: 'title-with-list-three',
    trigger: '.title-with-list',
    start: 'top center',
    end: 'bottom center',
    startTrigger: '.title-with-list',
    endTrigger: '.title-with-list',
    // markers: true,
    scrub: true
  }

  gsap.fromTo(
    '.title-with-list .circle-one',
    { x: 30 },
    {
      scrollTrigger,
      x: 0
    }
  )

  gsap.fromTo(
    '.title-with-list .circle-three',
    { x: -30 },
    {
      scrollTrigger: scrollTriggerThree,
      x: 0
    }
  )
}

</script>
<style lang="scss" scoped>
.title-with-list {
  @apply flex flex-col w-full main-container px-[29px] md:px-[38px] pt-8 pb-11 md:pt-28 md:pb-24 lg:pb-32;

  &.not-list {
    @apply md:px-16;

    .title-with-list__title {
      .text {
        @apply pt-[38px] md:pl-0 lg:w-[70%] desktop:w-[45%];
      }
      .circles{
        @apply justify-end lg:justify-start desktop:justify-center lg:pr-0 desktop:pl-32 xl:pl-48;
      }
    }

    .title-with-list__list{
      @apply md:pt-4 lg:pt-24 lg:pl-[35%] desktop:pl-[45%] desktop:gap-x-6 lg:gap-x-14 xl:gap-x-[5.5rem] inline-grid grid-cols-1 md:grid-cols-2 gap-x-20;

      .item:deep() {
        @apply pb-8;
        h4 {
          @apply md:pl-0;
        }

        ul {
          @apply flex flex-col md:flex-row gap-y-5 font-medium text-2xl lg:text-3xl leading-6 lg:leading-[30px] text-ore_green_dark bg-green-300;
        }
      }
    }
  }

  &.only-list {
    .title-with-list__list{
      @apply flex;

      .item:deep(){
        @apply pb-10 md:pb-0;
        ul {
          @apply inline-grid grid-cols-1 md:grid-cols-2 gap-x-20;
        }
      }
    }
  }

  &__title {
    @apply flex flex-col-reverse justify-center md:flex-row w-full;

    .text {
      @apply font-medium text-[46px] md:text-6xl lg:text-[70px] leading-[34px] md:leading-[60px] lg:leading-[70px] text-ore_orange pt-[59px] pb-[48px] md:pl-8 lg:pl-[35px] 2xl:pl-0 xl:w-1/2;
    }

    .circles{
      @apply flex flex-row self-end md:self-center space-x-1 w-auto h-auto pr-11 md:pr-20 md:pb-0 lg:flex flex-grow justify-center md:justify-end lg:justify-center items-end;

      span {
        @apply h-[30px] w-[30px] bg-transparent border border-ore_green_dark rounded-full;
      }
    }
  }

  &__list {
    @apply w-full md:pt-[86px] lg:pt-14 desktop:pl-[25.5rem] 2xl:pl-[23.5rem];

    .item:deep(){

      .cta-wrapper {
        @apply pt-[43px] md:pt-14;
      }

      h4 {
        @apply font-medium text-2xl md:text-3xl leading-6 md:leading-[30px] text-ore_green_dark md:pl-6;
      }

      p {
        @apply font-medium text-xl leading-6 text-ore_green_dark
      }

      ul > li {
        @apply font-medium text-2xl lg:text-3xl leading-6 lg:leading-[30px] text-ore_green_dark md:flex;

        &:not(:last-child) {
          @apply pb-[43px] md:pb-[72px] lg:pb-[90px];
        }

        &::before {
          @apply block border-ore_green_dark border border-solid h-4 mb-4 md:mb-0 md:pr-4 md:pt-4 md:mr-[11px] lg:mr-[18px] lg:mt-2 rounded-full w-4;
          content: "";
        }
      }
    }
  }
}
</style>
