<template>
  <section class="textGallery">
    <div class="galleryContainer">

      <a class="item" v-for="(item, index) in props.data.items" :key="index" :href="item.link.url">
        <div class="image">
          <img :src="item.image.url" :alt="item.image.alt" />
        </div>
        <div class="text">
          <p>{{ item.text }}</p>
           <div class="circleContainer">
          <div class="circles">
            <div class="circle one"></div>
            <div class="circle two"></div>
            <div class="circle three"></div>
          </div>
        </div>
        </div>
      </a>
    </div>
  </section>
</template>

<script setup>
const props = defineProps(['data'])
</script>

<style lang="scss" scoped>
.textGallery {
  @apply main-container;
  .galleryContainer{
    @apply flex flex-col md:flex-row justify-between gap-3 lg:gap-5 my-10 lg:mx-4;
    .item {
      @apply flex flex-col w-full md:w-1/3 bg-white rounded-3xl ;
      .image {
        @apply flex flex-col aspect-square overflow-hidden rounded-3xl transition-all duration-300 ease-in-out;
        img {
          @apply w-full h-full object-cover transition-all duration-300 ease-in-out;
        }
      }
      .text {
        @apply flex flex-row justify-between py-3 lg:py-7 px-6 items-center;
        p {
          @apply  text-xl lg:text-2xl;
        }
        .circleContainer{
          @apply block w-7 h-7 scale-75;
        .circles {
          @apply relative origin-center;
          .circle {
            @apply absolute w-7 h-7 border border-ore_green_dark rounded-full origin-top transition-transform ease-in-out duration-300;
          }
          .one, .two, .three{
            @apply rotate-0 transition-all ease-in-out duration-300;
          }
        }
      }
      }
    }
    .item:hover {
      .image{
        @apply scale-95 transition-all duration-300 ease-in-out;
        img{
          @apply transform scale-105 transition-all duration-300 ease-in-out;
        }
      }
      .circleContainer{
        @apply right-[45px] bottom-[45px];
        .circles {
          .one{
            @apply rotate-180 origin-[50%_15%] transition-transform ease-in-out duration-300;

          }
          .two{
            @apply rotate-180 origin-[30%_50%] transition-transform ease-in-out duration-300;
          }
          .three{
            @apply rotate-180 origin-[70%_50%] transition-transform ease-in-out duration-300;
          }
        }
      }
    }
  }
}
</style>
