<template>
  <div
    class="spacer"
    :style="`${
      height > 0 ? `padding-top: ${height}px;` : `margin-top: ${height}px;`
    } background-color: ${props.data.background_color}; box-shadow: 0 0 0 1px ${
      props.data.background_color
    };`"
  />
</template>

<script setup>
import { ref, onMounted, onUnmounted, onBeforeMount, watch } from 'vue'

const props = defineProps(['data'])

const deviceWidth = ref(0)
const height = ref(0)

watch(deviceWidth, () => {
  if (deviceWidth.value > 1024) {
    height.value = props.data.spacer_height
  } else if (deviceWidth.value > 768) {
    height.value = props.data.spacer_height_tablet
  } else {
    height.value = props.data.spacer_height_mobile
  }
})

onBeforeMount(() => {
  deviceWidth.value = window.innerWidth
})

onMounted(() => {
  window.addEventListener('resize', () => {
    deviceWidth.value = window.innerWidth
  })
})

onUnmounted(() => {
  window.removeEventListener('resize', () => {
    deviceWidth.value = window.innerWidth
  })
})
</script>

<style lang="scss" scoped>
.spacer {
}
</style>
