<template>
  <section class="video-module">
    <!-- <div class="container"> -->
      <div class="content-wrapper" @click="toggleVideo()"  id="video-iframe">
        <div class="embed-wrapper" v-if="videoPlaying && data.video_module_vimeo_id">
          <iframe v-if="data.video_module_vimeo_hash"
            :src="`https://player.vimeo.com/video/${data.video_module_vimeo_id}?h=${data.video_module_vimeo_hash}&autoplay=1&title=0&byline=0&portrait=0`"
            style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture" allowfullscreen scrolling="no"></iframe>
          <iframe v-else
            :src="`https://player.vimeo.com/video/${data.video_module_vimeo_id}?autoplay=1&title=0&byline=0&portrait=0`"
            style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0"
            allow="autoplay; fullscreen; picture-in-picture" allowfullscreen scrolling="no"></iframe>
        </div>
        <template v-if="data.video_module_video_autoplay">
          <video controlsList="nodownload" loop muted autoplay playsinline preload="auto" ref="videoLoop"
            v-show="showVideoLoop">
            <source :src="data.video_module_video_autoplay.url" type="video/mp4" />
          </video>
        </template>
      </div>
    <!-- </div> -->
  </section>
</template>

<script>
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
export default {
  data () {
    return {
      showVideoLoop: true,
      videoPlaying: false,
      durationMin: '',
      durationSec: ''
    }
  },
  components: {},
  props: ['data'],
  computed: {},
  methods: {
    toggleVideo () {
      const { videoLoop } = this.$refs
      if (this.showVideoLoop) {
        if (videoLoop) {
          videoLoop.pause()
        }
        this.showVideoLoop = false
        this.videoPlaying = true
      } else {
        if (videoLoop) {
          videoLoop.play()
        }
        this.showVideoLoop = true
        this.videoPlaying = false
      }
    },
    updateCountdown () {
      const { video } = this.$refs
      const videoDiff = video.duration - video.currentTime
      const minutes = parseInt(videoDiff / 60, 10)
      const seconds = Math.round(videoDiff % 60)
      this.durationMin = minutes
      this.durationSec = seconds === 60 ? 59 : seconds
    }
  },
  mounted () {
  },
  beforeDestroy () {
    const { videoLoop, video } = this.$refs
    if (videoLoop) {
      videoLoop.pause()
    }
    if (video) {
      video.pause()
    }
  }
}
</script>

<style lang="scss">
.video-module {
  position: relative;
  border-radius: 27px;
  overflow: hidden;

  .content-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;

    video {
      width: 100%;
    }

    cursor: pointer;
    .embed-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }
}
</style>
