<template>
  <div class="finish-card" @click="setproductFinish(material); setFinishCategory(finishname)" :class="selected ? 'active' : ''">
    <div class="texture">
    <img :src="material['acf-single-product-options-finish-option'].url ? material['acf-single-product-options-finish-option'].url : defaultImage" />
    </div>
    <div class="text">{{material["acf-single-product-options-finish-option"].label}}</div>
    <div v-if="material['acf-single-product-options-finish-option'].details['acf-product-options-finish-description']" class="popper-container">
      <Popper placement="top" :hover="true">
        <img class="tooltips" src="@/assets/img/info.svg" alt="" />
        <template #content>
          <div class="flex flex-col">
            <img class="tooltip-icon mb-4 w-6 h-6" src="@/assets/img/info.svg" alt="" />
            <div class="mb-4">{{ material['acf-single-product-options-finish-option'].details['acf-product-options-finish-description'] }}</div>
            <a class="flex w-fit self-end hover:scale-95 transition" v-if="material['acf-single-product-options-finish-option'].details['acf-product-options-finish-file']" target="_blank" role="button" :href="`${material['acf-single-product-options-finish-option'].details['acf-product-options-finish-file'].url}`" :download="`${material['acf-single-product-options-finish-option'].details['acf-product-options-finish-title']}`">
              <div class="cursor-pointer specs-button flex items-center border border-black rounded-full w-fit px-3 py-1">
                <p class="mr-4 text-[14px]">Specs</p><img src="@/assets/img/download-black.svg" alt="">
              </div>
            </a>
          </div>
        </template>
      </Popper>
    </div>
  </div>
</template>
<script setup>
import Popper from 'vue3-popper'
import { ref, watchEffect, onMounted } from 'vue'
import { ProductState } from './state'
import { useStore } from 'vuex'
import defaultImage from '@/assets/img/default-ore.jpg'

const store = useStore()
const { setFinish, setCurrentImage, setFinishCategory, finishCategory, finish } = ProductState()

const props = defineProps(['material', 'finishname'])
const selected = ref(false)

const setproductFinish = (finish) => {
  if (!finish['acf-single-product-options-finish-image']) {
    finish['acf-single-product-options-finish-image'] = { url: `${store.state.site.url}/${defaultImage}` }
  }
  setFinish(finish)
}

onMounted(() => {
  if (!finish.value) return
  if (
    finish.value['acf-single-product-options-finish-option'].value === props.material['acf-single-product-options-finish-option'].value &&
    finishCategory.value === props.finishname
  ) {
    selected.value = true
  }
})

watchEffect(() => {
  if (!finish.value) return
  if (
    finish.value['acf-single-product-options-finish-option'].value === props.material['acf-single-product-options-finish-option'].value &&
    finishCategory.value === props.finishname
  ) {
    selected.value = true
    setCurrentImage(props.material['acf-single-product-options-finish-image'].url)
  } else {
    selected.value = false
  }
})

</script>
<style lang="scss" scoped>
.finish-card {
  @apply bg-white rounded-xl p-2 flex items-center transition-shadow duration-300 ease-in-out relative cursor-pointer;
  &::before {
    @apply absolute content-[''] h-2 w-2 rounded-full bg-ore_orange top-2 right-3 opacity-0 transition-opacity duration-300 ease-in-out;
  }
  &.active {
    @apply shadow-primaryshade/30 shadow-lg transition-shadow duration-300 ease-in-out;
    ::before {
      @apply absolute content-[''] h-2 w-2 rounded-full bg-ore_orange top-2 right-3 opacity-100 transition-opacity duration-300 ease-in-out;
    }
  }
  &:hover {
    @apply shadow-primaryshade/30 shadow-lg transition-shadow duration-300 ease-in-out;
  }
  .texture {
    @apply h-[75px] w-5/12 rounded-xl overflow-hidden flex items-center;
    img {
      @apply h-[160%] sm:h-[200%] md:h-[255%] lg:h-[140%] w-auto object-cover object-center;
    }
  }
  .text {
    @apply leading-tight pl-2 w-7/12;
  }
  .popper-container:deep() {
    @apply self-end absolute right-2 bottom-0;
    &::before {
      @apply content-none;
    }
    .tooltips {
      @apply relative bg-white rounded-full z-30;
    }
    .popper {
        @apply bg-white bg-opacity-[95%] w-72 p-5 shadow-2xl text-base rounded-lg text-black;
      }
  }
}

</style>
