import axios from 'axios'

const { url } = __VUE_WORDPRESS__.routing

const ajax = axios.create(
  {
    baseURL: `${url}/wp-json/wp/v2/`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }
)

const acfAjax = axios.create(
  {
    baseURL: `${url}/wp-json/acf/v3/`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }
)

const searchAjax = axios.create(
  {
    baseURL: `${url}/wp-json/swp_api/search?s=`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  }
)

const batchRequest = {}

function addBatchId (type, id, embed) {
  if (!batchRequest[type]) {
    batchRequest[type] = {}
    batchRequest[type].ids = [id]
    batchRequest[type].request = new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(batchRequestIds(type, embed))
        batchRequest[type] = null
      }, 100)
    })
  } else if (!batchRequest[type].ids.includes(id)) {
    batchRequest[type].ids.push(id)
  }
}

function batchRequestIds (type, embed) {
  if (embed) {
    return ajax.get(`/${type}/`, { params: { include: batchRequest[type].ids, per_page: 100, _embed: true } })
  } else {
    return ajax.get(`/${type}/`, { params: { include: batchRequest[type].ids, per_page: 100 } })
  }
}

export const fetchSingleById = ({ type, id, batch = false, embed = false }) => {
  if (batch) {
    addBatchId(type, id, embed)
    return batchRequest[type].request
  } else {
    return ajax.get(`/${type}/${id}${embed ? '?_embed' : ''}`)
  }
}

export const fetchSingle = ({ type, params = {} }) => {
  return ajax.get(`/${type}/`, { params })
}

export const fetchItems = ({ type, params = {} }) => {
  return ajax.get(`/${type}/`, { params })
}

export const fetchSearch = ({ term, params = {} }) => {
  return searchAjax.get(`/${term}/`, { params })
}

export const fetchAcfOptions = () => {
  return acfAjax.get('/options/options')
}

// add item to quote
export const setQuoteItem = async ({ type, params = {} }) => {
  await ajax.post(`/${type}/`, { data: params, withCredentials: true })
  const quote = await fetchItems({ type: 'quote', params: {} })
  return quote
}

export const deleteQuoteItem = async ({ type, params = {} }) => {
  await ajax.delete(`/${type}/`, { data: params })
  const quote = await fetchItems({ type: 'quote', params: {} })
  return quote
}
