<template>
  <div class="careers">
    <div ref="careersRef" id="BambooHR" data-domain="oredesigns.bamboohr.com" data-version="1.0.0" data-departmentId=""></div>
    <ScriptLoader/>
  </div>
</template>
<script setup>

import { createApp } from 'vue'
import { onBeforeMount, onMounted, ref } from '@vue/runtime-core'
import Button from '@/components/utility/Button.vue'
import ScriptLoader from '@/components/utility/ScriptLoader.vue'

const careersRef = ref()
const modifyWidget = () => {
  const jobCategories = careersRef?.value?.children[0]?.children[1]?.children[1]?.children
  if (jobCategories) {
    for (let i = 0; i < jobCategories.length; i++) {
      const listHeader = jobCategories[i].children[0]
      listHeader.innerHTML = listHeader.innerHTML.replaceAll(/\((.*?)\)/g, '')
      const jobList = jobCategories[i].children[1].children
      for (let j = 0; j < jobList.length; j++) {
        const job = jobList[j]
        if (!document.querySelector(`#job-button-${j}-${i}`)) {
          const container = document.createElement('div')
          container.setAttribute('id', `job-button-${j}-${i}`)
          const jobUrl = job.children[0].href
          job.children[0].setAttribute('href', '')

          job.append(container)
          createApp(Button, { text: 'See Position', url: jobUrl, target: '_blank' }).mount(`#job-button-${j}-${i}`)
        }
      }
    }
  } else {
    setTimeout(() => {
      modifyWidget()
    }, 100)
  }
}
onBeforeMount(() => {

})
onMounted(() => {
  // document.onreadystatechange = async () => {
  //   if (document.readyState == 'complete') {
  //     console.log('Page completed with image and files!')
  //     // await loadScript('https://oredesigns.bamboohr.com/js/embed.js')
  //   }
  // }

  const observer = new MutationObserver((mutationList) => {
    for (const mutation of mutationList) {
      if (mutation.type === 'childList') {
        modifyWidget()
        observer.disconnect()
      }
      // if (mutation.type === subtree) {

      // }
    }
  })
  observer.observe(document.getElementById('BambooHR'), { childList: true, subtree: true })

  setTimeout(() => {

    // console.log(jobCategories)
  }, 2000)
})

</script>

<style lang="scss" scoped>
.careers {
  @apply main-container py-[100px] xl:px-[170px] 2xl:px-0;
  #BambooHR {
    &:deep(#BambooHR-ATS) {
      div {
        h2 {
          @apply font-FHOscar text-[46px] leading-[36px] text-ore_green_dark border-none
          md:text-[60px] md:leading-none
          #{!important};
        }
        .BambooHR-ATS-blankState {
          @apply xl:pl-[3.5rem] font-FHOscar border-none flex flex-col #{!important};
          strong {
            @apply text-xl text-ore_green_dark border-none;
          }
          p {
            @apply text-[40px] leading-none
            ;
          }
        }
        .BambooHR-ATS-Department-List {
          @apply font-FHOscar text-ore_green_dark p-0;
          .BambooHR-ATS-Department-Item {
           @apply font-FHOscar text-ore_green_dark;
           .BambooHR-ATS-Department-Header {
            @apply font-bold text-[30px] mt-[70px] leading-none
            lg:mt-[100px] lg:mb-[30px];
           }
            .BambooHR-ATS-Jobs-List {
              @apply flex flex-col leading-none font-FHOscar border-none p-0
              lg:flex-row
              #{!important};
              .BambooHR-ATS-Jobs-Item {
                @apply flex flex-col gap-[50px] text-ore_green_dark pt-[60px] pb-[40px] border-b-[2px] border-[#001610]/30
                lg:border-b-0 lg:border-r-2 lg:w-[33%] lg:justify-between lg:flex-wrap lg:flex-col lg:pr-[25px] lg:py-[40px];
                &:not(:first-child) {
                  @apply lg:pl-[100px]
                }
                &:last-child {
                  @apply lg:border-r-0;
                }
                span {
                  @apply text-base leading-none order-1
                  lg:w-[125px]
                  ;
                }
                a {
                  @apply pointer-events-none order-2 text-[30px] leading-none font-[400]
                  lg:min-h-[100px] lg:max-w-[300px]
                }

                div {
                  @apply order-3 ;
                   a {
                    @apply pointer-events-auto;

                   }
                   span {
                      @apply text-[24px]  ;
                    }
                }

              }
            }
          }
        }
      }

    }
    &:deep(div+div) {
      @apply hidden;
    }
  }
}
</style>
