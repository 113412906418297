<template>
  <div class="wrapper">
    <main>
      <section class="section-404">
        <div class="green-nav">
          <div class="message">
            <img src="@/assets/img/404_icon.png" alt="Sad smile icon" />
            <p v-html="acfOptions.error_404_title ? acfOptions.error_404_title : '404 ERROR PAGE'"/>
          </div>
          <div class="nav">
            <Button
              :url="page.page.permalink"
              :text="page.page.post_title"
              color="white"
              lineHover="bg-green"
              circleColor="circle-white"
              v-for="(page, index) in acfOptions.error_404_pages"
              :key="index"
            />
          </div>
        </div>
        <div class="text" v-html="acfOptions.error_404_text" />
      </section>
    </main>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'vuex'
import Button from '@/components/utility/Button.vue'

const store = useStore()
const acfOptions = computed(() => {
  return store.getters.acfOptions()
})

</script>

<style lang="scss" scoped>
.section-404 {
  @apply main-container min-h-[100vh] relative py-[160px] lg:py-[240px];

  .green-nav {
    @apply relative bg-ore_green max-w-[1390px] mx-auto rounded-[27px] p-6 mb-20 md:flex md:justify-center md:items-center md:gap-[100px] xl:justify-between xl:mb-[200px] xl:pr-12 xl:pl-8;

    .nav {
      @apply relative flex flex-col justify-start items-start mt-4 md:mt-0 xl:flex-row xl:flex-wrap xl:justify-end xl:gap-y-[10px] xl:items-center;

      .button {
        @apply relative;

        &.white {
          @apply m-3 p-0 xl:ml-[50px] xl:m-0 xl:mr-[50px];

          &:first-child {
            @apply xl:ml-0;
          }

       &:last-child {
            @apply xl:mr-0;
          }
        }

        + .button {
          &:before {
            @apply content-[''] absolute -left-[50px] top-0 bottom-0 w-[1px] bg-ore_orange hidden xl:block;
          }
        }
      }
    }

    .message {
      @apply relative flex flex-col justify-start items-center md:flex-row lg:flex-1 lg:max-w-max;

      p {
        @apply text-white leading-none text-center xl:text-[30px];
      }

      img {
        @apply mx-auto;
      }
    }
  }
  :deep(.text) {
    @apply max-w-[1140px] mx-auto;

    p {
      @apply text-[40px] leading-none mb-5 sm:text-[60px] sm:mb-9 xl:text-[70px] xl:mb-14;
    }
  }
}
</style>
