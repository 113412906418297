<template>
  <div class="site-loading-wrap">
    <div class="site-loading"></div>
  </div>
</template>

<script>
export default {
  name: 'SiteLoading'
}
</script>

<style lang="scss">
.site-loading-wrap {
  @apply fixed top-0 bottom-0 left-0 right-0 z-50 bg-ore_mint_30;
}
</style>
