<template>
  <section class="main-container" v-if="data">
    <div class="imageText2">
      <div class="imageText2__image">
        <img :src="data.image.url" :alt="data.image.alt" />
        <div class="circles">
          <div class="circle one"></div>
          <div class="circle two"></div>
          <div class="circle three"></div>
        </div>
      </div>
      <div class="imageText2__text">
        <span class="imageText2__text__small">{{ data.small_text }}</span>
        <div class="imageText2__text__main" v-html="data.main_text"></div>
        <Button

        v-if="data.link.title"
          color="white"
          lineHover="bg-orange"
          circleColor="circle-white"
          :text="data.link.title"
          :url="data.link.url"
        />
      </div>
    </div>
  </section>
</template>

<script>
import Button from '@/components/utility/Button.vue'
export default {
  props: ['data'],
  components: {
    Button
  }
}
</script>

<style lang="scss" scoped>
.main-container{
  @apply bg-ore_mint_30 px-4 lg:px-6;
}
.imageText2:deep() {
  @apply flex flex-col-reverse lg:flex-row gap-4 items-stretch my-10 lg:mx-6;

  .imageText2__image {
    @apply bg-white w-9/12  lg:w-4/12 h-60 md:h-96 lg:h-auto self-end lg:self-auto rounded-3xl relative transition-all duration-500 ease-in-out;
    img {
      @apply absolute top-12 lg:top-1/4 -left-20 md:-left-40 lg:-left-12 transition-all duration-500 ease-in-out;
    }
    .circles {
      @apply absolute right-[20px] bottom-[20px] h-7 w-7 origin-center;
      animation-duration: 0.5s;
      animation-name: circlesBackwards;
      animation-fill-mode: forwards;

      .circle {
        @apply absolute w-7 h-7 border border-ore_green_dark rounded-full transition-transform ease-in-out duration-300;
      }
      .one,
      .two,
      .three {
        @apply transition-all ease-in-out duration-300;
      }
    }

    &:hover {
      @apply scale-95 transition-all duration-300 ease-in-out;
      img {
        @apply scale-[1.0422] translate-y-1 -translate-x-1 transition-all duration-300 ease-in-out;
      }

      .circles {
        animation-duration: 0.5s;
        animation-name: circles;
        animation-fill-mode: forwards;
        animation-delay: 0.2ms;

        .one {
          @apply translate-x-[17px] translate-y-[0px];
        }
        .two {
          @apply translate-x-[-4px] translate-y-[0px];
        }
        .three {
          @apply translate-x-[7px] translate-y-[17px];
        }
      }
    }
  }

  .imageText2__text {
    @apply bg-ore_orange w-full lg:w-8/12 rounded-3xl  py-12 px-8 md:px-24 md:py-16;
    .imageText2__text__small {
      @apply text-[#EFF8F1] text-base;
    }
    .imageText2__text__main {
      @apply text-[#EFF8F1] font-normal pt-9 pb-6 sm:pt-11 md:pb-10;
      p {
        @apply min-h-[200px] text-2xl md:text-[40px] leading-6 md:leading-10 block text-[#EFF8F1];
        u {
          @apply underline decoration-2 underline-offset-4;
        }
      }
    }
  }

  @keyframes circles {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(180deg);
    }
  }

  @keyframes circlesBackwards {
    0% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
}
</style>
