<template>
  <div class="finish-picker" v-if="product">
    <div v-for="(type, index) of product.acf['acf-single-product-options-finish-repeater-section']" :key="`product-${index}`" class="finish-type">
      <h2>{{type['acf-single-product-options-finish-repeater-section-title']}}</h2>
      <p class="mb-10 text-[18px]" v-if="type['acf-single-product-options-finish-repeater-section-description']">{{ type['acf-single-product-options-finish-repeater-section-description'] }}</p>
      <div class="texture-card-container">
        <FinishSelectorCard v-for="(material, idx) of type['acf-single-product-options-finish-repeater']" :material="material" :finishname="type['acf-single-product-options-finish-repeater-section-title']" :key="`material-${idx}`" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ProductState } from './state'
import FinishSelectorCard from './FinishSelectorCard.vue'
const { product } = ProductState()
// console.log(product.value)
</script>
<style lang="scss" scoped>
  .finish-picker {
    @apply ml-0 lg:ml-28;
    .finish-type {
      @apply pt-12 pb-12;
      h2 {
        @apply text-[20px] text-ore_green_dark border-t-2 border-t-ore_green_dark w-4/12 pt-3 mb-4;
      }
      .texture-card-container {
        @apply grid grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 gap-3;

      }
    }
  }
</style>
