<template>
  <div class="category-bar">
    <div class="top-part" @click="toggle()">
      <p class="title">{{ info.name }}</p>
      <img class="cursor-pointer transition" :class="show ? 'rotate-180' : ''" src="@/assets/img/drop-down-circle.svg" alt="">
    </div>
    <SlideUpDown v-model="show">
      <div class="sub-content">
        <div class="sub-item" v-for="(sub, index) of information.products" :key="index">
          <p class="sub-title">{{ sub.title }}</p>
          <a class="flex w-fit self-end hover:scale-95 transition" v-if="sub?.specs" target="_blank" role="button" :href="`${sub.specs['acf-single-product-download-file'].url}`" :download="`${sub.title}`">
          <div class="cursor-pointer specs-button flex items-center border border-black rounded-full w-fit px-3 py-1">
            <p class="mr-2 text-[12px]">Specs</p><img src="@/assets/img/download-black.svg" alt="">
          </div>
        </a>
        </div>
      </div>
    </SlideUpDown>
  </div>
</template>
<script setup>
import { ref, onBeforeMount, computed } from 'vue'
import SlideUpDown from 'vue3-slide-up-down'
import { useStore } from 'vuex'

const props = defineProps(['info'])

const store = useStore()
const products = ref([])

const information = computed(() => {
  return products.value
})

onBeforeMount(async () => {
  const request = {
    type: 'product-type',
    slug: props.info.slug,
    showLoading: true
  }
  await store.dispatch('getSingleBySlug', request)
  const data = store.getters.singleBySlug(request)
  products.value = data
})

const show = ref(false)

const toggle = () => {
  show.value = !show.value
}

</script>
<style lang="scss" scoped>
  .category-bar {
    @apply bg-white p-5 rounded-2xl mb-2 w-full flex flex-col;
    .top-part {
      @apply flex justify-between w-full;
      p.title {
        @apply text-ore_green text-2xl font-medium leading-10 font-FHOscar;
      }
    }
    .sub-content {
      @apply block pt-5;
      .sub-item {
        @apply flex justify-between py-5 border-t border-ore_green/20;
      }
    }
  }
</style>
