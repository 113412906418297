
<template>
  <div>
  <div class="singleCategory">
    <div class="imageCategory"  data-scroll
            data-scroll-direction="vertical"
            data-speed="0.8">
      <img :class="`imageCategory-image-${id}`" :src="!image ? Dummy : image" alt=""  >

    </div>
    <div class="singleCategory__content desk">
      <div class="singleCategory__content__text">
        <h3 class="singleCategory__text__title" :class="description == '' ? 'lg:translate-y-[35px]': '' ">{{ title }}</h3>
        <p class="singleCategory__text__description" v-html="description"></p>
      </div>
      <Button
        color="mint-30"
        lineHover="bg-green"
        circleColor="circle-orange"
        :text="link_label"
        :url="link_url"
      />
    </div>
  </div>
  <div class="singleCategory__content mob">
      <div class="singleCategory__content__text">
        <h3 class="singleCategory__text__title">{{ title }}</h3>
      </div>
          <Button
          color="mint-30"
          lineHover="bg-green"
          circleColor="circle-mint"
          :text="link_label"
          :url="link_url"
        />
    </div>
  </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Button from '@/components/utility/Button.vue'
import Dummy from '@/assets/img/dummy.jpg'
defineProps(['image', 'title', 'description', 'link_label', 'link_url'])

const s4 = () => {
  return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1)
}

const id = ref(s4())

onMounted(() => {
  gsap.registerPlugin(ScrollTrigger)
  gsap.set(`.imageCategory-image-${id.value}`, { scale: 1.2 })
  gsap.fromTo(`.imageCategory-image-${id.value}`, { y: -300 }, {
    scrollTrigger: {
      trigger: `.imageCategory-image-${id.value}`,
      id: `imageCategoryImage${id.value}`,
      start: 'top-=50% center',
      end: 'bottom+=50% center',
      scrub: true,
      markers: false
    },
    y: 0
  })
})

onBeforeUnmount(() => {
  setTimeout(() => {
    const imageCategoryImage = ScrollTrigger.getById(`imageCategoryImage${id.value}`)
    if (imageCategoryImage) imageCategoryImage.kill()
  }, 1500)
})

</script>

<style lang="scss" scoped>
.singleCategory{
  @apply relative overflow-hidden w-full h-[470px] md:h-[768px] rounded-xl flex flex-row my-3 md:my-10 bg-no-repeat bg-cover bg-center p-[25px];
  .imageCategory{
    @apply h-full w-full absolute inset-0 z-0;
    img{
      @apply h-full w-full object-cover ;
    }
  }
  &__content{
    @apply z-1 w-full lg:w-[650px] h-[105px] md:h-[134px] lg:h-[170px] self-end flex flex-row items-center justify-between bg-ore_green rounded-3xl px-10 md:px-12 py-8 md:py-[45px];
    &__text{
      @apply max-w-[60%];
      h3{
        @apply text-2xl  md:text-[54px] lg:text-[40px] leading-6 md:leading-[54px] lg:leading-[40px] text-ore_mint_30;
      }
      :deep(p){
        @apply text-ore_mint text-base leading-[18px] mt-4 w-[280px] h-[56px] hidden lg:line-clamp-2  ;
      }
    }

    :deep(.button ) {
      @apply mb-5 lg:-mb-2;
      .button-icon {
        @apply pr-0 md:pr-[60px];
        span {
          @apply text-xl md:text-2xl;
        }

      }
      .button-icon::after{
        @apply hidden md:block;
      }
      svg{
          @apply hidden md:block;
        }
    }
  }
}
.desk{
  @apply hidden md:flex ;
}
.mob{
  @apply flex md:hidden mb-10;
}

</style>
