import { ref, computed, onBeforeMount } from 'vue'

const cart = ref([])

export const cartState = () => {
  const addToCart = data => {
    const newData = [...cart.value, data]
    cart.value = newData
    window.localStorage.setItem('ore_cart', JSON.stringify(cart.value))
  }

  const removeFromCart = index => {
    cart.value.splice(index, 1)
    window.localStorage.setItem('ore_cart', JSON.stringify(cart.value))
  }

  // replace old product with new product
  const updateProduct = (data, index) => {
    removeFromCart(index)
    addToCart(data)
  }

  // (use index for the quantity update)
  const addProductQuantity = (index) => {
    if (cart.value[index].quantity <= 98) cart.value[index].quantity++
    window.localStorage.setItem('ore_cart', JSON.stringify(cart.value))
  }

  // (use index for the quantity update)
  const removeProductQuantity = (index) => {
    if (cart.value[index].quantity >= 2) cart.value[index].quantity--
    window.localStorage.setItem('ore_cart', JSON.stringify(cart.value))
  }

  // Clear cart
  const clearCart = () => {
    cart.value = []
    window.localStorage.setItem('ore_cart', JSON.stringify(cart.value))
  }

  const totalProducts = computed(() => cart.value.length)

  onBeforeMount(() => {
    if (window.localStorage.getItem('ore_cart')) {
      cart.value = JSON.parse(window.localStorage.getItem('ore_cart'))
    }
  })

  return {
    cart,
    totalProducts,
    addToCart,
    removeFromCart,
    updateProduct,
    addProductQuantity,
    removeProductQuantity,
    clearCart
  }
}
