<template>
  <div>
    <section v-for="section, idx in sections" :key="idx">
                <DoubleImagesSection v-if="section.section_layout != 4" :data="{
                big_Img: getImage(idx, 1),
                small_Img: getImage(idx, 2),
                layout: section.section_layout,
                show_two: section.show_small_img
                }">
                    <div v-if="section.section_layout == 1" class="overview-content" :class="{'no-second-image': !section.show_small_img}">

                        <div class="overview-content-text" v-html="section.section_content"/>
                    </div>
                    <div v-else-if="section.section_layout == 2" class="solution-content" :class="{'no-second-image': !section.show_small_img}">
                        <div class="solution-content-inner">
                            <div v-if="section.section_title" class="project-section-title">{{section.section_title}}</div>
                            <div class="solution-content-title" v-html="section.section_content" />
                        </div>
                    </div>
                    <div v-else-if="section.section_layout == 3" class="process-content" :class="{'no-second-image': !section.show_small_img}" >
                        <div class="process-step" v-for="step, idx in section.par_col.section_list" :key="idx">
                            <div v-if="section.par_col.par_col_add_title" class="project-section-title">{{step.title}}</div>
                            <div v-html="step.text" />
                        </div>
                    </div>
                </DoubleImagesSection>
                <div v-if="section.section_layout == 4" class="process-content" :class="{'no-second-image': !section.show_small_img}" >
                        <div class="process-step" v-for="step, idx in section.par_col.section_list" :key="idx">
                            <div v-if="section.par_col.par_col_add_title" class="project-section-title">{{step.title}}</div>
                            <div v-html="step.text" />
                        </div>
                    </div>
                <SpacerModule v-if="section.spacer.section_add_spacer" :data="{
                    spacer_height: section.spacer.spacer_height,
                    spacer_height_tablet: section.spacer.spacer_height_tablet,
                    spacer_height_mobile: section.spacer.spacer_height_mobile,
                    background_color:'#ebf8ef',
                }"/>
    </section>
  </div>
</template>

<script setup>
import { ref } from 'vue'

import Dummy from '@/assets/img/dummy.jpg'
import SpacerModule from '@/components/modules/Spacer.vue'
import DoubleImagesSection from '@/components/utility/DoubleImagesSection.vue'

const data = defineProps(['sections', 'gallery'])

const sectionImgId = (idx) => {
  return idx * 2
}

const hasImageSection = ref(false)

const getImage = (idx, imageNum) => {
  const prevSectionLayout = data.sections[idx - 1]?.section_layout
  let thisIndex = idx
  if (prevSectionLayout == 4) {
    hasImageSection.value = true
  }
  if (hasImageSection.value === true) {
    thisIndex = idx - 1
  }
  switch (imageNum) {
    case 1:
      if (data.gallery[sectionImgId(thisIndex)]) {
        return data.gallery[sectionImgId(thisIndex)].sizes.large
      } else {
        return Dummy
      }
    case 2:
      if (data.gallery[sectionImgId(thisIndex) + 1]) {
        return data.gallery[sectionImgId(thisIndex) + 1].sizes.large
      } else {
        return Dummy
      }
  }
}

// if section - 1 == 4
// return idx + 2 and idx + 1

</script>

<style lang="scss" scoped>
.overview-content {
        @apply px-5 mt-10 mb-[70px]
        md:px-[50px]
        lg:w-[60%] lg:self-end lg:pr-[120px] lg:mb-[150px] lg:mt-[-270px]
        xl:w-[50%] xl:mb-[225px];
        &-text {
            &:deep(p) {
                @apply text-[30px] leading-none
                md:text-[40px]
                lg:text-[30px]
                xl:text-[40px];
            }
            &:deep(p+p) {
                @apply mt-6;
            }
            &:deep(h3) {
                @apply mb-[28px]
            }
        }
        &-products {
            .project-section-title{
                @apply mt-[70px]
                lg:mt-[125px];
            }
            .products-wrapper{
                @apply flex flex-wrap gap-x-[25px] ;
                span{
                    @apply text-xl leading-[24px] w-[calc(50%-25px)]
                    md:text-2xl md:w-[305px]
                    lg:w-[calc(50%-25px)]
                    xl:w-[250px]
                    2xl:w-[calc(50%-25px)];
                }
            }
        }
        &.no-second-image {
            @apply md:mt-[150px] lg:self-center lg:w-full lg:max-w-[75%]
            xl:mt-[300px];
        }
    }

.solution-content{
    @apply px-5 mt-10 mb-[70px] flex flex-col
        md:mt-[100px] md:max-w-full
        lg:mt-[-300px] lg:max-w-[50%] lg:items-center
        xl:mt-[-450px] xl:mb-[300px];
    &-title {
        @apply flex flex-col gap-[25px]
        md:max-w-[400px]
        lg:max-w-[320px];
        &:deep(p) {
            @apply text-2xl leading-none
        }
    }
    &.no-second-image {
            @apply md:mt-[150px]
            lg:self-center lg:w-full lg:max-w-[75%]
            xl:mt-[200px];
            .solution-content-title{
              @apply md:max-w-none
            }
        }
}

.process-content {
    @apply px-5 mt-10 mb-[70px] flex flex-col gap-10
    md:flex-row md:flex-wrap md:justify-between md:gap-x-0
    md:my-24
    xl:justify-start xl:px-[100px] xl:gap-x-[42px] xl:my-36
    2xl:px-[190px]
    ;
    .process-step {
        @apply
        md:w-[48%]
        xl:w-[380px];
        &:deep(p) {
            @apply text-2xl leading-none m-0;
        }
        &:deep(p+p) {
            @apply mt-6;
        }
    }
}
.misc-content {
            @apply px-5 mt-10 mb-[70px]
            md:px-[60px]
            lg:w-[60%] lg:self-end lg:pr-[120px] lg:mb-[150px] lg:mt-[-270px]
            xl:w-[50%] xl:mb-[225px];
            &-text {
                &:deep(p) {
                    @apply text-[30px] leading-none
                    md:text-[40px]
                    lg:text-[30px]
                    xl:text-[40px];
                }
                &:deep(p+p) {
                    @apply mt-6;
                }
            }
        }

.project-section-title {
    @apply w-[190px]  py-2 border-t-2 border-ore_green text-ore_green text-xl leading-[40px] font-[400]
    ;
}
</style>
