<template>
  <section id="hero" class="home-hero">
    <div class="background">
      <img src="~@/assets/img/home-hero-background.svg" alt="Background" />
    </div>

    <div class="top-content">
      <div class="container">
        <div class="text">
          <div v-html="props.data.home_hero_title" class="title" />

          <Button
            :url="props.data.home_hero_cta_top.url"
            :text="props.data.home_hero_cta_top.title"
            color="dark-green"
            lineHover="bg-orange"
            circleColor="circle-white-home"
          />
        </div>

        <!-- Image  -->
        <div class="image-home">
          <img
            v-if="props.data.img"
            :src="props.data.img.url"
            :alt="props.data.img.alt"
            class="products"
          />
          <img
            v-else
            src="~@/assets/img/home-hero-products.png"
            alt="Products"
            class="products"
          />

          <!-- Background image for mobile -->
          <img
            src="~@/assets/img/home-hero-background.svg"
            alt="Background"
            class="mobile"
          />
        </div>
      </div>
    </div>

    <div class="bottom-content">
      <div class="container small">
        <p v-html="props.data.home_hero_tag" class="tag" />
        <div class="big" v-html="props.data.home_hero_text" />
        <Button
          :url="props.data.home_hero_cta_bottom.url"
          :text="props.data.home_hero_cta_bottom.title"
          color="dark-green"
          lineHover="bg-mint-30"
          circleColor="circle-orange"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import Button from '@/components/utility/Button.vue'
import { onMounted, onBeforeMount, onBeforeUnmount } from 'vue'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'

const props = defineProps(['data'])

onBeforeMount(() => {
  // ScrollTrigger.clearMatchMedia()
})

const parallax = () => {
  gsap.to('.products', {
    y: '400',
    ease: 'none',
    scrollTrigger: {
      id: 'hero-anim',
      trigger: '.home-hero',
      start: 'top top',
      end: 'bottom top',
      scrub: 0.5,
      markers: false,
    },
  })
}

onMounted(() => {
  parallax()
})

onBeforeUnmount(() => {
  const heroAnim = ScrollTrigger.getById('hero-anim')
  if (heroAnim) heroAnim.kill()
})
</script>

<style lang="scss" scoped>
.home-hero {
  @apply relative overflow-hidden;

  .background {
    @apply hidden lg:block absolute top-0 right-0 w-[calc(100%_-_70px)] max-w-[750px] mr-0 ml-auto lg:bottom-[240px];

    img {
      @apply object-contain object-right w-[100%];
    }
  }

  .container {
    @apply wide-container flex flex-col justify-center items-start;

    &.small {
      @apply main-container xl:pr-32 xl:pl-44;
    }
  }

  .top-content {
    @apply relative z-2 lg:py-0;

    &:deep(h1) {
      @apply text-[24px] mb-[30px] sm:text-[40px] leading-none;
    }

    .container {
      @apply lg:flex-row lg:items-center;

      .text {
        @apply pt-[150px] pb-4 md:px-[70px]
        lg:w-6/12 lg:px-12 lg:min-h-[100vh] lg:py-20 lg:flex lg:flex-col lg:justify-center 2xl:pr-28
        lg:pt-64;
      }

      .image-home {
        @apply relative -ml-[20px] h-[815px] w-[640px] sm:ml-0 sm:h-[1400px] sm:w-[1200px] lg:w-6/12 lg:h-full;

        img {
          @apply absolute w-[90%] left-[-25px] top-[120px] mobile:top-[125px] mobile:w-[95%] sm:min-h-[490px] md:-left-[55px] lg:w-[1170px] lg:top-[calc(50%_-_205px)] lg:max-w-none 2xl:left-[-70px];

          &.mobile {
            @apply w-[100vw] h-auto left-0 sm:h-[1400px] sm:w-[854px] top-0 z-[-1] md:w-[1000px] lg:hidden;
          }
        }
      }
    }
  }

  .bottom-content {
    @apply relative z-2 min-h-[540px] mt-[-300px] mobile:mt-[-200px] sm:mt-[-540px] lg:mt-0 xl:pt-[300px] pb-[40px];

    &:deep(p.tag) {
      @apply text-[20px] mb-9 sm:mb-11 font-medium;
    }

    .big {
      &:deep(p) {
        @apply text-[40px] sm:text-[60px] lg:text-[70px] leading-none font-normal;
      }
    }
  }
}
</style>
