<template>
  <div class="finish-picker-card" :style="{ 'background-image': 'url(' + finish['acf-product-options-finish-image'].url + ')' }" :class="active ? 'active' : ''">
    <div class="bottom-area items-center">
      <div class="left-side flex">
        <p class="mr-3">{{ finish['acf-product-options-finish-title'] }}</p>
      </div>
      <div class="right-side">
        <Popper v-if="finish['acf-product-options-finish-description']" placement="top" :hover="true" offsetSkid="-40">
          <img class="tooltips" src="@/assets/img/info.svg" alt="" />
          <template #content>
            <div class="flex flex-col">
              <img class="tooltips mb-4 w-6 h-6" src="@/assets/img/info.svg" alt="" />
              <div class="mb-4">{{ finish['acf-product-options-finish-description'] }}</div>
              <a class="flex w-fit self-end hover:scale-95 transition" v-if="finish['acf-product-options-finish-file']" target="_blank" role="button" :href="`${finish['acf-product-options-finish-file'].url}`" :download="`${props.category} - ${finish['acf-product-options-finish-title']}`">
                <div class="cursor-pointer specs-button flex items-center border border-black rounded-full w-fit px-3 py-1">
                  <p class="mr-4 text-[14px]">Specs</p><img src="@/assets/img/download-black.svg" alt="">
                </div>
              </a>
            </div>
          </template>
        </Popper>
        <div class="remove-product">
          <img class="trash" src="@/assets/img/trash.svg" @click="removeFromFinishes(category)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'

import Popper from 'vue3-popper'
import { finishState } from '@/components/Cart/finishStore.js'
const { removeFromFinishes } = finishState()
const active = ref(false)
const props = defineProps(['finish', 'category'])

</script>
<style lang="scss" scoped>

  .finish-picker-card {
    @apply flex w-full md:w-[45%] lg:w-[22%] rounded-3xl h-[280px] lg:h-[300px] xl:h-[350px] bg-cover bg-no-repeat bg-center transition;
    &:hover {
      @apply shadow-xl scale-[101%];
    }
    .bottom-area {
      @apply flex justify-between py-8 px-6 bg-white self-end w-full rounded-b-3xl;
      .right-side:deep() {
        @apply flex flex-col sm:flex-row md:flex-row lg:flex-col xl:flex-row gap-4 items-center;
        .popper {
        @apply bg-white bg-opacity-[95%] w-full md:w-[300px] p-5 shadow-2xl text-base rounded-lg text-black;
        #arrow {
          &::before {
            @apply bg-white bg-opacity-[95%] shadow-2xl;
          }
        }
      }
        .tooltips {
          @apply cursor-pointer h-8 w-8;
        }
        .remove-product {
        @apply flex items-center w-10;
        .trash {
          @apply cursor-pointer p-2 m-0 bg-red-600 w-10 h-10 rounded-full right-0
          transition-all duration-150 ease-in-out z-1;
        }
    }
      }
    }
  }
</style>
