<template>
    <section class="product-customizer" v-if="product">
        <div class="inner-container">
          <div class="title-area">
            <div class="left-side">
              <div class="breadcrumbs">
                <div class="level"><a :href="store.state.site.url">Home</a></div>
                <div class="level"><a :href="acfOptions?.global_product_detail_pre_designed?.url" :title="acfOptions?.global_product_detail_pre_designed?.title ? acfOptions?.global_product_detail_pre_designed?.title : ''" :target="acfOptions?.global_product_detail_pre_designed?.target">{{acfOptions?.global_product_detail_pre_designed?.title}}</a></div>
                <div v-if="parentCategory" class="level"><a :href="route.query.subcategory ? `/product-type/${route.query.category}/#${route.query.subcategory}` : `/product-type/${route.query.category}`">{{parentCategory}}</a></div>
                <div class="level"><a :href="route.fullPath" v-html="product.title.rendered" /></div>
            </div>
            </div>

            <div class="right-side">
               <h1 class="product-title" v-html="product.title.rendered" />
               <div class="arrow"><img src='@/assets/img/arrow_down.svg' /></div>
            </div>
          </div>
          <div class="main-area">
            <div class="main-left-side">
             <ImageContainer />
             <QuantitySelector />
            </div>
            <div class="main-right-side">
              <FinishSelector />
              <SizeSelector />
              <DesignSelector />
            </div>
          </div>
          <div class="related-area" v-if="product.related_products?.length > 0">
            <h2 class="related-products-title">Featured Project</h2>
            <div class="carousel-container">
              <div v-for="(product, index) of product.related_products" :key="`${index}-related-carousel-item`" class="carousel-card" :class="index > 3 ? 'hidden' : ''">
                <a @click="router.push(`/${product.post_type}/${product.post_title.replaceAll(/[^ A-Za-z0-9]/g, '').replaceAll(' ', '-').toLowerCase()}`)" class="carousel-card-link cursor-pointer">
                  <img :src="product.acf['acf-single-product-image'].sizes.large" alt="" class="product-carousel-image" />
                  <p>{{product.post_title}}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
    </section>
</template>
<script setup>
import { useStore } from 'vuex'
import { onBeforeMount, onMounted, onUpdated, ref, computed, onBeforeUnmount } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import ImageContainer from './ImageContainer.vue'
import FinishSelector from './FinishSelector.vue'
import SizeSelector from './SizeSelector.vue'
import DesignSelector from './DesignSelector.vue'
import QuantitySelector from './QuantitySelector.vue'
import { ProductState } from './state'
import { cartState } from '../Cart/Store'

const route = useRoute()
const router = useRouter()
const props = defineProps(['slug'])
const store = useStore()
const parentCategory = ref(null)

const acfOptions = computed(() => {
  return store.getters.acfOptions()
})

gsap.registerPlugin(ScrollTrigger)

const params = {
  type: 'products',
  slug: props.slug,
  showLoading: true
}
const {
  product,
  currentImage,
  finish,
  specsPDF,
  quantity,
  size,
  designOptions,
  setProduct, setCurrentImage, setSpecsPDF
} = ProductState()

const { cart } = cartState()

const pinning = () => {
  ScrollTrigger.matchMedia({
    '(min-width: 1024px)': function () {
      ScrollTrigger.create({
        id: 'pinning-anim',
        trigger: '.main-area',
        start: 'top-=150 top',
        end: 'bottom-=100 bottom-=5%',
        pin: '.main-left-side',
        pinSpacing: false,
        endTrigger: '.design-type',
        markers: false
      })
    }
  })
}

const loader = () => {
  if (!product.value) {
    setTimeout(() => {
      loader()
    }, 100)
  } else {
    setTimeout(() => {
      pinning()
    }, 100)
  }
}

onBeforeMount(async () => {
  await store.dispatch('getSingleBySlug', params)
  setProduct(store.getters.singleBySlug(params))
  setCurrentImage(product.value.acf['acf-single-product-image'].url)
  if (product.value.acf['acf-single-product-downloads']) {
    setSpecsPDF(product.value.acf['acf-single-product-downloads'][0])
  }
})

onMounted(() => {
  const query = route.query
  function titleCase (str) {
    const splitStr = str.toLowerCase().split(' ')
    for (let i = 0; i < splitStr.length; i++) {
      // You do not need to check if i is larger than splitStr length, as your for does that for you
      // Assign it back to the array
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
    }
    // Directly return the joined string
    return splitStr.join(' ')
  }
  if ('customizer' in query) {
    if (query.customizer) {
      productSetter(route.query.index)
    }
  }
  if ('category' in query) {
    if (query.category) {
      parentCategory.value = titleCase((route.query.category).replaceAll('-', ' '))
    }
  }
  loader()
})

onUpdated(() => {
  // ScrollTrigger.refresh()
})

onBeforeUnmount(() => {
  clearScrollTrigger()
})

function clearScrollTrigger () {
  const piningAnim = ScrollTrigger.getById('pinning-anim')
  if (piningAnim) piningAnim.kill()
  // ScrollTrigger.clearMatchMedia()
}

const productSetter = (index) => {
  product.value = cart.value[index].product
  currentImage.value = cart.value[index].currentImage
  finish.value = cart.value[index].finish
  specsPDF.value = cart.value[index].specsPDF
  quantity.value = cart.value[index].quantity
  size.value = cart.value[index].size
  designOptions.value = cart.value[index].designOptions
}

</script>
<style lang="scss" scoped>
  .product-customizer {
    @apply main-container bg-ore_mint_30 pt-48 pb-20;
    .inner-container {
      .title-area {
        @apply flex flex-col flex-wrap items-start lg:flex-row xl:items-center xl:justify-between mb-10;

      .right-side {
       @apply flex flex-row w-full justify-between items-center gap-9 mt-6;
        .product-title {
          @apply text-[60px] md:text-[120px] font-medium py-0 w-full;
          line-height: 0.8;
        }
      }
        .left-side {
          @apply flex pb-4 w-full items-start;
          .breadcrumbs {
            @apply flex w-full justify-start;
            .level {
              @apply px-4 md:px-7 py-2 md:py-3 text-[16px] md:text-[24px] min-h-[65px] flex items-center;
              &:first-child {
                @apply pl-0;
              }
              &:not(:last-child) {
                @apply border-r border-r-ore_orange;
              }
            }
          }
          .arrow {
            @apply hidden xl:block ml-6;
          }
        }
      }
      .main-area {
        @apply grid grid-cols-1 lg:grid-cols-2;
        .left-side {
          @apply col-span-1;
        }
        .right-side {

        }
      }
      .related-area {
        @apply my-16;
        .related-products-title {
          @apply text-ore_orange text-lg lg:text-[40px] mb-12;
        }
        .carousel-container {
        @apply flex flex-row py-5 gap-5 flex-wrap md:flex-nowrap;
        .carousel-card {
          @apply w-[47%] sm:w-[48%] md:w-4/12 min-h-[200px] sm:min-h-[285px] overflow-hidden relative;
          &::before {
            @apply bg-white rounded-2xl content-[""] overflow-hidden absolute w-full h-full z-1 top-0 transition-transform duration-300 ease-in-out;
          }
          &:hover {
            .product-carousel-image {
              @apply transform scale-110 transition-transform duration-300 ease-in-out;
            }
            &:before {
              @apply transform scale-95 transition-transform duration-300 ease-in-out;
            }

            p {
              @apply transform translate-x-3 -translate-y-3 transition-transform duration-300 ease-in-out;
            }
          }
          .carousel-card-link {
            @apply z-2 flex flex-col items-center justify-between p-8 h-full;
            .product-carousel-image {
              @apply object-contain w-[525px] transition-transform duration-300 ease-in-out z-2 rounded-full;
            }
            p {
              @apply text-center self-start z-2 transition-transform duration-300 ease-in-out;
            }
          }
        }
        }
      }
    }
  }
</style>
