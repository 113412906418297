<template>
<div class="size-picker" v-if="product">
    <div class="size-type">
      <h2>Size</h2>
      <div class="size-card-container">
        <SizeSelectorCard v-for="(variations, index) of product.product_variations" :key="`size-${index}`" :sizes="variations" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { ProductState } from './state'
import SizeSelectorCard from './SizeSelectorCard.vue'
const { product } = ProductState()

// console.log(product.value)

</script>
<style lang="scss" scoped>

.size-picker {
    @apply ml-0 lg:ml-24;
    .size-type {
      @apply pt-12 pb-12;
      h2 {
        @apply text-[20px] text-ore_green_dark border-t-2 border-t-ore_green_dark w-4/12 pt-3 mb-10;
      }
      .size-card-container {
        @apply grid grid-cols-1 gap-4;

      }
    }
  }
</style>
