<template>
  <footer class="site-footer" v-show="!this.$store.state.site.loading" id="oreFooter">
    <video class="footer-background-video" autoplay loop muted>
      <source :src="acf_options.footer_background_video" type="video/mp4">
    </video>
    <div class="footer-wrapper" data-speed="2">
      <div class="footer-container">
        <div class="footer-head">
          <div class="footer-title">
             <img src="@/assets/img/footer-title.svg" alt="Ore Footer">
          </div>
          <div class="social-media">
            <a
              id="linkInstagram"
              v-if="acf_options.instagram"
              :href="acf_options.instagram"
              :class="links.linkInstagram"
              target="_blank"
              @mouseenter="onMouseEnterLink('linkInstagram')"
              @mouseleave="onMouseLeaveLink('linkInstagram')"
            >
              Instagram
            </a>
            <a
              id="linkLinkedin"
              v-if="acf_options.linkedin"
              :href="acf_options.linkedin"
              :class="links.linkLinkedin"
              target="_blank"
              @mouseenter="onMouseEnterLink('linkLinkedin')"
              @mouseleave="onMouseLeaveLink('linkLinkedin')"
            >
              Linkedin
            </a>
          </div>
        </div>
        <div class="footer-body">
          <div class="links">
            <a v-for="item in footerMenu" :key="item.id" :href="item.url" :target="item.target" v-html="item.content"></a>
          </div>
          <span class="rights text-center lg:text-left">
            ©{{acf_options.copyright}} {{ currentYear }}-{{ nextYear }}
          </span>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>

import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'

export default {
  name: 'footerModule',
  props: ['data'],
  data () {
    return {
      links: {
        linkInstagram: 'not-hovered',
        linkLinkedin: 'not-hovered'
      },
      footerMenu: this.$store.getters.menu({ name: 'footer-menu' }),
      observer: null
    }
  },
  computed: {
    acf_options () {
      return this.$store.getters.acfOptions()
    },
    currentYear () {
      return new Date().getFullYear()
    },
    nextYear () {
      const nextYear = this.currentYear + 1
      const nextYearToString = nextYear.toString()

      return nextYearToString.substring(nextYearToString.length - 2)
    }
  },
  methods: {
    heightObserver () {
      this.observer = new ResizeObserver(() => this.refreshScroll()).observe(
        document.querySelector('body')
      )
    },
    onMouseEnterLink (id) {
      this.links[id] = 'hovered'
    },
    onMouseLeaveLink (id) {
      this.links[id] = 'not-hovered'
    },
    parallax () {
      gsap.fromTo('.footer-head', {
        y: '300'
      }, {
        y: '0',
        scrollTrigger: {
          id: 'site-footer',
          trigger: '#oreFooter',
          start: 'top center',
          end: 'center center',
          scrub: 2,
          markers: false
        }
      })
      gsap.fromTo('.footer-background-video', {
        y: '-300'
      }, {
        y: '0',
        scrollTrigger: {
          id: 'site-footer',
          trigger: '#oreFooter',
          start: 'top center',
          end: 'center center',
          scrub: 2,
          markers: false
        }
      })
    },
    refreshScroll () {
      setTimeout(() => {
        const siteFooter = ScrollTrigger.getById('site-footer')
        if (siteFooter) siteFooter.refresh()
        // ScrollTrigger.refresh()
      }, 500)
    }
  },
  mounted () {
    this.parallax()
    // this.heightObserver()
  },
  beforeUnmount () {
    const siteFooter = ScrollTrigger.getById('site-footer')
    if (siteFooter) siteFooter.kill()
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/index.scss";
.site-footer {
  @apply flex min-h-screen w-full bg-cover bg-center bg-no-repeat relative h-screen overflow-hidden;

  .footer-background-video {
    @apply min-w-full min-h-full h-[130%] bg-cover absolute left-0 right-0 bottom-0 top-0 z-1 object-cover;
  }

  .footer-wrapper {
    @apply flex min-h-screen w-full bg-cover bg-right-bottom bg-no-repeat z-2;

    .footer-container {
      @apply wide-container flex xl:gap-y-10 2xl:gap-y-36 flex-col justify-end min-h-full px-[30px] md:px-14 2xl:px-24 lg:mx-auto;
      .footer-head {
        @apply flex flex-col space-y-[144px] md:space-y-[190px] lg:space-y-[172px];

        .footer-title:deep(){
          @apply w-full md:w-[625px];
        }

        .social-media {
          @apply flex flex-col md:flex-row md:space-x-7 lg:space-x-4;

          a {
            @apply font-FHOscar font-medium text-3xl pb-12 md:text-[40px] leading-5 md:leading-9 w-full md:max-w-[300px] lg:max-w-[400px] text-ore_footer_light_green transition ease-in-out duration-200 border-t;
            border-color: rgba($color: #cae6d0, $alpha: 0.3)
          }

          a:before {
            @apply block mb-[18px] md:mb-[34px] lg:mb-9 border-b-2 border-ore_orange;
            content: "";
          }

          a.not-hovered {
            &:before {
              animation: moveItBack 0.025s ease-in-out;
              animation-fill-mode: forwards;
              transform-origin:  0% 50%;
            }
          }

          a.hovered {
            &:before {
              animation: moveIt 0.25s ease-in-out;
              animation-fill-mode: forwards;
              transform-origin:  0% 50%;
            }
          }
        }
      }

      .footer-body {
        @apply flex w-full justify-between items-end mb-20;

        .rights {
          @apply font-FHOscar font-medium text-base leading-none md:text-2xl md:leading-6 text-ore_footer_light_green;
        }

        .links {
          @apply flex flex-col gap-[14px] md:flex-row md:gap-14 lg:gap-24;

          a{
            @apply cursor-pointer tracking-[0.02em] font-FHOscar font-normal text-ore_mint text-base leading-none;
          }
        }
      }
    }
  }
}

@keyframes moveIt {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

@keyframes moveItBack {
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(0.5);
  }
  100% {
    transform: scaleX(0);
  }
}
</style>
