<template>
  <section class="wysiwyg-section">
    <div v-if="props.data.wysiwyg_show_back_button" class="button-wrapper">
      <button class="button" onclick="history.back()">
        <img class="icon" :src="BackArrow" alt="Back Arrow"> Back
      </button>
    </div>
    <div class="title-wrapper">
      <div class="title" v-html="props.data.wysiwyg_title"></div>
      <img v-if="props.data.wysiwyg_show_go_bottom_icon" class="arrow" :src="ArrowDown" alt="Arrow">
    </div>
    <div class="content" v-html="props.data.wysiwyg_content"></div>
  </section>
</template>

<script setup>
import { onMounted } from 'vue'
import Button from "@/components/utility/Button.vue"
import ArrowDown from '@/assets/img/arrow_down.svg'
import BackArrow from '@/assets/img/back_arrow.svg'

const props = defineProps(['data'])

onMounted(() => {
  // console.log('data', props.data)
})
</script>

<style lang="scss" scoped>
  .wysiwyg-section {
    @apply flex flex-col w-full main-container py-36 xl:px-16 2xl:px-0;

    .button-wrapper{
      .button {
        @apply flex flex-row justify-center items-center gap-2 bg-ore_orange font-FHOscar text-lg lg:text-xl font-medium text-white rounded-[100px] py-3 px-5 transition-all duration-500;
        &:hover {
          @apply scale-105;
        }
      }

      .icon {
        @apply h-8 w-8 lg:w-auto lg:h-auto;
      }
    }

    .title-wrapper {
      @apply flex justify-between items-center my-8 md:mb-10 lg:my-16;

      .title:deep() {
        h1 {
          @apply font-medium font-FHOscar text-4xl md:text-[40px] lg:text-[70px] lg:leading-[70px] text-ore_green_dark;
        }
      }

      .arrow {
        @apply mr-5 md:mr-[30px] lg:mr-0 self-end h-11 w-auto;
      }
    }

    .content:deep() {
      @apply font-FHOscar text-xl md:text-2xl lg:text-3xl text-ore_green_dark;

      p {
        @apply font-FHOscar mb-8;
      }
    }
  }
</style>