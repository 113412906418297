<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<template>
  <section class="request-quote">
    <div class="wide-container">
      <FormKit
        type="form"
        id="formKit"
        #default="{ value, state: { valid } }"
        :plugins="[stepPlugin]"
        @submit="submitHandler"
        @submit-invalid="showErrors"
        :actions="false"
        v-model="requestFormData"
      >
        <div class="steps-container">
          <div class="previous">
            <!-- eslint-disable-next-line vue/no-v-text-v-html-on-component -->
            <FormKit
              type="button"
              :disabled="activeStep == props.data.request_quote_stepper[0].request_quote_step_title"
              @click="setStep(-1), activeIndex = steps[activeStep].index, refreshScrollTrigger()"
              v-text="'Back'"
            />
          </div>
          <div class="number">
            <span>0{{activeIndex}}</span>-
            <span>03</span>
          </div>
          <ul class="steps">
            <li
              v-for="(step, stepName) in steps"
              :key="step"
              :class="['step', { 'has-errors': checkStepValidity(stepName) }]"
              @click="activeStep = stepName, activeIndex = step.indexm, refreshScrollTrigger()"
              :data-step-valid="step.valid && step.errorCount === 0"
              :data-step-active="activeStep === stepName"
            >
              <span
                class="step-number"
                v-text="`0${step.index}`"
              />
              {{ camel2title(stepName) }}
            </li>
          </ul>
          <div class="next">
            <div v-show="activeIndex != Object.keys(steps).length">
              <FormKit
              type="button"
              class="next"
              :disabled="steps[activeStep]?.blockingCount > 0 || activeStep == props.data.request_quote_stepper[props.data.request_quote_stepper.length-1].request_quote_step_title"
              @click="activeIndex = setStep(1), activeIndex = steps[activeStep].index, refreshScrollTrigger()"
              v-text="'Next'"/>
            </div>
            <div v-show="activeIndex == Object.keys(steps).length">
              <FormKit
                type="submit"
                class="submit"
                :label="labelSend"
                :disabled="!valid || disabled"/>
            </div>
          </div>
        </div>

        <!-- .form-body solely for styling -->
        <div class="form-body" v-if="isDataFormLoaded">
          <section
            class="step-content"
            v-for="(step, index) in props.data.request_quote_stepper"
            :key="index"
            v-show="activeStep === step.request_quote_step_title">
            <ul class="validation-errors" v-if="messages.length">
              <li v-for="(message, indexMessage) in messages" :key="indexMessage">{{ message }}</li>
            </ul>
            <div class="top">
              <div class="one">
                <div class="greetings"
                  v-if="activeIndex === 1"
                  v-html="props.data.request_quote_form_greetings">
                </div>
                <div
                  class="summary"
                  v-if="activeIndex !== 1">
                  <span class="title">Summary</span>
                  <div class="content">
                    <div
                      class="col"
                      v-for="(element, indexSummary) in summary"
                      :key="indexSummary"
                    >
                      <div
                        class="row"
                        v-for="(field, indexElement) in element"
                        :key="indexElement"
                      >
                        <span class="label">{{field.label}}:</span>
                        <span class="value">{{field.value}}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="two">
                <FormKit
                  :id="step.request_quote_step_title"
                  type="group"
                  :name="step.request_quote_step_title"
                >
                  <FormKitSchema :schema="formByPage[index]" />
                </FormKit>
              </div>
            </div>
            <div class="bottom" v-if="step.request_quote_step_image">
              <div class="image-parallax">
                <img
                  class="image"
                  :src="step.request_quote_step_image.url"
                  :alt="step.request_quote_step_image.alt"
                  data-speed="0.8"/>
              </div>
              <div class="title"
                v-if="step.request_quote_step_image_description"
                v-html="step.request_quote_step_image_description"
                data-speed="0.9"></div>
            </div>
          </section>
        </div>
      </FormKit>
    </div>
  </section>
</template>
<script setup>
import { onBeforeMount, ref, watch, onMounted } from 'vue'

import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Axios from 'axios'
import camel2title from '../../utils/utils'
import useSteps from '../../utils/useSteps'
import { reset } from '@formkit/core'
import { getValidationMessages } from '@formkit/validation'

import { ScrollTrigger } from 'gsap/ScrollTrigger'

const { steps, visitedSteps, activeStep, setStep, stepPlugin } = useSteps()
const props = defineProps(['data'])
const store = useStore()
const router = useRouter()
const isDataFormLoaded = ref(false)
const fullRegexPhoneExpression = ref(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)
const form = ref({})
const formByPage = ref({})
const storeParams = ref({})
const summary = ref({})
const requestFormData = ref({})
const messages = ref([])
const activeIndex = ref(1)
const disabled = ref(false)
const labelSend = ref('Submit')

const showErrors = (node) => {
  messages.value = []
  const validations = getValidationMessages(node)
  validations.forEach((inputMessages) => {
    messages.value = messages.value.concat(
      inputMessages.map((message) => message.value)
    )
  })
}

const redirectToSuccess = () => {
  reset('formKit')
  const path = props.data.request_quote_form_final_message.url.replace(store._state.data.site.url, '')
  router.push(path)
}

const postData = async (URL, body, node) => {
  try {
    const response = await Axios.post(URL, body, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    disabled.value = false
    labelSend.value = 'Submit'
    messages.value = []
    if (response.status === 200 && response.data.is_valid) {
      node.clearErrors()
      redirectToSuccess()
    } else {
      alert(response.message ?? '`There was an error submitting the form')
    }
  } catch (error) {
    disabled.value = false
    labelSend.value = 'Submit'
    node.setErrors(error.formErrors, error.fieldErrors)
    console.error(`There was an error submitting the form: ${error}`)
  }
}

const submitHandler = (_data, node) => {
  disabled.value = true
  labelSend.value = 'Sending...'
  messages.value = []
  const curedForm = new FormData()
  const keys = Object.keys(requestFormData.value)
  keys.forEach((step, _index) => {
    for (const field of Object.entries(requestFormData.value[step])) {
      const fieldSettings = form.value.fields.find((x) => {
        return x.label === field[0]
      })
      const key = `input_${fieldSettings.id}`
      const value = fieldSettings.type === 'fileupload' ? field[1].map(a => a.file)[0] : field[1] ?? ''
      curedForm.append(key, value)
    }
  })

  postData(form.value.submitURL, curedForm, node)
}

const checkStepValidity = (stepName) => {
  return (steps[stepName].errorCount > 0 || steps[stepName].blockingCount > 0) && visitedSteps.value.includes(stepName)
}

watch(activeIndex, async (newValue) => {
  if (newValue === 1) {
    summary.value = {}
    return
  }

  const finalSummary = {}
  const keys = Object.keys(requestFormData.value).slice(0, activeIndex.value - 1)
  keys.forEach((element, index) => {
    finalSummary[index] ??= []
    const elementKeys = Object.keys(requestFormData.value[element])
    elementKeys.forEach(elementKey => {
      finalSummary[index].push({
        label: elementKey,
        value: requestFormData.value[element][elementKey] ?? ''
      })
    })
  })
  summary.value = finalSummary
})

const selectOptions = (choices) => {
  if (choices) {
    return choices.map((choice) => {
      return choice.text
    })
  } else {
    return []
  }
}

const validationTypes = (required, type) => {
  const validationPhone = `matches:${fullRegexPhoneExpression.value}`
  const validation = []
  if (required) {
    validation.push('required')
    if (type === 'phone') {
      validation.push(validationPhone)
    }
  }
  if (type === 'email') {
    validation.push('email')
  }
  if (type === 'phone') {
    validation.push(validationPhone)
  }
  const finalValidation = validation.join('|')
  return finalValidation
}

const returnFormKiType = (type) => {
  switch (type) {
    case 'phone':
      return 'tel'
    case 'fileupload':
      return 'file'
    default:
      return type
  }
}

const setFormByPage = () => {
  const schema = []
  form.value.fields.forEach(field => {
    if (field.type === 'page') {
      return
    }
    const index = field.pageNumber - 1
    const fieldSettings = {
      $formkit: returnFormKiType(field.type),
      name: field.label,
      label: field.labelPlacement ? '' : field.label,
      placeholder: field.placeholder,
      validation: validationTypes(field.isRequired, field.type)
    }
    if (field.type === 'select') {
      fieldSettings.options = selectOptions(field.choices)
    }
    if (field.type === 'textarea') {
      fieldSettings.rows = 8
    }
    if (field.type === 'fileupload') {
      fieldSettings.accept = field.allowedExtensions ?? ''
      fieldSettings.multiple = field.multipleFiles ?? false
    }

    schema[index] ??= []
    schema[index].push(fieldSettings)
  })

  return schema
}

const getFormData = async () => {
  storeParams.value = {
    type: 'forms',
    id: props.data.request_quote_form_id,
    showLoading: true
  }
  await store.dispatch('getSingleById', storeParams.value)
  form.value = store.getters.singleById(storeParams.value)
  formByPage.value = setFormByPage()
  isDataFormLoaded.value = true
}

const refreshScrollTrigger = () => {
  setTimeout(() => {
    // ScrollTrigger.refresh()
  }, 0)
}

onMounted(() => {
  refreshScrollTrigger()
})

onBeforeMount(() => {
  // console.log('data', props)
  getFormData()
})
</script>

<style lang="scss" scoped>
.request-quote{
  @apply min-h-[100vh] pt-[130px] px-[9px] pb-8 md:px-5 lg:py-[160px] bg-ore_mint_30;

  .wide-container{
    @apply px-0;
    .formkit-form {
      @apply mx-4 text-ore_green_dark;

      .steps-container{
        @apply grid grid-cols-2 place-content-between lg:grid-cols-none lg:place-content-between lg:grid-flow-col gap-y-2 bg-ore_green text-ore_mint_30 font-FHOscar font-normal text-2xl leading-6 px-[22px] lg:px-7 py-[15px] lg:py-5 rounded-[20px];

        .previous:deep() {
          @apply flex;

          .formkit-input{
            @apply flex flex-row justify-start items-center gap-8 text-[20px] leading-6;
            &::before {
              @apply w-5 transition-all duration-500;
              content: url('@/assets/img/back_arrow.svg');
            }
            &:hover{
              @apply scale-[1.025];
            }
          }

          .formkit-input[disabled]{
            @apply opacity-60;
          }
        }
        .number {
          @apply flex text-[20px] leading-6 justify-end items-center lg:hidden;

          span:last-child{
            @apply relative mr-10;
            &::after{
              @apply absolute rounded-[20px] w-[10px] h-[10px] bg-ore_orange -right-9 -top-2;
              content: '';
            }
          }
        }

        .steps {
          @apply w-full flex flex-col lg:flex-row items-start justify-center lg:items-center;

          .step {
            @apply flex flex-col text-2xl leading-6 font-FHOscar font-normal text-ore_mint_30 lg:border-r lg:border-ore_orange lg:px-[61px] cursor-pointer;
            .step-number {
              @apply hidden lg:flex items-center lg:relative font-FHOscar font-normal text-xl leading-6 text-ore_mint;

              &::after {
                @apply bg-ore_orange w-[10px] h-[10px] rounded-[10px] absolute left-8;
                content: "";
              }
            }
          }

          .step[data-step-active="false"]{
            @apply hidden lg:inline-flex opacity-60;
          }

          .step:last-child {
            @apply border-none;
          }
        }
        .next:deep(){
          @apply flex justify-end items-center;
          .formkit-input {
            @apply bg-ore_orange text-white rounded-3xl text-[20px] leading-6 py-2 px-10 transition-all duration-500;
            filter: drop-shadow(0px 0px 30px rgba(255, 76, 0, 0.5));
          }
          .formkit-input[disabled]{
            @apply opacity-60;
          }
          &:hover{
            @apply scale-[1.025];
          }
        }

      }

      .form-body:deep() {
        @apply flex w-full;
        .step-content{
          @apply flex flex-col w-full;
          .validation-errors {
            @apply bg-red-200 text-red-500 rounded-2xl p-4 mt-2;
          }
          .top{
            @apply flex flex-col lg:flex-row px-5 pb-16 lg:pt-40 lg:pb-24 lg:pr-0 lg:pl-28 gap-y-14 lg:gap-y-0 lg:gap-x-10;
            .one{
              @apply hidden lg:block w-full lg:w-1/2;

              .summary {
                @apply hidden lg:flex flex-col mt-10;
                .title{
                  @apply font-FHOscar font-normal text-ore_green text-xl leading-10 border-t-2 border-ore_green mb-10 max-w-min pr-40;
                }
                .content {
                  @apply flex flex-row gap-x-32;
                  .col {
                    @apply flex flex-col gap-y-5 font-FHOscar font-normal text-2xl leading-6 text-ore_green;
                  }
                  .row{
                    @apply flex flex-col;
                  }
                }
              }

              .greetings{
                p{
                  @apply font-FHOscar font-normal text-2xl lg:text-3xl leading-6 lg:leading-[30px] text-ore_green_dark;
                }

                h5{
                  @apply text-xl lg:text-2xl lg:leading-6 mb-5 lg:mb-6;
                }
              }
            }
            .two{
              @apply w-full lg:w-1/2;
              .formkit-outer{
                @apply flex flex-col w-full pb-2;
                .formkit-wrapper {
                  @apply w-full flex flex-col;
                  .formkit-label{
                    @apply text-ore_green font-FHOscar font-normal text-xl leading-10 mt-16 lg:mt-9 py-4 ml-4 lg:ml-0 border-t-2 border-ore_green w-1/2 lg:max-w-[190px];
                  }
                  .formkit-inner{
                    @apply flex flex-col w-full;
                     &:focus-visible, &:focus {
                      --tw-ring-shadow: none;
                      @apply border-none outline-none shadow-none;
                    }

                    .formkit-input {
                      @apply flex w-full border-none text-ore_green p-5 rounded-2xl font-FHOscar font-normal text-lg leading-10;

                      &:focus-visible, &:focus {
                        --tw-ring-shadow: none;
                        @apply border-none outline-none shadow-none;
                      }

                      &[type="date"]{
                        @apply cursor-pointer;
                      }

                      &[type="file"]{
                        @apply text-transparent flex items-center justify-center pl-2 lg:p-0 leading-none pb-0;

                        &::-webkit-file-upload-button {
                          @apply invisible;
                        }

                        &::before {
                          content: "Attach File";
                          -webkit-user-select: none;
                          @apply flex items-center justify-center text-white bg-ore_orange rounded-[100px] outline-none whitespace-nowrap cursor-pointer text-xl lg:text-2xl py-1 px-8 lg:p-4 w-36 lg:w-72;
                        }
                      }
                    }

                    select {
                      @apply cursor-pointer;
                    }

                    .formkit-no-files{
                      @apply pl-2;
                    }

                    .formkit-file-list{
                      @apply flex flex-col lg:flex-row lg:flex-wrap gap-y-1 lg:gap-x-1 pl-2 lg:pl-0;

                      .formkit-file-item{
                        @apply flex flex-row-reverse items-center justify-center bg-ore_green text-white rounded-[100px] text-lg lg:text-xl leading-10 py-1 lg:py-4 px-6 lg:px-20 gap-x-8 relative;
                        .formkit-file-name {
                          @apply inline-block text-ellipsis max-w-full overflow-hidden whitespace-nowrap;
                        }
                      }
                      .formkit-file-remove{
                        @apply text-transparent py-0 w-10 h-10 absolute left-0 top-[6px] lg:top-3;
                        &::before{
                          content: url('@/assets/img/remove.svg');
                        }
                      }
                    }

                    .formkit-file-remove{
                      @apply ml-2 text-xl text-ore_green_dark py-4;
                    }
                  }
                }
                .formkit-messages{
                  @apply text-red-500 text-xs pl-3 pt-1;
                }

                .formkit-help{
                  @apply text-ore_green text-[14px] pl-3 pt-1;
                }
              }
              .formkit-outer[data-type="checkbox"]{
                @apply md:basis-full;
                .formkit-wrapper {
                  @apply flex-row justify-start items-center mt-4;
                  .formkit-inner {
                    @apply max-w-max;
                    .formkit-input{
                      @apply max-w-max cursor-pointer;
                    }
                    input.formkit-input{
                      @apply rounded-[100px] bg-cover bg-transparent lg:mr-4;
                      background-image: url('@/assets/img/checkbox-unchecked.svg');
                    }
                    input.formkit-input:checked{
                      @apply bg-auto;
                      background-image: url('@/assets/img/checkbox-checked.svg');
                    }
                  }
                  .formkit-label{
                    @apply my-0 border-t-0 py-0 leading-6 cursor-pointer;
                  }
                }
              }
              .formkit-outer[data-type="select"]{
                .formkit-wrapper{
                  .formkit-inner{
                    select.formkit-input {
                      @apply bg-auto;
                      background-image: url('@/assets/img/down-circle-arrow-orange.svg');
                    }
                  }
                }
              }
            }
          }
          .bottom {
            @apply flex flex-col w-full lg:relative;
            .image-parallax {
              @apply h-[500px] md:h-[768px] md:max-h-[768px] overflow-hidden object-cover w-full rounded-3xl;

              .image{
                @apply object-cover w-full h-[540px] md:h-[768px] border-l-[0px] border-r-[1px] border-transparent rounded-3xl;
              }
            }

            .title {
              @apply w-full bg-ore_green px-6 py-3 mt-20 lg:mt-8 rounded-3xl text-ore_mint_30 text-xl leading-6 font-FHOscar font-normal lg:absolute lg:max-w-fit lg:top-12 lg:left-7;
              p, span{
                @apply text-ore_mint_30;
              }
            }
          }
        }
        .step-content:first-child {
          .top {
            @apply pt-16 lg:pt-40;
            .one {
              @apply block;
            }
          }
        }
      }
    }
  }
}
</style>
