<template>
  <div class="faq-toggle">
    <div class="faq-item">
      <div class="top-part"  @click="toggle()">
        <p class="question">{{ data.question }}</p>
        <img class="cursor-pointer transition" :class="show ? 'rotate-180' : ''" src="@/assets/img/drop-down-circle.svg" alt="" />
      </div>
      <SlideUpDown v-model="show">
        <div class="answer" v-html="data.answer" />
      </SlideUpDown>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue'
import SlideUpDown from 'vue3-slide-up-down'
defineProps(['data'])

const show = ref(false)

const toggle = () => {
  show.value = !show.value
}

</script>
<style lang="scss" scoped>
.faq-toggle {
  @apply flex flex-col;
  .faq-item {
    @apply bg-white px-6 rounded-2xl mb-4;
    .top-part {
      @apply flex justify-between;
      .question {
        @apply text-ore_green_dark pr-4 text-lg lg:text-2xl font-light leading-6 font-FHOscar py-6;
      }
    }
    .answer {
      @apply py-6;
    }
  }
}
</style>
