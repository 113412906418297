<template>
  <section class="list-with-animation" :style="backgroundAnimationColor">
    <div class="list-with-animation_wrapper">
      <div
        class="list-with-animation__animation-wrapper"
        id="listWithAnimationAnimationWrapper"
      >
        <div
          id="listWithAnimationAnimation"
          ref="listWithAnimationAnimation"
          class="list-with-animation-animation"
          :style="backgroundAnimationColor"
        ></div>
      </div>
      <div class="list-with-animation__items">
        <div
          class="list-with-animation__item"
          v-for="(item, index) in props.data.list_with_animation_list"
          :id="`list-with-animation__item-${index}`"
          :key="index"
        >
          <div class="content" :class="[`item-${index}`, props.data.list_with_animation_color]" v-html="item.list_with_animation_content"></div>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { computed, ref, onBeforeMount, onMounted, onUpdated, onBeforeUnmount, onUnmounted } from 'vue'
import LottieWeb from 'lottie-web'
// import '@lottiefiles/lottie-player'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import animationWhite from '@/assets/lottie/ore_value_circles_white.json'
import animationOrange from '@/assets/lottie/ore_value_circles_orange.json'

const props = defineProps(['data'])

const animations = ref([])
const currentScrollTriggerId = ref('')

const backgroundAnimationColor = computed(() => {
  if (!props.data.list_with_animation_bg) {
    return {}
  }

  return {
    background: `rgba(${props.data.list_with_animation_bg.red}, ${props.data.list_with_animation_bg.green}, ${props.data.list_with_animation_bg.blue}, ${props.data.list_with_animation_bg.alpha})`
  }
})

onBeforeMount(() => {
  window.addEventListener('resize', onWindowResize)
})

onMounted(() => {
  // console.log('data', props)
  onWindowResize()
})

onUpdated(() => {
  // ScrollTrigger.refresh()
})

onBeforeUnmount(() => {
  clearAnimations()
})

onUnmounted(() => {
  window.removeEventListener('resize', this.onWindowResize)
})

function getLottieFileByColor () {
  switch (props.data.list_with_animation_color) {
    case 'orange':
      return animationOrange
    case 'white':
      return animationWhite
    default:
      return animationOrange
  }
}

function buildLottieAnimation () {
  const target = '#listWithAnimationAnimation'
  const duration = 1
  const triggerId = `trigger-${generateUuidv4()}`
  const itemsQuantity = props.data.list_with_animation_list.length - 1
  const timeObj = { currentFrame: 0 }
  const animation = LottieWeb.loadAnimation({
    container: document.querySelector(target),
    renderer: 'svg',
    loop: false,
    autoplay: false,
    animationData: getLottieFileByColor()
  })

  gsap.registerPlugin(ScrollTrigger)

  ScrollTrigger.matchMedia({
    all: function () {
      // console.log('small')
      ScrollTrigger.create({
        id: triggerId,
        trigger: target,
        endTrigger: `#list-with-animation__item-${itemsQuantity}`,
        start: 'start 0',
        end: () => {
          const chartHeight = document.querySelector(target).offsetHeight
          const lastItemHeigth = document.querySelector(
            `#list-with-animation__item-${itemsQuantity}`
          ).offsetHeight
          const result = `bottom ${lastItemHeigth + chartHeight}px`
          return result
        },
        pin: true,
        pinSpacing: false,
        onEnter: () => {
          gsap.to(target, {
            autoAlpha: 1
          })
        },
        onUpdate: (self) => {
          if (duration) {
            gsap.to(timeObj, {
              duration,
              currentFrame: Math.floor(
                self.progress * (animation.totalFrames - 15)
              ),
              onUpdate: () => {
                animation.goToAndStop(timeObj.currentFrame, true)
              },
              ease: 'expo'
            })
          } else {
            animation.goToAndStop(
              self.progress * (animation.totalFrames - 1),
              true
            )
          }
        },
        onComplete: (self) => {
          if (self.scrollTrigger) self.scrollTrigger.kill(true)
          self.progress(1)
        }
      })
    }
  })

  const animationCreated = {
    triggerId,
    animation
  }

  animations.value.push(animationCreated)
  currentScrollTriggerId.value = triggerId
}

function clearAnimations () {
  animations.value.forEach((animation, index) => {
    if (animation.triggerId === currentScrollTriggerId.value) {
      const Anim = ScrollTrigger.getById(animation.triggerId)
      if (Anim) Anim.kill()
      animation.animation.destroy()
      animations.value.splice(index, 1)
    }
  })
}

function onWindowResize () {
  clearAnimations()
  buildLottieAnimation()
}

function generateUuidv4 () {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )
}

</script>

<style lang="scss" scoped>
.list-with-animation {
  @apply main-container p-0 w-full;

  .list-with-animation_wrapper {
    @apply flex flex-col lg:flex-row w-full md:pb-16;

    .list-with-animation__animation-wrapper:deep() {
      @apply flex my-4 md:mt-0 md:mb-28 w-full lg:w-1/4 z-[99];

      svg {
        @apply flex md:py-4 bg-inherit lg:bg-transparent;
      }

      .list-with-animation-animation {
        @apply w-full h-[35vh] md:h-[40vh] lg:h-[50vh] transition-opacity ease-in-out duration-500 py-2 md:p-0 lg:bg-transparent;
      }

      .pin-spacer {
        @apply h-0 p-0;
      }
    }

    .list-with-animation__items {
      @apply flex flex-col py-24 md:pt-28 md:pb-4 md:px-12 lg:w-3/4 px-8;

      .list-with-animation__item:deep(){
        .content {
          @apply pb-12 md:pb-16 lg:mb-32;

          h3 {
            @apply relative font-medium font-FHOscar text-2xl md:text-3xl leading-6 md:leading-8 text-ore_green_dark mb-12 lg:pl-[6.2rem];

            &::before {
              content: "";
              @apply absolute lg:inline-block right-0 md:right-[44%] lg:right-auto top-1/4 lg:left-0 lg:mr-0 bg-no-repeat object-cover;
            }
          }

          ul {
            @apply columns-1 md:columns-2 md:gap-x-14 lg:gap-x-[72px] lg:pl-[104px];
            li {
              @apply font-FHOscar font-medium text-xl lg:text-2xl leading-6 text-ore_green_dark mb-4 list-none;

              &::before {
                @apply text-black font-bold inline-block w-[5px] -ml-4 pr-4;
                content: "\2022";
              }
            }
          }
        }

        .orange {
          &.item-0 {
            h3 {
              &::before {
                background-image: url('@/assets/img/orange-circle-1.png');
              }
            }
          }

          &.item-1 {
            h3 {
              &::before {
                background-image: url('@/assets/img/orange-circle-2.png');
              }
            }
          }

          &.item-2 {
            h3 {
              &::before {
                background-image: url('@/assets/img/orange-circle-3.png');
              }
            }
          }

          ul {
            li {
              &::before {
                @apply text-ore_orange;
              }
            }
          }
        }

        .white {
          &.item-0 {
            h3 {
              &::before {
                background-image: url('@/assets/img/white-circle-1.png');
              }
            }
          }

          &.item-1 {
            h3 {
              &::before {
                background-image: url('@/assets/img/white-circle-2.png');
              }
            }
          }

          &.item-2 {
            h3 {
              &::before {
                background-image: url('@/assets/img/white-circle-3.png');
              }
            }
          }
          ul {
            li {
              &::before {
                @apply text-white;
              }
            }
          }
        }

        .item-0 {
          h3 {
            &::before {
              @apply w-6 h-6;
            }
          }
        }

        .item-1, .item-2 {
          h3 {
            &::before {
              @apply w-8 h-8;
            }
          }
        }

      }
    }
  }
}
</style>
