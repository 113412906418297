<template>
    <div class="product-image-container" v-if="currentImage">
      <div class="summary">
        <div v-if="finish" class="finish option">
          <p>Finish:</p>
          <p>{{finish["acf-single-product-options-finish-option"].label}}</p>
        </div>
        <div v-if="size" class="size option">
          <p>Size:</p>
          <p>{{size.attributes.attribute_size}}</p>
        </div>
        <div v-if="designOptions.length > 0" class="engineering option">
          <p>Engineering:</p>
          <div class="options">
            <template v-for="(option, indx) of designOptions" :key="`sumary-${indx}-option`">
              <p v-if="indx < 5">• {{option.label}}</p>
              <p v-else-if="indx === 5">+{{designOptions.length - 5}} other{{designOptions.length - 5 > 1 ? 's' : ''}}</p>
            </template>
          </div>
        </div>
      </div>
        <img class='product-image' :src="currentImage">
        <a v-if="specsPDF" target="_blank" :href="specsPDF['acf-single-product-download-file'].url" :download="`${product.slug}-specs`"><div class="specs"><span>Specs</span><img src="@/assets/img/download.svg"></div></a>
    </div>
</template>
<script setup>
import { ProductState } from './state'
const { currentImage, specsPDF, product, finish, size, designOptions } = ProductState()

</script>
<style lang="scss" scoped>
  .product-image-container {
    @apply w-full min-h-[400px] max-h-[400px] md:min-h-[600px] md:max-h-[600px]
    rounded-xl bg-white flex items-center relative overflow-hidden;
    @media (max-height: 800px) {
      @apply min-h-[500px] max-h-[500px];

    }
    .summary {
      @apply hidden md:flex absolute top-10 left-10 w-10/12 z-2 justify-start items-start gap-4;
      .option {
        @apply w-3/12;
        p {
          @apply text-ore_green_dark;
        }
      }
      .engineering {
        @apply w-7/12;
        .options {
          @apply flex flex-row gap-y-1 flex-wrap;
          p {
            @apply text-[16px] leading-[18px] w-6/12 truncate;
          }
        }
      }
    }
    .product-image {
      @apply w-auto h-full md:h-[120%] object-contain md:object-cover object-center absolute;
    }
    .specs {
      @apply absolute bottom-4 right-4 text-[20px] text-white
       bg-[#001610]/80 py-2 px-6 rounded-full flex shadow-md
       shadow-primaryshade/50 transition-all duration-300;
      span {
        @apply mr-6;
      }
      &:hover {
        @apply shadow-lg transition-all scale-105 duration-300 shadow-primaryshade/50;
      }
    }
  }
</style>
