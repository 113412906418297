<template>

</template>

<script setup>
//
import { onMounted } from '@vue/runtime-core'
const bamboo = () => {
  // Took embedded code and added it to the component.
  // If this ever needs to be updated, just copy-paste from
  // https://oredesigns.bamboohr.com/js/embed.js
  // Reasoon for the embed not working is the readystatechange
  // inside the embedded code.
  const rootId = 'BambooHR-ATS'
  const el = document.getElementById('BambooHR')

  if (!el) {
    console.error('BambooHR-ATS: Hmm, Looks like we are missing something, Double check the code snippet.')
    return
  }

  const departmentId = el.getAttribute('data-departmentId')
  const domain = el.getAttribute('data-domain')
  const version = el.getAttribute('data-version') || '1.0.0'

  if (!domain) {
    console.error('BambooHR-ATS: We are unable to retrieve the domain, Double check the code snippet.')
    return
  }

  const root = document.createElement('div')
  root.id = rootId
  el.appendChild(root)

  const footer = document.createElement('div')
  footer.style = 'font-family: "Lato", sans-serif; font-size: 12px; color: #999; text-align: right; margin: 9px 9px 0px 0px;'
  const poweredBy = document.createTextNode('Powered by')
  const logo = document.createElement('img')
  logo.src = 'https://resources.bamboohr.com/images/footer-logo.png'
  logo.alt = 'BambooHR - HR software'
  logo.style = 'display:inline;'
  const link = document.createElement('a')
  link.href = 'http://www.bamboohr.com'
  link.target = '_blank'
  link.rel = 'external'

  link.appendChild(logo)
  footer.appendChild(poweredBy)
  footer.appendChild(link)

  el.appendChild(footer)
  let embedUrl = 'https://' + domain + '/jobs/embed2.php?version=' + encodeURIComponent(version) + ''

  if (departmentId) {
    embedUrl += '&departmentId=' + encodeURIComponent(departmentId)
  }
  const xmlhttp = new XMLHttpRequest()

  xmlhttp.onreadystatechange = function () {
    if (xmlhttp.readyState == 4 && xmlhttp.status == 200) {
      const content = xmlhttp.responseText
      if (root) root.innerHTML = content
    }
  }
  xmlhttp.open('GET', embedUrl, true)
  xmlhttp.send()
}

onMounted(() => {
  bamboo()
})
</script>

<style>

</style>
