<template>
  <section class="title-with-two-columns">
    <div class="main-container">
      <p v-html="props.data.title_with_two_columns_text_first_column" class="title" :class="
        props.data.title_with_two_columns_text_first_column_size ? props.data.title_with_two_columns_text_first_column_size : ''
      " />

      <div class="circles-wrapper">
        <div class="circles">
          <span class="circle-one"></span>
          <span class="circle-two"></span>
          <span class="circle-three"></span>
        </div>
      </div>

      <p v-html="props.data.title_with_two_columns_text_second_column" class="column one" :class="
        props.data.title_with_two_columns_text_second_column_size ? props.data.title_with_two_columns_text_second_column_size : ''
      " />

      <p v-html="props.data.title_with_two_columns_text_third_column" class="column two" :class="
        props.data.title_with_two_columns_text_third_column_size ? props.data.title_with_two_columns_text_third_column_size : ''
      " />

      <div class="button" :style="{
        marginTop: props.data.title_with_two_columns_text_button_margin,
      }">
        <Button :url="data.title_with_two_columns_cta.url" :text="data.title_with_two_columns_cta.title" :target="data.title_with_two_columns_cta.target"
          color="dark-green" lineHover="bg-mint-30" circleColor="circle-orange" />
      </div>
    </div>
  </section>
</template>

<script setup>
import { ref, onUpdated, onMounted, onBeforeUnmount } from 'vue'
import Button from '@/components/utility/Button.vue'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { gsap } from 'gsap'

gsap.registerPlugin(ScrollTrigger)

const isTablet = ref(false)
const props = defineProps(['data'])

onUpdated(() => {
  if (isTablet.value) {
    // ScrollTrigger.refresh()
  }
})

onMounted(() => {
  const screenWidth = window.innerWidth
  isTablet.value = screenWidth >= 768 && screenWidth < 1024

  if (isTablet.value) {
    applyTransition()
  }
})

onBeforeUnmount(() => {
  const imageWithTitleWrapper = ScrollTrigger.getById('image-with-title-wrapper')
  if (imageWithTitleWrapper) imageWithTitleWrapper.kill()

  const imageWithTitleWrapperTwo = ScrollTrigger.getById('image-with-title-wrapper-two')
  if (imageWithTitleWrapperTwo) imageWithTitleWrapperTwo.kill()
})

function applyTransition () {
  if (props.data.image_with_title_image) {
    gsap.fromTo(
      '.circle-one',
      { x: 0 },
      {
        scrollTrigger: {
          id: 'image-with-title-wrapper',
          trigger: '.image-with-title__wrapper',
          start: 'start center',
          end: 'bottom center',
          startTrigger: '.image-with-title',
          endTrigger: '.image-with-title',
          // markers: true,
          scrub: true
        },
        x: 18
      }
    )

    gsap.fromTo(
      '.circle-three',
      { x: 0 },
      {
        scrollTrigger: {
          id: 'image-with-title-wrapper-two',
          trigger: '.image-with-title__wrapper',
          start: 'start center',
          end: 'bottom center',
          startTrigger: '.image-with-title',
          endTrigger: '.image-with-title',
          // markers: true,
          scrub: true
        },
        x: -18
      }
    )
  }
}

</script>

<style lang="scss" scoped>
.title-with-two-columns {
  @apply relative py-20;

  .main-container {
    @apply relative md:flex md:flex-row md:justify-start lg:justify-around xl:justify-start md:flex-wrap xl:px-7;

    .title {
      @apply mb-14 md:w-1/2 lg:w-96 desktop:w-[430px] xl:mr-40;
    }

    .circles-wrapper {
      @apply hidden md:block lg:hidden md:w-1/2 lg:w-0;
      .circles{
        @apply flex flex-row space-x-1 w-auto h-auto lg:pt-44 xl:pt-52 lg:flex justify-end items-center;

        span {
          @apply h-[30px] w-[30px] bg-transparent border border-ore_green_dark rounded-full;
        }
      }
    }

    .column {
      @apply mb-6 md:w-6/12 lg:w-64 desktop:w-[350px] xl:mr-11;

      &.one {
        @apply md:pr-4;
      }

      &.two {
        @apply md:pl-4;
      }
    }

    .small {
      @apply text-[20px] md:text-3xl lg:text-[20px] leading-6 md:leading-[24px];
    }

    .medium {
      @apply leading-[30px] text-[30px];
    }
    .big {
      @apply leading-none text-[20px] md:text-[60px] lg:text-[70px] xl:-mt-5;
    }
  }

  .button {
    @apply md:w-6/12 md:mr-0 md:ml-auto md:pl-[8px] lg:w-[240px] desktop:w-[338px] lg:pl-0 xl:w-[350px] xl:mr-11 xl:pl-[5px];
  }
}
</style>
