import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'
import emitter from 'tiny-emitter/instance'
const { url } = window.__VUE_WORDPRESS__.routing

// scroll position is handled in @after-leave transition hook
// if ('scrollRestoration' in window.history) window.history.scrollRestoration = 'manual'

export const router = createRouter({
  history: createWebHistory(url.replace(window.location.origin, '')),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (to.hash) {
      emitter.emit('smoothScrollTo', to.hash)
    }
    if (savedPosition) {
      return new Promise(resolve => {
        emitter.on('scrollBeforeEnter', () => {
          resolve(emitter.emit('scrollNow'))
        })
      })
    } else {
      return new Promise(resolve => {
        emitter.on('scrollBeforeEnter', () => {
          resolve(emitter.emit('scrollNow'))
        })
      })
    }
  }
})
