<template>
  <section class="portfolio-list-module">
    <div v-for="(item, index) of data.data.portfolio_list" :key="`portfolio-item-${index}`" class="card">
      <div class="image">
        <img :src="item.acf['acf-single-portfolio-gallery'].length > 1 ? item.acf['acf-single-portfolio-gallery'][0].url : PlaceholderImage" alt="Image">
      </div>
      <div class="text" >
        <div class="description">
          <p class="type">Portfolio</p>
        </div>
        <div class="information">
          <div class='single-title' v-html="item.post_title"/>
          <p v-html="truncateNoP(item.acf['acf-single-portfolio-information'])" />
          <Button :url="`${store.state.site.url}/portfolio/${item.post_name}`" text="Learn More" color="dark-green" lineHover="bg-mint-30"
            circleColor="circle-orange" />
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import PlaceholderImage from '@/assets/img/dummy.jpg'
import Button from '@/components/utility/Button.vue'
import { useStore } from 'vuex'
const data = defineProps(['data'])

// console.log(data)

const store = useStore()

const truncateNoP = (text) => {
  const maxChar = 187
  if (text.length > maxChar) {
    const truncated = `${text.slice(0, maxChar)}...`
    // console.log(truncated)
    return truncated
  } else {
    return text
  }
}

</script>
<style lang="scss" scoped>
  .portfolio-list-module {
    @apply wide-container flex flex-col lg:flex-row gap-x-6 my-40 lg:my-60;
    .card {
        @apply relative mt-5 bg-white p-[15px] rounded-[27px] overflow-hidden md:w-[calc(50%_-_12.5px)] md:mt-0
        lg:max-h-[1001px]
        lg:p-[25px];
        .image {
          @apply rounded-[27px] h-[240px] relative overflow-hidden lg:h-[455px];

          img {
            @apply absolute top-0 left-0 object-cover object-center w-full h-full;
          }
        }

        .text {
          @apply mt-8 px-[10px] md:mt-5 md:pr-5 lg:mt-14;

          .description {
            @apply flex justify-start gap-14 mb-7 lg:mb-12;

            p {
              @apply text-[16px] leading-none;
            }
          }

          :deep(.information) {
            @apply flex flex-col justify-between;

            p {
              @apply min-h-[120px] text-[20px] leading-none
              lg:max-h-[120px] lg:text-[30px] lg:mb-20;
            }
            .button{
               @apply mt-[0px] md:mt-[16px] mb-[15px];

            }
          }
        }

        &.full {
          @apply md:flex md:gap-[25px] md:w-full lg:max-h-[768px] lg:gap-[90px] lg:items-center;

          .image {
            @apply md:w-1/2 md:h-full lg:h-[700px];
          }

          .text {
            @apply md:w-1/2 lg:mt-2 lg:h-[50%] lg:pl-0 lg:flex lg:flex-col lg:justify-center;

            :deep(.information) {
                @apply md:mb-0 md:justify-start;
                div {
                }
              p {
                @apply lg:text-[40px] lg:mb-24 lg:max-h-full;
                &:last-child {
                  @apply lg:mb-24;
                }
              }

            }
          }
        }
        .single-title {
                @apply text-[40px] mb-[40px];
              }
        &.dont-show {
            @apply hidden;
        }
      }
  }
</style>
