<template>
  <a v-if="backCTA.url" :href="backCTA.url" class="go-back">
    <img src="@/assets/img/back_arrow.svg" />
    <span>{{backCTA.title}}</span>
  </a>
  <button v-else @click="goBack()" class="go-back">
    <img src="@/assets/img/back_arrow.svg" />
    <span>{{backCTA.title}}</span>
  </button>
</template>

<script setup>
defineProps(['backCTA'])

const goBack = () => {
  history.back()
}
</script>

<style lang="scss" scoped>
.go-back {
    @apply hidden top-[111px] left-5
    bg-ore_orange rounded-full
    py-[11px] px-[26px]
    items-center justify-between gap-3
    text-xl leading-6 text-white font-[400]
    md:flex md:absolute
    xl:top-44
    2xl:left-[-80px];
}
</style>
