<template>
  <section class="main-product-carousel">
    <div class="product-carousel-inner">
      <div class="carousel-container">
        <div v-for="(product, index) of data.products" :key="index" class="carousel-card">
          <a @click="router.push(`/${product.post_type}/${product.post_title.replaceAll(' ', '-').toLowerCase()}`)" class="carousel-card-link cursor-pointer">
            <img :src="product.acf['acf-single-product-image'].sizes.large" alt="" class="product-carousel-image" />
            <p>{{product.post_title}}</p>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>
<script setup>
import { useRouter } from 'vue-router'
import { onMounted, onBeforeUnmount } from 'vue'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

onMounted(() => {
  ScrollTrigger.matchMedia({
    // large
    '(min-width: 960px)': function () {
      gsap.fromTo('.carousel-container',
        { x: 0 },
        {
          scrollTrigger: {
            id: 'carousel-container',
            trigger: '.carousel-container',
            start: 'center bottom',
            scrub: true,
            markers: false
          },
          x: '-30%'
        })
    }
  })
})

onBeforeUnmount(() => {
  const carouselContainer = ScrollTrigger.getById('carousel-container')
  if (carouselContainer) carouselContainer.kill()
})

defineProps(['data'])
const router = useRouter()
</script>
<style lang="scss" scoped>
.main-product-carousel {
  @apply  relative z-1 overflow-hidden;
  .product-carousel-inner {
    @apply main-container py-16;
    .carousel-container {
      @apply flex flex-row py-5 overflow-x-auto lg:overflow-visible snap-x;
      .carousel-card {
        @apply mx-3 min-w-[375px] lg:min-w-[475px] max-w-[375px] lg:max-w-[475px]
        min-h-[385px] lg:min-h-[485px] overflow-hidden relative snap-center;
        &::before {
          @apply bg-white rounded-2xl content-[""] overflow-hidden absolute w-full h-full z-1 top-0 transition-transform duration-300 ease-in-out;
        }
        &:hover {
          .product-carousel-image {
            @apply transform scale-110 transition-transform duration-300 ease-in-out;
          }
          &:before {
            @apply transform scale-95 transition-transform duration-300 ease-in-out;
          }

          p {
            @apply transform translate-x-3 -translate-y-3 transition-transform duration-300 ease-in-out;
          }
        }
        .carousel-card-link {
          @apply z-2 flex flex-col items-center justify-between p-8 h-full;
          .product-carousel-image {
            @apply object-contain w-[525px] transition-transform duration-300 ease-in-out z-2 rounded-full;
          }
          p {
            @apply text-center self-start z-2 transition-transform duration-300 ease-in-out;
          }
        }
      }
    }
  }
  }
</style>
