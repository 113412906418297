<template>
  <section class="main-container  imageText" v-if="data">
    <div class="imageText__image">
      <img
        :src="data.image.url"
        :alt="data.image.alt"
        data-scroll
        class="imageText__image_img" />
    </div>
    <div class="imageText__text">
      <div class="imageText__text__main" v-html="data.main_text"></div>
      <Button
        color="dropdgreen-dark"
        lineHover="bg-mint-30"
        circleColor="circle-orange"
        :text="data.link.title"
        :url="data.link.url"
      />
    </div>
  </section>
</template>

<script>
import Button from '@/components/utility/Button.vue'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default {
  props: ['data'],
  data () {
    return ({
      parallax: null
    })
  },
  components: {
    Button
  },
  mounted () {
    gsap.registerPlugin(ScrollTrigger)
    gsap.set('.imageText__image_img', { scale: 1.2 })
    gsap.fromTo('.imageText__image_img', { y: -300 }, {
      scrollTrigger: {
        trigger: '.imageText__image_img',
        id: 'imageTextImageImg',
        start: 'top-=10% center',
        end: 'bottom+=50% center',
        scrub: true,
        markers: false
      },
      y: 0
    })
    // ScrollTrigger.refresh()
  },
  beforeUnmount () {
    const imageTextImageImg = ScrollTrigger.getById('imageTextImageImg')
    if (imageTextImageImg) imageTextImageImg.kill()
  }
}
</script>

<style lang="scss" scoped>
.imageText{
  @apply flex flex-col md:flex-row gap-4 items-center my-10  px-[10px] md:px-[46px];
  .imageText__image {
    @apply relative bg-white w-full max-w-md md:w-[300px] lg:w-4/12  rounded-3xl overflow-hidden aspect-square ;
    img {
      @apply top-[-10%] absolute w-full h-[150%] object-cover
    }
  }

  .imageText__text {
    @apply w-full md:flex-1 lg:w-8/12 pt-10 md:py-[30px] lg:py-0 px-0 lg:px-20 lg:mt-[-20px];
    .imageText__text__main {
      @apply px-[14px] text-ore_green_dark font-normal pt-11 md:pt-0 md:pl-[34px]  md:pr-[0px] lg:pl-6 lg:w-11/12;
      &:deep(p){
        @apply text-2xl md:text-3xl lg:text-[40px] leading-6 md:leading-8 lg:leading-10 block pb-10;
      }
    }
    a{
      @apply ml-[14px] md:ml-[34px] lg:ml-6;
    }
  }
}
</style>
